import React from 'react';

export const QuarterTile = ({ tileRef, size, children }) => {
    const classes = `quarter-tile ${size.height} ${size.width} padding-0 bg-white`;
    return (
        <div className={classes} ref={tileRef}>
            {children}
        </div>
    );
};
