import { authorizeFetch } from '@/configuration/setup/fetch';
import { HTTP_STATUS_NO_CONTENT, HTTP_STATUS_UNAUTHORIZED } from '@/configuration/setup/httpErrors';
import { accessToken } from '@/configuration/tokenHandling/accessToken';
import get from 'lodash/fp/get';
import { put } from 'redux-saga/effects';

export const authorizedFetch = authorizeFetch(accessToken, fetch);

function FetchResponseError(status, message) {
    Error.call(this);
    this.name = 'FetchResponseError';
    this.message = message;
    this.status = status;
}

function ParseResponseError(message, rawString, url) {
    Error.call(this);
    this.name = 'ParseResponseError';
    this.message = message;
    this.rawString = rawString;
    this.url = url;
}

const rejectResponse = (response) => {
    // console.log('FetchResponseError', { response });
    if (response.status === HTTP_STATUS_UNAUTHORIZED) {
        return response.text().then((message) => {
            try {
                return Promise.reject(new FetchResponseError(response.status, JSON.parse(message)));
            } catch (error) {
                return Promise.reject(error);
            }
        });
    }
    return Promise.reject();
};

const handleTextResponse = (response) => {
    if (response.ok) {
        return response.text().then((message) => {
            return Promise.resolve(message);
        });
    }
    return rejectResponse(response);
};

const handleJSONResponse = (response) => {
    if (response.ok) {
        if (response.status === HTTP_STATUS_NO_CONTENT) {
            return Promise.resolve({});
        }
        return response.text().then((message) => {
            try {
                if (message.length > 0) {
                    return Promise.resolve(JSON.parse(message));
                }
                return Promise.resolve({});
            } catch (error) {
                return Promise.reject(new ParseResponseError(error.message, message, response.url));
            }
        });
    }
    return response.text().then((message) => {
        try {
            const parsedMessage = JSON.parse(message);
            const actualMessage = parsedMessage.message || parsedMessage;
            return Promise.reject(new FetchResponseError(response.status, actualMessage));
        } catch (error) {
            return rejectResponse(response);
        }
    });
};

const handleBlobResponse = (response) => {
    if (response.ok) {
        return response.blob().then((blob) => {
            return Promise.resolve(blob);
        });
    }
    return rejectResponse(response);
};

const defaultJsonOptions = {
    method: 'GET',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
};

const defaultDownloadOptions = {
    headers: {
        Accept: 'application/octet-stream',
        'Content-Type': 'application/octet-stream',
    },
};

export const requestText = (url, options, fetchMethod = authorizedFetch) => {
    const requestObject = {
        ...defaultJsonOptions,
        ...options,
    };

    // if request.body  =>  JSON.stringify(request.body);
    return fetchMethod(url, requestObject).then((response) => {
        return handleTextResponse(response);
    });
};
export const requestJSON = (url, options, fetchMethod = authorizedFetch) => {
    const requestObject = {
        ...defaultJsonOptions,
        ...options,
    };

    // if request.body  =>  JSON.stringify(request.body);
    return fetchMethod(url, requestObject).then((response) => {
        return handleJSONResponse(response);
    });
};

export const handleError = (/* error*/) => {};

export const startDownload = (url, options, fetchMethod = authorizedFetch) => {
    const requestObject = {
        ...defaultDownloadOptions,
        ...options,
    };
    return fetchMethod(url, requestObject).then((response) => {
        return handleBlobResponse(response);
    });
};

export const getHTTP = (serviceUrl) => {
    return requestJSON(serviceUrl, {
        method: 'GET',
    });
};

export const postHTTP = (serviceUrl, body) => {
    return requestJSON(serviceUrl, {
        method: 'POST',
        body,
    });
};

export const putHTTP = (serviceUrl, body) => {
    return requestJSON(serviceUrl, {
        method: 'PUT',
        body,
    });
};

export const patchHTTP = (serviceUrl, body) => {
    return requestJSON(serviceUrl, {
        method: 'PATCH',
        body,
    });
};

export const deleteHTTP = (serviceUrl, body) => {
    return requestJSON(serviceUrl, {
        method: 'DELETE',
        body,
    });
};

export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR';

export const notifyAuthorizationError = (message) => {
    return {
        type: AUTHORIZATION_ERROR,
        message,
    };
};

export function* doHandleErrorSaga(actionType, error) {
    console.error(JSON.stringify(error), { actionType }); // eslint-disable-line no-console
    if (get('status', error) === HTTP_STATUS_UNAUTHORIZED) {
        yield put(notifyAuthorizationError(error.message));
    }
}
