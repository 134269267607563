import { SIGNAL_DIRECTION_IN } from '@/common/constants/commonConstants';
import { useAppSelector } from '@/configuration/setup/hooks';
import { getConfLogicInputVectors, getConfLogicOutputFunctions } from '@/features/conflogic2/confLogicSlice';
import SignalListContainer from '@/features/conflogic2/SignalListContainer';
import SignalListHeader from '@/features/conflogic2/SignalListHeader';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { eq } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const SidebarContainer = ({ closed, direction, title }) => {
    const vectors = eq(direction, SIGNAL_DIRECTION_IN)
        ? useAppSelector(getConfLogicInputVectors)
        : useAppSelector(getConfLogicOutputFunctions);

    return (
        <Sidebar
            title={<SignalListHeader messageId={title}/>}
            headerClassName={'bg-darkest'}
            titleClassName='padding-left-10'
            closed={closed}
            disableClose
        >
            <SignalListContainer vectors={vectors} direction={direction}/>
        </Sidebar>
    );
};

SidebarContainer.defaultProps = {
    closed: true,
    direction: 'in',
    title: '',
};
SidebarContainer.propTypes = {
    closed: PropTypes.bool,
    direction: PropTypes.string,
    title: PropTypes.string,
};

export default SidebarContainer;
