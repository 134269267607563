import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // data: {},
    // isLoading: true,
};

export const customerSlice = createSlice({
    name: 'customer',
    initialState,
    // reducers: {
    //     // ❌ ERROR: mutates state, but also returns new array size!
    //     brokenReducer: (state, action) => state.push(action.payload),
    //     // ✅ SAFE: the `void` keyword prevents a return value
    //     fixedReducer1: (state, action) => void state.push(action.payload),
    //     // ✅ SAFE: curly braces make this a function body and no return
    //     fixedReducer2: (state, action) => {
    //         state.push(action.payload)
    //     },
    //     brokenTodosLoadedReducer(state, action) {
    //         // ❌ ERROR: does not actually mutate or return anything new!
    //         state = action.payload
    //     },
    //     fixedTodosLoadedReducer(state, action) {
    //         // ✅ CORRECT: returns a new value to replace the old one
    //         return action.payload
    //     },
    //     correctResetTodosReducer(state, action) {
    //         // ✅ CORRECT: returns a new value to replace the old one
    //         return initialState
    //     },
    // },
    reducers: {
        getCustomerFetch: (state) => {
            // state.isLoading = true;
        },
        getCustomerSuccess: (state = initialState, action = {}) => {
            // state.data = action.payload;
            // state.isLoading = false;
            return action.payload;
        },
        getCustomerFailure: (state) => {},
        addNewVehicle: (state, action) => {},
        editVehicle: (state, action) => {},
        removeVehicle: (state, action) => {},
    },
});

export const { getCustomerFetch, getCustomerSuccess, getCustomerFailure, addNewVehicle, editVehicle, removeVehicle } =
    customerSlice.actions;

export const getCustomer = (state) => state.entities.customer;
// export const isCustomerLoading = (state) => state.isLoading;

export default customerSlice.reducer;
