// import * as confLogicSagas from '@/common/sagas/confLogicSagas';
import { fetchAdminDataSaga } from '@/common/sagas/adminSagas';
import { fetchInputSignalsSaga, fetchOperatorOptionsSaga } from '@/common/sagas/confLogicSagas';
import { addVehicleSaga, editVehicleSaga, fetchCustomerSaga, removeVehicleSaga } from '@/common/sagas/customerSagas';
import { fetchEngineSignalParamsSaga, fetchEngineSignalsSaga } from '@/common/sagas/parametrizationSagas';
import { fetchUserPermissionsSaga } from '@/common/sagas/permissionsSagas';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
    yield all([
        // [...Object.values(customerSagas), ...Object.values(engineSagas)/* , ...Object.values(confLogicSagas)*/].map(fork)
        // admin
        fetchAdminDataSaga(),
        // customer
        fetchCustomerSaga(),
        addVehicleSaga(),
        editVehicleSaga(),
        removeVehicleSaga(),
        // engine
        fetchEngineSignalsSaga(),
        fetchEngineSignalParamsSaga(),
        // conflogic
        fetchInputSignalsSaga(),
        fetchOperatorOptionsSaga(),
        // config
        fetchUserPermissionsSaga(),
    ]);
}
