import Tile from '@/features/admin/Tile';
import getOr from 'lodash/fp/getOr';
import React from 'react';
import { FormattedNumber } from 'react-intl';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';
const MultipleNumbersTile = ({ sourceData, tileDescriptor }) => {
    return (
        <Tile>
            <div data-name={name} className='multiple-number-tile cursor-pointer bg-white bg-tile height-500'>
                <div className='display-flex flex-column justify-content-center align-items-center height-100pct'>
                    <div className='display-flex flex-column col-md-6'>
                        {tileDescriptor.values.map((value) => {
                            const numericalValue = getOr(0, value.dataPath, sourceData);
                            return (
                                <div className='width-100pct padding-x-10 display-flex' key={getID()}>
                                    <span className='flex-0-0 text-size-20 text-bold text-color-darkest'>
                                        {value.label}
                                    </span>
                                    <span className='flex-1-1 text-size-20 text-bold text-color-darkest text-right'>
                                        <FormattedNumber value={numericalValue}/>
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='position-relative'>
                    <div className={labelWrapperClasses}>
                        <div>
                            <span className={labelClasses}>{tileDescriptor.label}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Tile>
    );
};

export default MultipleNumbersTile;
