// Primary paths
export const DEFAULT_ROUTE = '/home';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_CONFLOGIC = '/conflogic';
export const ROUTE_PARAMETRIZATION = '/parametrization';
export const ROUTE_OVERVIEW = '/overview';
export const ROUTE_UPLOAD = '/upload';

export const ADMIN_NUMBERTILE = 'numberTile';
export const ADMIN_MULTIPLENUMBERTILE = 'multipleNumberTile';
export const ADMIN_QUARTEREDTILE = 'quarteredTile';
export const ADMIN_TABLETILE = 'tableTile';
export const SIGNAL_DIRECTION_IN = 'in';
export const SIGNAL_DIRECTION_OUT = 'out';
export const SIGNAL_TARGET_INPUT_1 = 'input1';
export const SIGNAL_TARGET_INPUT_2 = 'input2';
export const SIGNAL_TARGET_VECTOR = 'vector';
export const SIGNAL_TARGET_FCN = 'fcn';
export const SIGNAL_GROUP_1 = 'levA';
export const SIGNAL_GROUP_2 = 'levB';
export const SIGNAL_GROUP_3 = 'output';
export const SIGNAL_GROUP_3_INPUT_RESULT = 'inputResult';
export const SIGNAL_TARGET_VEHICLE_FUNCTION = 'vehicleFunction';
export const SIGNAL_TARGET_SPEC_FUNCTION = 'specFunction';
export const SIGNAL_TARGET_THRESHOLD_SOURCE = 'source';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_SELECTDIAG = 'selectdiag';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_THRESCAN = 'threscan';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_THRESCL = 'threscl';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_PBSWITCH = 'pbswitch';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_ACTVLVL = 'actvlvl';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_ACTVZDR = 'actvzdr';
export const SIGNAL_ADDITIONAL_PARAMETER_TYPE_CONFIGCLFCN = 'configclfcn';
export const MOST_USED_KEY = 'Most Used';

export const PUSHBUTTON_TYPE_PUSHBUTTON = 'pushbutton';
export const PUSHBUTTON_TYPE_SWITCH = 'switch';

export const TIMER_OPERATOR_VALUE = 'timerValue';
export const TIMER_OPERATOR_DEFAULT_VALUE = 2;

export const CONF_LOGIC_FILE_CONSTANT = 'CL';
export const CONF_LOGIC_FILE_FILL_VALUE = '0';
export const CONF_LOGIC_FILE_FILL_LENGTH_ID = 5;
export const CONF_LOGIC_FILE_FILL_LENGTH_NAME = 2;
export const CONF_LOGIC_FILE_LINE_SUFFIX = '1';

export const COMPONENT_LEVEL_A = 'A';
export const COMPONENT_LEVEL_B = 'B';
export const COMPONENT_LEVEL_OUTPUT = 'O';
export const OUTPUT_VECTOR = 'V';
export const OUTPUT_FUNCTION = 'F';
export const OUTPUT_VEH_FUNCTION = 'FN';
export const OUTPUT_SRC_CONFIG = 'BOSR';
export const OUTPUT_SRC_CONFIG_FIXED_VALUE = '1';
export const OUTPUT_TIMER_CONSTANT = 'T';
export const OUTPUT_PB_KEYPAD_4 = 'PBCTT';
export const OUTPUT_PB_KEYPAD_6 = 'PBCT6';

export const CONF_LOGIC_DIRECTION_ROW = 'row';
export const CONF_LOGIC_DIRECTION_COL = 'col';

export const CONF_LOGIC_COMPONENT_ID_PREFIX = 'logic-component';

export const MAX_OPERATOR_SELECTION_PER_TYPE = 4;
export const OPERATOR_AND = 'And';
export const OPERATOR_DELAY = 'Delay';
export const OPERATOR_FLIPFLOP = 'Flipflop';
export const OPERATOR_NOT = 'Not';
export const OPERATOR_OR = 'Or';
export const OPERATOR_TIMER = 'Timer';
export const OPERATOR_OPTIONS = [
    OPERATOR_AND,
    OPERATOR_OR,
    OPERATOR_NOT,
    OPERATOR_TIMER,
    OPERATOR_DELAY,
    OPERATOR_FLIPFLOP,
];

export const DRAGGING_TYPE_INPUT = 'input';
export const DRAGGING_TYPE_OUTPUT = 'output';

export const THRESCAN_LOWER_VALUE = 0;
export const THRESCAN_1_UPPER_VALUE = 250;
export const THRESCAN_2_UPPER_VALUE = 250;
export const THRESCAN_3_UPPER_VALUE = 250;
export const THRESCAN_4_UPPER_VALUE = 64255;
export const ACTVLVL_LOWER_VALUE = 0;
export const ACTVLVL_UPPER_VALUE = 8;
export const ACTVZDR_LOWER_VALUE = 0;
export const ACTVZDR_UPPER_VALUE = 7;

export const KS_AMOUNT = '{{KS_AMOUNT}}';
export const KS_LINE = '{{KS_LINE}}';
export const KS_NAME = '{{KS_NAME}}';
export const KS_NUMBER = '{{KS_NUMBER}}';

export const INPUT_DIAG_PIN_NO_DIAG = 0;
export const INPUT_DIAG_PIN_DIAG_ON_CLAMP_15 = 1;
export const INPUT_DIAG_PIN_PERMANENT_DIAG = 2;
