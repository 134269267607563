import { getHTTP } from '@/common/utils/sagaUtils';
import { config } from '@/config';
import { endpointAPI } from '@/data/endpointAPI';
import { getAdminDataSuccess } from '@/features/admin/adminSlice';
import { call, put, takeLatest } from 'redux-saga/effects';

const appDataServiceUrl = config.backend.APP_DATA_SERVICE;

export function* doFetchAdminDataSaga() {
    try {
        console.groupCollapsed('doFetchAdminDataSaga Start');
        const url = `${appDataServiceUrl}/admindata`;
        const adminData = import.meta.env.PROD ? endpointAPI.conflogicSignals : yield call(getHTTP, url);
        if (!adminData) {
            throw new Error('Admin data are not available');
        }
        const { purchased, usage, bodybuilders, mostused } = adminData;
        console.info(adminData);
        yield put(
            getAdminDataSuccess({
                purchased,
                usage,
                bodybuilders,
                mostused,
            }),
        );
        console.info('doFetchAdminDataSaga End');
    } catch (error) {
        // yield put(getAdminDataFailure());
        console.info('doFetchAdminDataSaga End');
    } finally {
        console.groupEnd();
    }
}

export function* fetchAdminDataSaga() {
    yield takeLatest('admin/getAdminDataFetch', doFetchAdminDataSaga);
}
