export const ROLES_TCT = 'tctRoles';
export const ROLE_RIO_TCT_ADMIN = 'rio.tct.admin';
export const ROLE_RIO_TCT_DEVELOPER = 'rio.tct.developer';
export const ROLE_RIO_TCT_BODYBUILDER = 'rio.tct.bodybuilder';
export const RIO_PERMISSION_APP_UPLOAD = 'app.upload';

export const PERMISSION_MENU_SHOW_FILEUPLOAD = ROLE_RIO_TCT_DEVELOPER;
export const PERMISSION_ROUTE_SHOW_FILEUPLOAD = ROLE_RIO_TCT_DEVELOPER;
export const PERMISSION_OVERVIEW_SHOW_RETROFITFILE = ROLE_RIO_TCT_DEVELOPER;

export const viewComponentPermissions = {
    home: [ROLE_RIO_TCT_ADMIN, ROLE_RIO_TCT_DEVELOPER, ROLE_RIO_TCT_BODYBUILDER],
    admin: [ROLE_RIO_TCT_ADMIN, ROLE_RIO_TCT_DEVELOPER],
    parametrization: [ROLE_RIO_TCT_ADMIN, ROLE_RIO_TCT_DEVELOPER, ROLE_RIO_TCT_BODYBUILDER],
    confLogic: [ROLE_RIO_TCT_ADMIN, ROLE_RIO_TCT_DEVELOPER, ROLE_RIO_TCT_BODYBUILDER],
    overview: [ROLE_RIO_TCT_ADMIN, ROLE_RIO_TCT_DEVELOPER, ROLE_RIO_TCT_BODYBUILDER],
    upload: [ROLE_RIO_TCT_DEVELOPER],
};

export const uiComponentPermissions = {
    overview: {
        showRetrofitFile: [ROLE_RIO_TCT_DEVELOPER],
    },
};
