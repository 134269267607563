import { getHTTP } from '@/common/utils/sagaUtils';
import { config } from '@/config';
import { endpointAPI } from '@/data/endpointAPI';
import { getCustomerFailure, getCustomerSuccess } from '@/features/home/customerSlice';
import { setSelectedVehicle } from '@/features/home/vehicleSlice';
import { all, call, put, takeLatest } from 'redux-saga/effects';

const appDataServiceUrl = config.backend.APP_DATA_SERVICE;

function* doFetchCustomerSaga() {
    console.groupCollapsed('doFetchCustomerSaga Start');
    const url = `${appDataServiceUrl}/customer`;
    try {
        const customer = import.meta.env.PROD ? endpointAPI.customer : yield call(getHTTP, url);
        if (!customer) {
            throw new Error('Customer is empty');
        }
        console.info(customer);
        yield all([yield put(getCustomerSuccess(customer)), yield put(setSelectedVehicle(customer?.vehicles[0]))]);
    } catch (error) {
        yield put(getCustomerFailure());
    }
    console.info('doFetchCustomerSaga End');
    console.groupEnd();
}

// ///////////// KEEP /////////////
// function* doFetchCustomerSaga() {
//     const customer = endpointAPI.customer;
//     yield all([
//         yield put(getCustomerSuccess(customer)),
//         yield put(setSelectedVehicle(customer?.vehicles[0])),
//         // yield put(setSelectedVehicleVIN(customer?.vehicles[0].vin)),
//     ]);
// }

function* doAddNewVehicleSaga(vehicle) {
    console.groupCollapsed('doAddNewVehicleSaga Start');
    console.info('Add new vehicle: ', vehicle);
    // Notification.info(<span>{'Vehicle added'}</span>, 'DUMMY DATA', 5000, () => {
    //     // Notification.success('Clicked');
    // });
    // const vehicle = input.payload;
    // const url = `${appDataServiceUrl}/customer/vehicle/add`;
    // const customer = yield call(postHTTP, url, JSON.stringify({ vehicle: { ...vehicle } }));
    // const inputVehicle = customer.vehicles.find(v => v.vin === vehicle.vin);
    // yield put(mergeCustomer(customer, { entityName: ENTITY_MERGE_CUSTOMER }));
    // yield put(setSelectedVehicle(inputVehicle.vin, { entityName: ENTITY_SET_SELECTED_VEHICLE }));
    console.info('doAddNewVehicleSaga End');
    console.groupEnd();
}

function* doEditVehicleSaga(vehicle) {
    console.groupCollapsed('doEditVehicleSaga Start');
    console.info('Edit vehicle: ', vehicle);
    // Notification.info(<span>{'Vehicle edited'}</span>, 'DUMMY DATA', 5000, () => {
    //     // Notification.success('Clicked');
    // });
    // const vehicle = input.payload;
    // const url = `${appDataServiceUrl}/customer/vehicle/edit`;
    // const customer = yield call(putHTTP, url, JSON.stringify({ vehicle: { ...vehicle } }));
    // const inputVehicle = customer.vehicles.find(v => v.vin === vehicle.vin);
    // yield put(mergeCustomer(customer, { entityName: ENTITY_MERGE_CUSTOMER }));
    // yield put(setSelectedVehicle(inputVehicle.vin, { entityName: ENTITY_SET_SELECTED_VEHICLE }));
    console.info('doEditVehicleSaga End');
    console.groupEnd();
}

function* doRemoveVehicleSaga(vin) {
    console.groupCollapsed('doRemoveVehicleSaga Start');
    console.info('Remove vehicle: ', vin);
    // Notification.info(
    //     <span data-testid="vehicleRemovedNotification">{'Vehicle removed'}</span>,
    //     'DUMMY DATA',
    //     5000,
    //     () => {
    //         // Notification.success('Clicked');
    //     }
    // );
    // const vin = input.payload;
    // const url = `${appDataServiceUrl}/customer/vehicle/remove`;
    // const customer = yield call(postHTTP, url, JSON.stringify({ vin }));
    // yield put(mergeCustomer(customer, { entityName: ENTITY_MERGE_CUSTOMER }));
    // if (size(customer.vehicles)){
    //     yield put(setSelectedVehicle(customer.vehicles[0].vin, { entityName: ENTITY_SET_SELECTED_VEHICLE }));
    // }
    console.info('doRemoveVehicleSaga End');
    console.groupEnd();
}

export function* fetchCustomerSaga() {
    yield takeLatest('customer/getCustomerFetch', doFetchCustomerSaga);
}

export function* addVehicleSaga() {
    yield takeLatest('customer/addNewVehicle', doAddNewVehicleSaga);
}

export function* editVehicleSaga() {
    yield takeLatest('customer/editVehicle', doEditVehicleSaga);
}

export function* removeVehicleSaga() {
    yield takeLatest('customer/removeVehicle', doRemoveVehicleSaga);
}
