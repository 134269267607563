import { DEFAULT_ROUTE } from '@/common/constants/commonConstants';
// import { selectedVehicleSelector } from '@/common/selectors/vehicleSelectors';
import ConfigurationLink from '@/components/ConfigurationLink';
import { config } from '@/config';
import { useAppSelector } from '@/configuration/setup/hooks';
import { getCustomer } from '@/features/home/customerSlice';
import { getSelectedVehicle } from '@/features/home/vehicleSlice';
import InfoDialog from '@rio-cloud/rio-uikit/InfoDialog';
import { size } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

const VinNotMatchingDialog = () => {
    const show = false;
    const location = useLocation();
    // const vehicleVIN = useSelector(selectedVehicleSelector);
    const customer = useAppSelector(getCustomer);
    const vehicle = useAppSelector(getSelectedVehicle);

    if (location.pathname !== '/' && location.pathname !== DEFAULT_ROUTE) {
        if (!size(vehicle) && !size(customer)) {
            window.location.href = config.homeRoute;
        }
    }

    return (
        <InfoDialog
            show={show}
            content={
                <div className='text-center'>
                    <div className='margin-bottom-25'>
                        <div className='text-size-h2'>{'VIN mismatch'}</div>
                        <p>{'The internal VIN and VIN from url did not match.'}</p>
                    </div>
                    <div className={'pull-right btn-toolbar'}>
                        <ConfigurationLink
                            link={'/home'}
                            label={'Redirecting to Home'}
                            background={'bg-primary'}
                            icon={'rioglyph-home'}
                            show
                        >
                            <FormattedMessage id='intl-msg:home.backtohome' defaultMessage='MISSING'/>
                        </ConfigurationLink>
                    </div>
                </div>
            }
        />
    );
};

export default VinNotMatchingDialog;
