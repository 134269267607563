import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import userReducer from '../../features/users/userSlice';

import entityReducers from './reducers/entityReducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
// import {initOperatorOptions} from "../../common/actions/operatorOptionsActions";
export const sagaMiddleware = createSagaMiddleware();

const loggingMiddleware = () => {
    return (next: any) => {
        return (action: any) => {
            console.groupCollapsed(action.type);
            console.info(action);
            console.info(store.getState());
            console.groupEnd();
            return next(action);
        };
    };
};

export const setupStore = (preloadedState: any) => {
    console.info('setupStore: preloadedState: ', preloadedState);

    return configureStore({
        reducer: {
            config: configReducer,
            lang: langReducer,
            app: appReducer,
            user: userReducer,
            login: loginReducer,
            tokenHandling: tokenReducer,
            entities: entityReducers,

            // Add the generated reducer as a specific top-level slice
            // [userApi.reducerPath]: userApi.reducer,
        },
        preloadedState,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        // middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false}).concat(userApi.middleware).concat(sagaMiddleware),
        // middleware,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({ thunk: false })
                // .concat(userApi.middleware)
                .concat(loggingMiddleware)
                .concat(sagaMiddleware),
    });
};

export const store = setupStore({});

// export const store = configureStore({
//     reducer: {
//         config: configReducer,
//         lang: langReducer,
//         app: appReducer,
//         user: userReducer,
//         login: loginReducer,
//         tokenHandling: tokenReducer,
//         entities: entityReducers,
//
//         // Add the generated reducer as a specific top-level slice
//         [userApi.reducerPath]: userApi.reducer,
//     },
//     // Adding the api middleware enables caching, invalidation, polling,
//     // and other useful features of `rtk-query`.
//     // middleware: (getDefaultMiddleware) => getDefaultMiddleware({thunk: false}).concat(userApi.middleware).concat(sagaMiddleware),
//     // middleware,
//     middleware: (getDefaultMiddleware) =>
//         getDefaultMiddleware({ thunk: false })
//             .concat(userApi.middleware)
//             .concat(loggingMiddleware)
//             .concat(sagaMiddleware),
// });

sagaMiddleware.run(rootSaga);

// store.dispatch(initOperatorOptions(operatorOptions));

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
