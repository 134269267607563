import { getID } from '@/common/utils/commonUtils';
import { QuarterTile } from '@/features/admin/QuarterTile';
import Tile from '@/features/admin/Tile';
import RadialBarChart from '@rio-cloud/rio-uikit/RadialBarChart';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import useWindowResize from '@rio-cloud/rio-uikit/useWindowResize';
import getOr from 'lodash/fp/getOr';
import React, { useLayoutEffect, useRef, useState } from 'react';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

const QuarteredTile = ({ sourceData, values }) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const tileRef = useRef(null);
    useWindowResize(() => {
        setWidth(tileRef.current.offsetWidth);
        setHeight(tileRef.current.offsetHeight);
    });
    useLayoutEffect(() => {
        setWidth(tileRef.current.offsetWidth);
        setHeight(tileRef.current.offsetHeight);
    }, [values]);
    const size =
        values.length === 1
            ? { height: 'height-100pct', width: 'width-100pct' }
            : values.length === 2
                ? { height: 'height-50pct', width: 'width-50pct' }
                : { height: 'height-50pct', width: 'width-50pct' };
    const tiles = values.map((tileDescriptor) => {
        const value = getOr(0, tileDescriptor.dataPath, sourceData);
        const allUsers = Object.keys(sourceData.bodybuilders).length;
        const percent = (100 / allUsers) * +value;
        const bar = [
            {
                name: value.label,
                value: percent,
                color:
                    percent > 80
                        ? 'color-coldplay-fountain'
                        : percent > 50
                            ? 'color-warmup-salmon'
                            : 'color-warmup-cadillac',
            },
        ];
        const tooltipContent = <div className='width-200'>{tileDescriptor.tooltip}</div>;
        return (
            <QuarterTile tileRef={tileRef} key={getID()} size={size}>
                <div className='display-flex justify-content-end'>
                    <SimpleTooltip key={getID()} content={tooltipContent} placement='bottom'>
                        <span className='rioglyph rioglyph-info-sign text-size-20'/>
                    </SimpleTooltip>
                </div>
                <RadialBarChart
                    cx={width / 2}
                    cy={100}
                    height={height}
                    width={width}
                    data={bar}
                    dataKey='value'
                    startAngle={90}
                    endAngle={-270}
                    innerRadius={50}
                    outerRadius={70}
                    legend={false}
                    range={[0, 100]}
                    textOptions={{
                        x: width / 2,
                        y: 100,
                        text: `${percent}%`,
                        size: '150%',
                        color: 'bg-black',
                    }}
                />
                <div className='position-relative'>
                    <div className={labelWrapperClasses}>
                        <span className={labelClasses}>{tileDescriptor.label}</span>
                    </div>
                </div>
            </QuarterTile>
        );
    });
    return (
        <Tile>
            <div className='helper height-500 width-100pct display-flex flex-wrap'>{tiles}</div>
        </Tile>
    );
};

export default QuarteredTile;
