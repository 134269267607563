import {
    COMPONENT_LEVEL_A,
    COMPONENT_LEVEL_B,
    COMPONENT_LEVEL_OUTPUT,
    OPERATOR_DELAY,
    OPERATOR_NOT,
    OPERATOR_TIMER,
    SIGNAL_GROUP_1,
    SIGNAL_GROUP_2,
    SIGNAL_GROUP_3,
    SIGNAL_GROUP_3_INPUT_RESULT,
    SIGNAL_TARGET_FCN,
    SIGNAL_TARGET_INPUT_1,
    SIGNAL_TARGET_INPUT_2,
    TIMER_OPERATOR_DEFAULT_VALUE,
    TIMER_OPERATOR_VALUE,
} from '@/common/constants/commonConstants';
import { createSlice } from '@reduxjs/toolkit';
import { eq, size } from 'lodash';

const initialState = {
    inputVectors: {},
    sourceSignals: [],
    resultSignals: {},
    outputVectors: {},
    outputFunctions: {},
    operatorOptions: {},
    vehicleFunctions: [],
    selectedVehicleFunction: {},
    selectedThresholdSourceSignal: {},
    selectedSpecFunction: {},
    logicComponents: [],
    newLogicComponents: [],
    retroFitFileContents: [],
    currentInputPath: [],
    draggingType: '',
    incomingSidebarClosed: true,
    outgoingSidebarClosed: true,
};

const inputSignalTemplate = {
    id: 0,
    fSignal: 'Drop Input Vector',
    description: 'Select a vector from list left hand side',
    additional_parameter: '',
    additional_information: '',
    category: '',
    valid: false,
    specFunction: {},
    customTitle: undefined,
};
const inputResultTemplate = {
    id: 250,
    fSignal: 'Insert Result or Signal',
    description: 'Description',
    additional_parameter: '',
    additional_information: '',
    category: '',
    valid: false,
    customTitle: undefined,
};
const fcnTemplate = {
    parameter: 'Insert Output Function',
    description: 'Description',
    additional_parameter: '',
    additional_information: '',
    valid: false,
    vehicleFunction: {},
};

export const confLogicSlice = createSlice({
    name: 'confLogic',
    initialState,
    // reducers: {
    //     // ❌ ERROR: mutates state, but also returns new array size!
    //     brokenReducer: (state, action) => state.push(action.payload),
    //     // ✅ SAFE: the `void` keyword prevents a return value
    //     fixedReducer1: (state, action) => void state.push(action.payload),
    //     // ✅ SAFE: curly braces make this a function body and no return
    //     fixedReducer2: (state, action) => {
    //         state.push(action.payload)
    //     },
    //     brokenTodosLoadedReducer(state, action) {
    //         // ❌ ERROR: does not actually mutate or return anything new!
    //         state = action.payload
    //     },
    //     fixedTodosLoadedReducer(state, action) {
    //         // ✅ CORRECT: returns a new value to replace the old one
    //         return action.payload
    //     },
    //     correctResetTodosReducer(state, action) {
    //         // ✅ CORRECT: returns a new value to replace the old one
    //         return initialState
    //     },
    //     brokenTodoToggled(state, action) {
    //         const todo = state.find((todo) => todo.id === action.payload)
    //         if (todo) {
    //             // ❌ ERROR: Immer can't track updates to a primitive value!
    //             let { completed } = todo
    //             completed = !completed
    //         }
    //     },
    //     fixedTodoToggled(state, action) {
    //         const todo = state.find((todo) => todo.id === action.payload)
    //         if (todo) {
    //             // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
    //             todo.completed = !todo.completed
    //         }
    //     },
    // },
    reducers: {
        getConfLogicSignalsFetch: () => {},
        getConfLogicSignalsSuccess: (state, action) => {
            state.inputVectors = action.payload.inputVectors;
            state.resultSignals = {
                ResultsLevelA: action.payload.outputVectorsLevelA,
                ResultsLevelB: action.payload.outputVectorsLevelB,
            };
            state.outputVectors = action.payload.outputVectors;
            state.outputFunctions = action.payload.outputFunctions;
            state.vehicleFunctions = action.payload.vehicleFunctions;
            state.sourceSignals = action.payload.sourceSignals;
        },
        getConfLogicOperatorOptionsFetch: () => {},
        getConfLogicOperatorOptionsSuccess: (state, action) => {
            state.operatorOptions = action.payload;
        },
        addNewConfLogicComponent: (state, action) => {
            const { newId, groupId, level, parentRelation, resultParentLayer } = action.payload;
            const abbr = level === SIGNAL_GROUP_1 ? COMPONENT_LEVEL_A : COMPONENT_LEVEL_B;
            // O => B => A
            let levBInput1Obj = { ...inputSignalTemplate };
            if (level === SIGNAL_GROUP_2) {
                const levAComp = state.newLogicComponents.filter(
                    (comps) => comps.groupId === groupId && comps[SIGNAL_GROUP_1],
                );
                if (eq(size(levAComp), 1)) {
                    levBInput1Obj = {
                        ...levAComp[0].result,
                        valid: true,
                    };
                }
            }
            const levelTemplate = {
                operator: parentRelation.operator,
                operatorCount: parentRelation.operatorCount,
                validSignalGroup: false,
                input1: {
                    ...levBInput1Obj,
                },
            };
            if (eq(parentRelation.operator, OPERATOR_TIMER)) {
                levelTemplate.timerValue = TIMER_OPERATOR_DEFAULT_VALUE;
            }
            const newComponent = {
                id: newId,
                groupId,
                abb: abbr,
                title: resultParentLayer.fSignal,
                result: resultParentLayer,
                created: new Date().toLocaleString(),
                valid: false,
                open: true,
                [level]: {
                    ...levelTemplate,
                },
            };
            if (
                !eq(parentRelation.operator, OPERATOR_NOT) &&
                !eq(parentRelation.operator, OPERATOR_DELAY) &&
                !eq(parentRelation.operator, OPERATOR_TIMER)
            ) {
                newComponent[level].input2 = {
                    ...inputSignalTemplate,
                };
            }
            state.newLogicComponents.push(newComponent);
        },
        addNewConfLogicOutputComponent: (state, action) => {
            const { newId, groupId, outputVector } = action.payload;
            const levBComp = state.newLogicComponents.filter(
                (comps) => comps.groupId === groupId && comps[SIGNAL_GROUP_2],
            );
            let levOInput1Obj = { ...inputSignalTemplate };
            if (eq(size(levBComp), 1)) {
                levOInput1Obj = {
                    ...levBComp[0].result,
                    valid: true,
                };
            }
            if (eq(size(levBComp), 0)) {
                levOInput1Obj = inputSignalTemplate;
                const levAComp = state.newLogicComponents.filter(
                    (comps) => comps.groupId === groupId && comps[SIGNAL_GROUP_1],
                );
                if (size(levAComp)) {
                    levOInput1Obj = {
                        ...levAComp[0].result,
                        valid: true,
                    };
                }
            }

            const newComponent = {
                id: newId,
                groupId,
                abb: COMPONENT_LEVEL_OUTPUT,
                title: outputVector.parameter,
                result: {
                    fSignal: 'Insert Result',
                    description: 'Description',
                },
                created: new Date().toLocaleString(),
                valid: false,
                open: true,
                output: {
                    validSignalGroup: false,
                    inputResult: { ...levOInput1Obj },
                    vector: { ...outputVector },
                    fcn: { ...fcnTemplate },
                },
            };
            state.newLogicComponents.push(newComponent);
        },
        deleteNewConfLogicComponent: (state, action) => {
            const { id } = action.payload;
            const components = [...state.newLogicComponents];
            const componentToDelete = JSON.parse(JSON.stringify(components.filter((c) => c.id === id)[0]));
            const changedComponents = components.map((oC) => {
                if (oC.abb === componentToDelete.abb) {
                    return oC;
                }
                if (oC.abb === COMPONENT_LEVEL_OUTPUT && oC.output.inputResult.fSignal === componentToDelete.title) {
                    oC.output.inputResult = inputSignalTemplate;
                }
                if (oC.abb !== COMPONENT_LEVEL_OUTPUT) {
                    [SIGNAL_GROUP_1, SIGNAL_GROUP_2].forEach((level) => {
                        if (oC[level]) {
                            [SIGNAL_TARGET_INPUT_1, SIGNAL_TARGET_INPUT_2].forEach((input) => {
                                if (oC[level][input]) {
                                    if (oC[level][input].fSignal === componentToDelete.title) {
                                        oC[level][input] = inputSignalTemplate;
                                    }
                                }
                            });
                        }
                    });
                }
                return oC;
            });
            state.newLogicComponents = changedComponents.filter((c) => c.id !== id);
        },
        changeNewConfLogicTitle: (state, action) => {
            const { id, title } = action.payload;
            const changedTitleComponent = state.newLogicComponents.filter((c) => c.id === id)[0];
            const unfrozenTitleComponent = JSON.parse(JSON.stringify(changedTitleComponent));
            unfrozenTitleComponent.customTitle = title;
            unfrozenTitleComponent.result.customTitle = title;
            state.newLogicComponents = state.newLogicComponents.map((c) => {
                const unfrozen = JSON.parse(JSON.stringify(c));
                if (unfrozen.id === id) {
                    unfrozen.customTitle = title;
                    unfrozen.result.customTitle = title;
                    return unfrozen;
                }
                if (unfrozen.abb !== COMPONENT_LEVEL_A) {
                    [SIGNAL_GROUP_1, SIGNAL_GROUP_2, SIGNAL_GROUP_3].forEach((level) => {
                        if (unfrozen[level]) {
                            [SIGNAL_TARGET_INPUT_1, SIGNAL_TARGET_INPUT_2, SIGNAL_GROUP_3_INPUT_RESULT].forEach(
                                (input) => {
                                    if (unfrozen[level][input]) {
                                        if (unfrozen[level][input].fSignal === unfrozenTitleComponent.result.fSignal) {
                                            unfrozen[level][input].customTitle = title;
                                        }
                                    }
                                },
                            );
                        }
                    });
                }
                return unfrozen;
            });
        },
        changeNewConfLogicVector: (state, action) => {
            const { id, artifact, path } = action.payload;
            const [, signalGroup, input] = path;
            const index = state.newLogicComponents.findIndex((lc) => lc.id === id);
            const unfrozenComponent = { ...state.newLogicComponents[index] };
            unfrozenComponent[signalGroup][input] = { ...artifact };
            if (eq(signalGroup, SIGNAL_GROUP_1) || eq(signalGroup, SIGNAL_GROUP_2)) {
                const input1Valid = unfrozenComponent[signalGroup][SIGNAL_TARGET_INPUT_1].valid;
                const input2Valid = unfrozenComponent[signalGroup][SIGNAL_TARGET_INPUT_2]
                    ? unfrozenComponent[signalGroup][SIGNAL_TARGET_INPUT_2].valid
                    : true;
                unfrozenComponent[signalGroup].validSignalGroup = input1Valid && input2Valid;
            }
            if (eq(signalGroup, SIGNAL_GROUP_3)) {
                unfrozenComponent[signalGroup].validSignalGroup =
                    unfrozenComponent[signalGroup][SIGNAL_TARGET_FCN].valid;
            }
            unfrozenComponent.valid = unfrozenComponent[signalGroup].validSignalGroup;
            const unfrozenState = [...state.newLogicComponents];
            unfrozenState.splice(index, 1, unfrozenComponent);
            state.newLogicComponents = unfrozenState;
        },
        resetNewConfLogicVector: (state, action) => {
            const { path } = action.payload;
            state.newLogicComponents = state.newLogicComponents.map((component) => {
                if (component.id === path[0]) {
                    component.valid = false;
                    component[path[1]][path[2]] = inputSignalTemplate;
                }
                return component;
            });
        },
        changeNewConfLogicOperators: (state, action) => {
            const { componentId, newOperator, oldOperator, path, layerRelation, modifiedParentResult } = action.payload;
            const components = [...state.newLogicComponents];
            const componentToChange = components.find((c) => c.id === componentId);
            if (componentToChange[path].operator === oldOperator.label) {
                componentToChange[path].operator = newOperator.label;
                componentToChange[path].operatorCount = layerRelation.operatorCount;
                componentToChange.title = modifiedParentResult.fSignal;
                componentToChange.result = { ...modifiedParentResult };
            }
            // not, delay, timer --> only input1
            const deleteSignalInput2 = () => {
                if (componentToChange[path][SIGNAL_TARGET_INPUT_2]) {
                    delete componentToChange[path][SIGNAL_TARGET_INPUT_2];
                }
            };
            if (eq(newOperator.label, OPERATOR_TIMER)) {
                deleteSignalInput2();
                componentToChange[path].timerValue = TIMER_OPERATOR_DEFAULT_VALUE;
            } else {
                delete componentToChange[path].timerValue;
                if (eq(newOperator.label, OPERATOR_NOT) || eq(newOperator.label, OPERATOR_DELAY)) {
                    deleteSignalInput2();
                } else {
                    if (!componentToChange[path][SIGNAL_TARGET_INPUT_2]) {
                        componentToChange[path][SIGNAL_TARGET_INPUT_2] = {
                            ...inputSignalTemplate,
                        };
                    }
                }
            }
            state.newLogicComponents = components;
        },
        removeNewConfLogicOutputVectors: (state, action) => {
            const { outputVector } = action.payload;
            const outputVectors = [...state.outputVectors];
            const logicComponents = [...state.newLogicComponents];
            const oComponents = logicComponents.filter(o => o.abb === COMPONENT_LEVEL_OUTPUT);
            oComponents.forEach(c => {
                const index = outputVectors.findIndex(v => v.id === c.output.vector.id);
                if (index >= 0){
                    outputVectors.splice(index,  1);
                }
            });
            state.outputVectors = outputVectors;
        },
        returnNewConfLogicOutputVectors: (state, action) => {
            const { outputVector } = action.payload;
            const outputVectors = [...state.outputVectors];
            const index = outputVectors.findIndex((v) => v.id === outputVector.id);
            if (index === -1) {
                outputVectors.push(outputVector);
            }
            outputVectors.sort((a, b) => a.id - b.id);
            state.outputVectors = outputVectors;
        },
        setSelectedVehicleFunction: (state, action) => {
            const { vehicleFunctionId } = action.payload;
            const functions = [...state.vehicleFunctions];
            state.selectedVehicleFunction = { ...functions.find((f) => f.id === vehicleFunctionId) };
        },
        resetSelectedVehicleFunction: (state, action) => {
            state.selectedVehicleFunction = {};
        },
        updateNewLogicComponentWithVehicleFunction: (state, action) => {
            const { componentId, selectedVehicleFunction, path } = action.payload;
            const [id, layer, input] = path;
            const logicComponents = [...state.newLogicComponents];
            const componentToChange = logicComponents.find((c) => c.id === componentId);
            componentToChange[layer][input].valid = true;
            componentToChange[layer][input].vehicleFunction = selectedVehicleFunction;
            const index = logicComponents.findIndex((c) => c.id === componentId);
            logicComponents.splice(index, 1, componentToChange);
            state.newLogicComponents = logicComponents;
        },
        setSelectedThresholdSourceSignal: (state, action) => {
            const { sourceSignalId, value } = action.payload;
            const sourceSignals = [...state.sourceSignals];
            const modSourceSignal = { ...sourceSignals.find((signal) => signal.id === sourceSignalId) };
            modSourceSignal.value = value;
            state.selectedThresholdSourceSignal = modSourceSignal;
        },
        resetSelectedThresholdSourceSignal: (state, action) => {
            state.selectedThresholdSourceSignal = {};
        },
        updateNewLogicComponentSignalLayerWithThresholdSourceSignal: (state, action) => {
            const { componentId, selectedThresholdSourceSignal, path } = action.payload;
            const [id, layer, input] = path;
            const logicComponents = [...state.newLogicComponents];
            const componentToChange = logicComponents.find((c) => c.id === componentId);
            componentToChange[layer][input].source = selectedThresholdSourceSignal;
            const index = logicComponents.findIndex((c) => c.id === componentId);
            logicComponents.splice(index, 1, componentToChange);
            state.newLogicComponents = logicComponents;
        },
        setSelectedSpecFunction: (state, action) => {
            const { componentId, path, value } = action.payload;
            state.selectedSpecFunction = { componentId, path, value };
        },
        resetSelectedSpecFunction: (state, action) => {
            state.selectedSpecFunction = {};
        },
        updateNewLogicComponentSignalLayerWithSpecFunction: (state, action) => {
            const { componentId, selectedSpecFunction, path } = action.payload;
            const [id, layer, input] = path;
            const logicComponents = [...state.newLogicComponents];
            const componentToChange = logicComponents.find((c) => c.id === componentId);
            componentToChange[layer][input].specFunction = selectedSpecFunction;
            const index = logicComponents.findIndex((c) => c.id === componentId);
            logicComponents.splice(index, 1, componentToChange);
            state.newLogicComponents = logicComponents;
        },
        setCurrentInputPath: (state, action) => {
            const { path } = action.payload;
            state.currentInputPath = path;
        },
        setDraggingType: (state, action) => {
            const { draggingType } = action.payload;
            state.draggingType = draggingType;
        },
        setTimerOperatorValue: (state, action) => {
            const { componentId, timerValue } = action.payload;
            const index = state.newLogicComponents.findIndex((lc) => lc.id === componentId);
            const unfrozenComponent = { ...state.newLogicComponents[index] };
            const signalGroup = unfrozenComponent[SIGNAL_GROUP_1] ? SIGNAL_GROUP_1 : SIGNAL_GROUP_2;
            unfrozenComponent[signalGroup][TIMER_OPERATOR_VALUE] = +timerValue;
            const unfrozenState = [...state.newLogicComponents];
            unfrozenState.splice(index, 1, unfrozenComponent);
            state.newLogicComponents = unfrozenState;
        },
        toggleSidebar: (state, action) => {
            const { direction, closed } = action.payload;
            if (eq(direction, 'in')) {
                state.incomingSidebarClosed = closed;
            }
            if (eq(direction, 'out')) {
                state.outgoingSidebarClosed = closed;
            }
        },
    },
});

export const getConfLogicInputVectors = (state) => state.entities.confLogic.inputVectors;
export const getConfLogicResultSignals = (state) => state.entities.confLogic.resultSignals;
export const getConfLogicOutputVectors = (state) => state.entities.confLogic.outputVectors;
export const getConfLogicOutputFunctions = (state) => state.entities.confLogic.outputFunctions;
export const getConfLogicVehicleFunctions = (state) => state.entities.confLogic.vehicleFunctions;
export const getConfLogicSelectedVehicleFunction = (state) => state.entities.confLogic.selectedVehicleFunction;
export const getConfLogicSelectedThresholdSourceSignal = (state) =>
    state.entities.confLogic.selectedThresholdSourceSignal;
export const getConfLogicSelectedSpecFunction = (state) => state.entities.confLogic.selectedSpecFunction;
export const getConfLogicOperatorOptions = (state) => state.entities.confLogic.operatorOptions;
export const getNewConfLogicComponents = (state) => state.entities.confLogic.newLogicComponents;
export const getCurrentInputPath = (state) => state.entities.confLogic.currentInputPath;
export const getConfLogicThresholdSourceSignals = (state) => state.entities.confLogic.sourceSignals;
export const getDraggingType = (state) => state.entities.confLogic.draggingType;
export const getIncomingSidebar = (state) => state.entities.confLogic.incomingSidebarClosed;
export const getOutgoingSidebar = (state) => state.entities.confLogic.outgoingSidebarClosed;
export const {
    getConfLogicSignalsFetch,
    getConfLogicSignalsSuccess,
    getConfLogicOperatorOptionsFetch,
    getConfLogicOperatorOptionsSuccess,
    deleteNewConfLogicComponent,
    addNewConfLogicComponent,
    addNewConfLogicOutputComponent,
    changeNewConfLogicTitle,
    changeNewConfLogicVector,
    resetNewConfLogicVector,
    changeNewConfLogicOperators,
    removeNewConfLogicOutputVectors,
    returnNewConfLogicOutputVectors,
    setSelectedVehicleFunction,
    resetSelectedVehicleFunction,
    setSelectedThresholdSourceSignal,
    resetSelectedThresholdSourceSignal,
    setSelectedSpecFunction,
    resetSelectedSpecFunction,
    updateNewLogicComponentWithVehicleFunction,
    updateNewLogicComponentSignalLayerWithThresholdSourceSignal,
    updateNewLogicComponentSignalLayerWithSpecFunction,
    setCurrentInputPath,
    setDraggingType,
    setTimerOperatorValue,
    toggleSidebar,
} = confLogicSlice.actions;
export default confLogicSlice.reducer;
