import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    purchased: [],
    usage: {},
    bodybuilders: [],
    mostused: [],
};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    // reducers: {
    //     // ❌ ERROR: mutates state, but also returns new array size!
    //     brokenReducer: (state, action) => state.push(action.payload),
    //     // ✅ SAFE: the `void` keyword prevents a return value
    //     fixedReducer1: (state, action) => void state.push(action.payload),
    //     // ✅ SAFE: curly braces make this a function body and no return
    //     fixedReducer2: (state, action) => {
    //         state.push(action.payload)
    //     },
    //     brokenTodosLoadedReducer(state, action) {
    //         // ❌ ERROR: does not actually mutate or return anything new!
    //         state = action.payload
    //     },
    //     fixedTodosLoadedReducer(state, action) {
    //         // ✅ CORRECT: returns a new value to replace the old one
    //         return action.payload
    //     },
    //     correctResetTodosReducer(state, action) {
    //         // ✅ CORRECT: returns a new value to replace the old one
    //         return initialState
    //     },
    //     brokenTodoToggled(state, action) {
    //         const todo = state.find((todo) => todo.id === action.payload)
    //         if (todo) {
    //             // ❌ ERROR: Immer can't track updates to a primitive value!
    //             let { completed } = todo
    //             completed = !completed
    //         }
    //     },
    //     fixedTodoToggled(state, action) {
    //         const todo = state.find((todo) => todo.id === action.payload)
    //         if (todo) {
    //             // ✅ CORRECT: This object is still wrapped in a Proxy, so we can "mutate" it
    //             todo.completed = !todo.completed
    //         }
    //     },
    // },
    reducers: {
        getAdminDataFetch: () => {},
        getAdminDataSuccess: (state, action) => {
            state.bodybuilders = action.payload.bodybuilders;
            state.mostused = action.payload.mostused;
            state.purchased = action.payload.purchased;
            state.usage = action.payload.usage;
        },
    },
});

export const getAdminData = (state) => state.entities.admin;
export const { getAdminDataFetch, getAdminDataSuccess } = adminSlice.actions;
export default adminSlice.reducer;
