import Badge from '@/components/Badge';
import InputSignal from '@/components/InputSignal';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';

const DynamicExpander = ({ signals, dragStart, dragEnd, descriptor, output, badge = false }) => {
    const signalWidth = badge ? '9' : '12';
    return (
        <div>
            <table className='table margin-0' data-testid='ksvalue-table'>
                <colgroup>
                    <col className='width-100pct'/>
                </colgroup>
                <tbody>
                    {signals
                        .filter((s) => s[descriptor[1]])
                        .map((signal) => (
                            <tr key={uuid()}>
                                <td className='draggable-container'>
                                    <div className='row display-flex flex-row justify-content-between'>
                                        <div className={`col-ls-${signalWidth}`}>
                                            <InputSignal
                                                key={uuid()}
                                                signal={signal}
                                                dragStart={dragStart}
                                                dragEnd={dragEnd}
                                                descriptor={descriptor}
                                                output={output}
                                            />
                                        </div>
                                        {badge && (
                                            <div className='col-ls-3'>
                                                <Badge title={signal.name}/>
                                            </div>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};
export default DynamicExpander;

DynamicExpander.defaultProps = {
    signals: [],
    dragStart: noop,
    dragEnd: noop,
    descriptor: {},
    output: '',
};

DynamicExpander.propTypes = {
    signals: PropTypes.array,
    dragStart: PropTypes.func,
    dragEnd: PropTypes.func,
    descriptor: PropTypes.object,
    output: PropTypes.string,
};
