import { ADMIN_QUARTEREDTILE, ADMIN_TABLETILE } from '@/common/constants/commonConstants';
import { ROLE_RIO_TCT_ADMIN } from '@/common/constants/permissions';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import { Admin } from '@/features/admin/Admin';
import { getAdminData, getAdminDataFetch } from '@/features/admin/adminSlice';
import ErrorState from '@rio-cloud/rio-uikit/ErrorState';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { compact, size } from 'lodash';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

export const tileDescriptors = compact([
    {
        //     id: 'multipleNumberTile',
        //     label: 'Customer',
        //     type: 'multipleNumberTile',
        //     values: [
        //         {
        //             id: 'purchases',
        //             label: 'Purchased',
        //             type: 'numberTile',
        //             permission: ROLE_RIO_TCT_ADMIN,
        //             dataPath: ['purchased', 'customers'],
        //         },
        //         {
        //             id: 'usage',
        //             label: 'Usage',
        //             type: 'numberTile',
        //             permission: ROLE_RIO_TCT_ADMIN,
        //             dataPath: ['usage', 'customers'],
        //         },
        //     ],
        // }, {
        id: 'quarteredTiles',
        label: <FormattedMessage id=''/>,
        type: ADMIN_QUARTEREDTILE,
        values: [
            {
                id: 'purchases',
                label: <FormattedMessage id='intl-msg:admin.percentPurchased'/>,
                permission: ROLE_RIO_TCT_ADMIN,
                dataPath: ['purchased', 'customers'],
                tooltip: <FormattedMessage id='intl-msg:admin.percentPurchased.tooltip'/>,
            },
            {
                id: 'usages',
                label: <FormattedMessage id='intl-msg:admin.percentUsage'/>,
                permission: ROLE_RIO_TCT_ADMIN,
                dataPath: ['usage', 'customers'],
                tooltip: <FormattedMessage id='intl-msg:admin.percentUsage.tooltip'/>,
            },
        ],
    },
    {
        id: 'customersTableTile',
        label: <FormattedMessage id='intl-msg:admin.customerLocation'/>,
        type: ADMIN_TABLETILE,
        values: [
            {
                id: 'customerLocationTable',
                label: <FormattedMessage id='intl-msg:admin.Customers'/>,
                type: ADMIN_TABLETILE,
                dataPath: ['bodybuilders'],
                keys: ['name', 'country', 'code'],
                sort: 'name',
                permission: ROLE_RIO_TCT_ADMIN,
            },
        ],
    },
    {
        id: 'ksValueTableTile',
        label: <FormattedMessage id='intl-msg:admin.mostUsedKSValues'/>,
        type: ADMIN_TABLETILE,
        values: [
            {
                id: 'mostUsedKsValueTable',
                label: <FormattedMessage id='intl-msg:admin.ksValues'/>,
                type: ADMIN_TABLETILE,
                dataPath: ['mostused'],
                keys: ['name', 'changes'],
                sort: 'changes',
                permission: ROLE_RIO_TCT_ADMIN,
            },
        ],
    },
]);
const AdminContainer = () => {
    const adminData = useAppSelector(getAdminData);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getAdminDataFetch());
    }, [dispatch]);

    return size(adminData) ? (
        <Admin data={adminData}/>
    ) : (
        <ErrorState
            headline={<FormattedMessage id='intl-msg:common.pleaseBePatient'/>}
            message={<FormattedMessage id='intl-msg:common.fetchingCustomerData'/>}
            condensed
        >
            <Spinner/>
        </ErrorState>
    );
};

export default AdminContainer;
