import DynamicExpander from '@/components/DynamicExpander';
import ExpanderPanelHeader from '@/components/ExpanderPanelHeader';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import { eq } from 'lodash';
import React, { useState } from 'react';

const ExpanderPanelContainer = ({
    vectors,
    index,
    title,
    onDragStart,
    onDragEnd,
    expandAllClicked,
    collapseAllClicked,
    descriptor,
    output,
    badge,
}) => {
    const [panelExpanded, setPanelExpanded] = useState(false);
    const onEnter = () => {
        setPanelExpanded(true);
    };
    const onExit = () => {
        setPanelExpanded(false);
    };

    const toggleState = expandAllClicked ? true : collapseAllClicked ? false : panelExpanded;
    return (
        <ExpanderPanel
            data-testid={'ksvalue-' + index}
            title={<ExpanderPanelHeader expanded={panelExpanded} title={title}/>}
            headerClassName={'bg-darker'}
            bsStyle={'default'}
            titleClassName={'text-bold'}
            onEnter={() => onEnter()}
            onExit={() => onExit()}
            bodyClassName={'padding-0'}
            open={eq(index, 0) ? true : toggleState}
        >
            <DynamicExpander
                dragStart={onDragStart}
                dragEnd={onDragEnd}
                signals={vectors}
                descriptor={descriptor}
                output={output}
                badge={badge}
            />
        </ExpanderPanel>
    );
};
export default ExpanderPanelContainer;
