import { getHTTP } from '@/common/utils/sagaUtils';
import { config } from '@/config';
import { endpointAPI } from '@/data/endpointAPI';
import { getConfLogicOperatorOptionsSuccess, getConfLogicSignalsSuccess } from '@/features/conflogic2/confLogicSlice';
import { call, put, takeLatest } from 'redux-saga/effects';

const appDataServiceUrl = config.backend.APP_DATA_SERVICE;

export function* doFetchInputSignalsSaga() {
    console.groupCollapsed('doFetchInputSignalsSaga Start');
    const url = `${appDataServiceUrl}/conflogicsignals`;

    try {
        const confLogicSignals = import.meta.env.PROD ? endpointAPI.conflogicSignals : yield call(getHTTP, url);
        if (!confLogicSignals) {
            throw new Error('Conf Logic Signals are not available');
        }
        const {
            inputVectors,
            outputVectorsLevelA,
            outputVectorsLevelB,
            outputVectors,
            outputFunctions,
            vehicleFunctions,
            sourceSignals,
        } = confLogicSignals;
        const uniqueInputVectorCategories = [...new Set(inputVectors.map((signal) => signal.category))];
        const categorizedInputVectors = {};
        uniqueInputVectorCategories.forEach((c) => {
            categorizedInputVectors[c] = inputVectors.filter((s) => s.category === c);
        });
        const uniqueOutputFunctionCategories = [...new Set(outputFunctions.map((signal) => signal.category))];
        const categorizedOutputFunctions = {};
        uniqueOutputFunctionCategories.forEach((c) => {
            categorizedOutputFunctions[c] = outputFunctions.filter((s) => s.category === c);
        });
        // const resultSignals = { ResultsLevelA: outputVectorsLevelA, ResultsLevelB: outputVectorsLevelB };
        console.info(confLogicSignals);
        yield put(
            getConfLogicSignalsSuccess({
                inputVectors: categorizedInputVectors,
                outputVectorsLevelA,
                outputVectorsLevelB,
                outputVectors,
                outputFunctions: categorizedOutputFunctions,
                vehicleFunctions,
                sourceSignals,
            }),
        );
    } catch (error) {
        // yield put(getConfLogicSignalsFailure());
    }
    console.info('doFetchInputSignalsSaga End');
    console.groupEnd();
}

export function* doFetchOperatorOptionsSaga() {
    console.groupCollapsed('doFetchOperatorOptionsSaga Start');
    const url = `${appDataServiceUrl}/operatoroptions`;

    try {
        const operatorOptions = import.meta.env.PROD ? endpointAPI.operatorOptions : yield call(getHTTP, url);
        if (!operatorOptions) {
            throw new Error('Operator Options are not available');
        }
        console.info(operatorOptions);
        yield put(getConfLogicOperatorOptionsSuccess(operatorOptions));
    } catch (error) {
        // yield put(getCustomerFailure());
    }
    console.info('doFetchOperatorOptionsSaga End');
    console.groupEnd();
}

export function* fetchInputSignalsSaga() {
    yield takeLatest('confLogic/getConfLogicSignalsFetch', doFetchInputSignalsSaga);
}

export function* fetchOperatorOptionsSaga() {
    yield takeLatest('confLogic/getConfLogicOperatorOptionsFetch', doFetchOperatorOptionsSaga);
}
