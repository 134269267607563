import { useAppSelector } from '@/configuration/setup/hooks';
import { getNewConfLogicComponents } from '@/features/conflogic2/confLogicSlice';
import { COMPONENT_LEVEL_OUTPUT } from '@/common/constants/commonConstants';
const useOutputFunctionCheck = (id) => {
    const logicComponents = useAppSelector(getNewConfLogicComponents);
    const outputComponents = logicComponents.filter(l => l.abb === COMPONENT_LEVEL_OUTPUT);
    let used = false;
    outputComponents.forEach((c) => {
        if (c.output.fcn.id && c.output.fcn.id === id){
            used = true;
        }
    });
    return {used};
};

export default useOutputFunctionCheck;
