import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { isUserSessionExpired } from '../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import DefaultRedirect from '../routes/DefaultRedirect';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from '../data/appSlice';
import AppHeader from '../features/header/AppHeader';
import VinNotMatchingDialog from '../components/VinNotMatchingDialog';

import './App.css';
import React, { useRef } from 'react';
import { AppContext } from './AppContext';
import { getIncomingSidebar, getOutgoingSidebar } from '../features/conflogic2/confLogicSlice';
import SidebarContainer from '../components/SidebarContainer';
import { SIGNAL_DIRECTION_IN, SIGNAL_DIRECTION_OUT } from '../common/constants/commonConstants';

const AppLayout = () => {
    const dispatch = useAppDispatch();
    const sidebarRef = useRef();
    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);
    const closedIncoming = useAppSelector(getIncomingSidebar);
    const closedOutgoing = useAppSelector(getOutgoingSidebar);
    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog);
    if (!(displayMessages && userLocale)) {
        return null;
    }
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;
    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <AppContext.Provider value={{ sidebarRef }}>
                <ApplicationLayout data-testid={'app-layout'}>
                    <ApplicationLayout.Header>
                        <AppHeader/>
                    </ApplicationLayout.Header>
                    <ApplicationLayout.Sidebar className='bg-lighter left' data-testid={'sidebar-left'}>
                        <SidebarContainer
                            closed={closedIncoming}
                            direction={SIGNAL_DIRECTION_IN}
                            title={'intl-msg:conflogic.InputVectors'}
                        />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Sidebar className='bg-lighter right' data-testid={'sidebar-right'}>
                        <SidebarContainer
                            closed={closedOutgoing}
                            direction={SIGNAL_DIRECTION_OUT}
                            title={'intl-msg:conflogic.OutputVectors'}
                        />
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body>
                        <VinNotMatchingDialog/>
                        <NotificationsContainer/>
                        <SessionExpiredDialog
                            locale={userLocale}
                            onClose={handleSessionExpiredDialogClose}
                            show={showSessionExpired}
                        />
                        <Outlet/>
                        <DefaultRedirect/>
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            </AppContext.Provider>
        </IntlProvider>
    );
};

export default AppLayout;
