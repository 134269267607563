import {
    ROLE_RIO_TCT_ADMIN,
    ROLE_RIO_TCT_BODYBUILDER,
    ROLE_RIO_TCT_DEVELOPER,
    ROLES_TCT,
} from '@/common/constants/permissions';
import { getHTTP } from '@/common/utils/sagaUtils';
import { config } from '@/config';
import { fetchUserPermissionsSuccess } from '@/configuration/setup/configSlice';
import { endpointUserAPI } from '@/data/endpointAPI';
import getOr from 'lodash/fp/getOr';
import { call, put, takeLatest } from 'redux-saga/effects';

const appDataServiceUrl = config.backend.APP_USER_DATA_SERVICE;

export function* doFetchUserPermissions() {
    console.groupCollapsed('doFetchUserPermissions Start');
    const url = `${appDataServiceUrl}/userpermissions`;
    try {
        const permissions = import.meta.env.PROD ? endpointUserAPI.permissions : yield call(getHTTP, url);
        const roles = getOr([], ROLES_TCT, permissions);
        const hasSufficientRoles =
            roles.includes(ROLE_RIO_TCT_ADMIN) ||
            roles.includes(ROLE_RIO_TCT_DEVELOPER) ||
            roles.includes(ROLE_RIO_TCT_BODYBUILDER);
        const newPermissions = { ...permissions };
        newPermissions['permitted'] = !!hasSufficientRoles;
        if (permissions) {
            yield put(fetchUserPermissionsSuccess(newPermissions));
        } else {
            throw new Error('No Permissions available');
        }
    } catch (error) {
        // yield put(getCustomerFailure());
    }
    console.info('doFetchUserPermissions End');
    console.groupEnd();
}

export function* fetchUserPermissionsSaga() {
    yield takeLatest('config/fetchUserPermissions', doFetchUserPermissions);
}
