import {
    ADMIN_MULTIPLENUMBERTILE,
    ADMIN_NUMBERTILE,
    ADMIN_QUARTEREDTILE,
    ADMIN_TABLETILE,
} from '@/common/constants/commonConstants';
import { getID } from '@/common/utils/commonUtils';
import MultipleNumbersTile from '@/features/admin/MultipleNumbersTile';
import NumberTile from '@/features/admin/NumberTile';
import QuarteredTile from '@/features/admin/QuarteredTile';
import TableTile from '@/features/admin/TableTile';
import { tileDescriptors } from '@/pages/AdminContainer';
import { includes } from 'lodash';
import getOr from 'lodash/fp/getOr';
import React from 'react';

export const Admin = ({ data }) => {
    const renderTiles = () => {
        return tileDescriptors.map((tileDescriptor) => renderTile(tileDescriptor));
    };
    const renderTile = (tileDescriptor) => {
        if (!tileDescriptor.permission || includes(tileDescriptor.permission, this.props.permissions)) {
            if (tileDescriptor.type === ADMIN_NUMBERTILE) {
                const value = getOr(0, tileDescriptor.dataPath, data);
                return (
                    <NumberTile
                        key={getID()}
                        label={tileDescriptor.label}
                        value={value}
                        tileDescriptor={tileDescriptor}
                    />
                );
            } else if (tileDescriptor.type === ADMIN_MULTIPLENUMBERTILE) {
                return <MultipleNumbersTile sourceData={data} key={getID()} tileDescriptor={tileDescriptor}/>;
            } else if (tileDescriptor.type === ADMIN_QUARTEREDTILE) {
                return <QuarteredTile values={tileDescriptor.values} key={getID()} sourceData={data}/>;
            } else if (tileDescriptor.type === ADMIN_TABLETILE) {
                return <TableTile key={getID()} sourceData={data} tileDescriptor={tileDescriptor}/>;
            }
        }
    };

    const tiles = renderTiles();
    return (
        <div className='overview row position-relative height-100pct'>
            <div className='home'>
                <div className='home-content home-tiles row equal-height'>{tiles}</div>
            </div>
        </div>
    );
};
