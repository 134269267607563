import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setSelectedVehicle: (state, action) => {
            return action.payload;
        },
    },
});

// const {actions, reducer} = vehicleSlice;
// export const {setSelectedVehicleVIN} = actions;
// export const getSelectedVehicleVIN = (state) => state.vehicle.vin;
export const getSelectedVehicle = (state) => state.entities.vehicle;
export const { setSelectedVehicle } = vehicleSlice.actions;
export default vehicleSlice.reducer;
