import React from 'react';

const ExpanderPanelHeader = ({expanded, title}) => {
    const panelClassNames = (category) => {
        const folder = category ? 'rioglyph-folder-open' : 'rioglyph-folder-closed';
        return `margin-right-10 rioglyph ${folder}`;
    };
    return (
        <div>
            <span className={panelClassNames(expanded)}/>
            <span className='text-size-14'>{title.toUpperCase()}</span>
        </div>
    );
};

export default ExpanderPanelHeader;
