import Tile from '@/features/admin/Tile';
import React from 'react';
import { FormattedNumber } from 'react-intl';

const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';

const NumberTile = ({ label, value }) => {
    return (
        <Tile>
            <div className='number-tile display-flex flex-column col-md-6 bg-color-coldplay-kashmir'>
                <div data-name={name} className='cursor-pointer bg-white bg-tile height-500'>
                    <div className='display-flex justify-content-center align-items-center height-100pct'>
                        <span className='text-size-18 text-bold text-color-darkest'>
                            <span className='text-size-200pct'>
                                <FormattedNumber value={value}/>
                            </span>
                        </span>
                    </div>
                    <div className='position-relative'>
                        <div className={labelWrapperClasses}>
                            <span className={labelClasses}>{label}</span>
                        </div>
                    </div>
                </div>
            </div>
        </Tile>
    );
};

export default NumberTile;
