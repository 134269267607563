import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { useDebugValue } from 'react';
import useOutputFunctionCheck from '@/features/conflogic2/hooks/useOutputFunctionCheck';

const InputSignal = ({ signal, dragStart, dragEnd, descriptor, output }) => {
    const onDragStart = (e) => {
        dragStart(e);
    };
    const onDragEnd = (e) => {
        dragEnd(e);
    };
    let vehicleFunction = {};
    if(signal.category === 'Vehicle functions') {
        vehicleFunction = useOutputFunctionCheck(signal.id);
    }
    const Title = () => {
        const desc = signal[descriptor[1]];
        return (
            <>
                <div className='rioglyph rioglyph-flash pull-left text-color-highlight display-flex align-items-center'/>
                <div className='margin-left-10 pull-right'>
                    <span className='text-size-12 display-block'>{desc}</span>
                </div>
            </>
        );
    };
    const DraggableVector = () => {
        return <div
            data-testid={signal['name']}
            className={`draggable display-flex align-items-center cursor-move ${output}`}
            data-input={JSON.stringify(signal)}
            onDragStart={(e) => onDragStart(e)}
            onDragEnd={(e) => onDragEnd(e)}
            draggable={signalName}
        >
            <Title/>
        </div>;
    };
    const signalName = signal['name'] !== '';
    const component = vehicleFunction.used ?
        <div
            data-testid={signal['name']}
            className={`display-flex text-color-light align-items-center cursor-not-allowed user-select-none ${output}`}
            data-input={JSON.stringify(signal)}
        >
            <Title/>
        </div>:
        <DraggableVector/>;
    return  (component);
};

export default InputSignal;

InputSignal.defaultProps = {
    signal: noop,
    dragStart: noop,
    dragEnd: noop,
    descriptor: {},
    output: '',
};

InputSignal.propTypes = {
    signal: PropTypes.object,
    dragStart: PropTypes.func,
    dragEnd: PropTypes.func,
    descriptor: PropTypes.object,
    output: PropTypes.string,
};
