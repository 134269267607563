import adminSlice from '@/features/admin/adminSlice';
import confLogicSlice from '@/features/conflogic2/confLogicSlice';
import engineSlice from '@/features/engine/engineSlice';
import customerSlice from '@/features/home/customerSlice';
import vehicleSlice from '@/features/home/vehicleSlice';
import { combineReducers } from '@reduxjs/toolkit';

const entityReducers = combineReducers({
    customer: customerSlice,
    vehicle: vehicleSlice,
    engine: engineSlice,
    confLogic: confLogicSlice,
    admin: adminSlice,
});

export default entityReducers;
