import { getID } from '@/common/utils/commonUtils';
import Tile from '@/features/admin/Tile';
import React from 'react';

const TableTile = ({ sourceData, tileDescriptor }) => {
    const labelWrapperClasses = 'position-absolute bottom-25 padding-left-20 padding-right-20 bg-darkest';
    const labelClasses = 'text-size-20 text-bold text-uppercase text-color-white';
    const value = tileDescriptor.values[0];
    const displayData = sourceData[value.dataPath];
    const sortedData = [...displayData].sort((a, b) => (a[value.sort] < b[value.sort] ? -1 : 1));
    const tableClassNames =
        'table table-layout-fixed table-column-overflow-hidden table-bordered table-sticky table-head-filled table-hover';
    const tableHeadClassNames = 'user-select-none';

    return (
        <Tile>
            <div data-name={name} className='table-tile cursor-pointer bg-white bg-tile height-500 '>
                <div className='table-wrapper position-relative max-height-400 overflow-y-scroll'>
                    <table className={`${tableClassNames} height-400`}>
                        <thead>
                            <tr>
                                {value.keys.map((key) => (
                                    <th key={getID()} className={tableHeadClassNames}>
                                        <span>
                                            <span>{key.toUpperCase()}</span>
                                        </span>
                                    </th>
                                ))}
                                {/* <th*/}
                                {/*    className={tableHeadClassNames}*/}
                                {/*/ / title={value.keys[0]}*/}
                                {/* >*/}
                                {/*    <span>*/}
                                {/*        <span>{value.keys[0].toUpperCase()}</span>*/}
                                {/*    </span>*/}
                                {/* </th>*/}
                                {/* <th*/}
                                {/*    className={tableHeadClassNames}*/}
                                {/*/ / title={value.keys[0]}*/}
                                {/* >*/}
                                {/*    <span>*/}
                                {/*        <span>{value.keys[1].toUpperCase()}</span>*/}
                                {/*    </span>*/}
                                {/* </th>*/}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((row) => (
                                <tr key={getID()}>
                                    {Object.keys(row).map((key) => (
                                        <td key={getID()} data-field={row[key]}>
                                            <span>{row[key]}</span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='position-relative'>
                <div className={labelWrapperClasses}>
                    <div>
                        <span className={labelClasses}>{tileDescriptor.label}</span>
                    </div>
                </div>
            </div>
        </Tile>
    );
};

export default TableTile;
