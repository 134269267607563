import { fetchUserPermissions, getPermissions } from '@/configuration/setup/configSlice';
import { useAppDispatch, useAppSelector } from '@/configuration/setup/hooks';
import AppLayout from '@/layout/AppLayout';
import { allRoutes } from '@/routes/Router';
import ForbiddenState from '@rio-cloud/rio-uikit/ForbiddenState';
import { intersection } from 'lodash';
import isEmpty from 'lodash/fp/isEmpty';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

export const PermissionsContainer = ({}) => {
    const [permissionsFetched, setPermissionsFetched] = useState(false);
    const [userPermitted, setUserPermitted] = useState(false);
    const dispatcher = useAppDispatch();
    const permissions = useAppSelector(getPermissions);

    useEffect(() => {
        dispatcher(fetchUserPermissions());
    }, []);
    useEffect(() => {
        if (Object.keys(permissions).length > 0) {
            setPermissionsFetched(true);
            setUserPermitted(permissions.permitted);
        } else {
            setPermissionsFetched(false);
        }
    }, [permissions]);
    const createRoutes = () => {
        const validRoutes = allRoutes
            .map((route, i) => {
                const permitted = intersection(permissions.tctRoles, route.permissions);
                if (!isEmpty(permitted)) {
                    return (
                        <Route key={i} path={route.path} errorElement={route.errorElement} element={route.element}/>
                    );
                }
            })
            .filter((route) => route !== undefined);

        return (
            <Route path='/' element={<AppLayout/>}>
                {validRoutes.map((validRoute) => validRoute)}
            </Route>
        );
    };
    const renderContent = () => {
        const router = createBrowserRouter(createRoutesFromElements(createRoutes()));
        if (userPermitted) {
            return <RouterProvider router={router}/>;
        } else if (permissionsFetched && !userPermitted) {
            return (
                <ForbiddenState
                    headline={<FormattedMessage id='intl-msg:common.noAccess'/>}
                    message={<FormattedMessage id='intl-msg:common.noAccessMessage'/>}
                    outerClassName={'margin-top-50'}
                />
            );
        } // else{
        //     return (
        //         <ForbiddenState
        //             headline={'Checking permissions'}
        //             outerClassName={'margin-top-50'}
        //         >
        //             <Spinner/>
        //         </ForbiddenState>
        //     );
        // }
    };
    const content = !isEmpty(permissions) && renderContent();
    return <div className='device-management-permissions-module'>{content}</div>;
};
