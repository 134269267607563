import {lazy} from 'react';
import {
    DEFAULT_ROUTE,
    ROUTE_ADMIN,
    ROUTE_CONFLOGIC,
    ROUTE_OVERVIEW,
    ROUTE_PARAMETRIZATION,
    ROUTE_UPLOAD,
} from '../common/constants/commonConstants';
import {ErrorBoundary} from '../components/ErrorBoundary';
import SuspendedWithSpinner from '../components/SuspendedWithSpinner';
import {viewComponentPermissions} from '../common/constants/permissions';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import AdminContainer from '../pages/AdminContainer';
import {ftAdmin} from '../configuration/featureToggles';

// Lazy load pages for better performance and automatically split the bundle accordingly
const HomeContainer = lazy(() => import('../pages/HomeContainer'));
const EngineContainer = lazy(() => import('../pages/EngineContainer'));
const ConfLogicContainer2 = lazy(() => import('../pages/ConfLogicContainer2'));
const OverviewContainer = lazy(() => import('../pages/OverviewContainer'));
const UploadContainer = lazy(() => import('../pages/UploadContainer'));

export const routes = [
    DEFAULT_ROUTE,
    ROUTE_ADMIN,
    ROUTE_PARAMETRIZATION,
    ROUTE_CONFLOGIC,
    ROUTE_OVERVIEW,
    ROUTE_UPLOAD,
];

export const allRoutes = [
    {
        path: DEFAULT_ROUTE,
        errorElement: <ErrorBoundary/>,
        element: (
            <SuspendedWithSpinner>
                <HomeContainer/>
            </SuspendedWithSpinner>
        ),
        permissions: viewComponentPermissions.home,
    },
    {
        path: ROUTE_ADMIN,
        errorElement: <ErrorBoundary/>,
        element: (
            <SuspendedWithSpinner>
                <AdminContainer/>
            </SuspendedWithSpinner>
        ),
        permissions: viewComponentPermissions.admin,
    },
    {
        path: ROUTE_PARAMETRIZATION,
        errorElement: <ErrorBoundary/>,
        element: (
            <SuspendedWithSpinner>
                <EngineContainer/>
            </SuspendedWithSpinner>
        ),
        permissions: viewComponentPermissions.parametrization,
    },
    {
        path: ROUTE_CONFLOGIC,
        errorElement: <ErrorBoundary/>,
        element: (
            <SuspendedWithSpinner>
                <ConfLogicContainer2/>
            </SuspendedWithSpinner>
        ),
        permissions: viewComponentPermissions.confLogic,
    },
    {
        path: ROUTE_OVERVIEW,
        errorElement: <ErrorBoundary/>,
        element: (
            <SuspendedWithSpinner>
                <OverviewContainer/>
            </SuspendedWithSpinner>
        ),
        permissions: viewComponentPermissions.overview,
    },
    {
        path: ROUTE_UPLOAD,
        errorElement: <ErrorBoundary/>,
        element: (
            <SuspendedWithSpinner>
                <UploadContainer/>
            </SuspendedWithSpinner>
        ),
        permissions: viewComponentPermissions.upload,
    },
];

export const getAllNavItems = () => {
    const allNavItems = [
        {
            key: DEFAULT_ROUTE,
            route: (
                <NavLink to={'/home'}>
                    <FormattedMessage id={'intl-msg:mainmenu.home'}/>
                </NavLink>
            ),
            permissions: viewComponentPermissions.home,
        },
        {
            key: ROUTE_PARAMETRIZATION,
            route: (
                <NavLink to={'/parametrization'}>
                    <FormattedMessage id={'intl-msg:mainmenu.parametrization'}/>
                </NavLink>
            ),
            permissions: viewComponentPermissions.parametrization,
        },
        {
            key: ROUTE_CONFLOGIC,
            route: (
                <NavLink to={'/conflogic'}>
                    <FormattedMessage id={'intl-msg:mainmenu.conflogic'}/>
                </NavLink>
            ),
            permissions: viewComponentPermissions.confLogic,
        },
        {
            key: ROUTE_OVERVIEW,
            route: (
                <NavLink to={'/overview'}>
                    <FormattedMessage id={'intl-msg:mainmenu.overview'}/>
                </NavLink>
            ),
            permissions: viewComponentPermissions.overview,
        },
        {
            key: ROUTE_UPLOAD,
            route: (
                <NavLink to={'/upload'}>
                    <FormattedMessage id={'intl-msg:mainmenu.upload'}/>
                </NavLink>
            ),
            permissions: viewComponentPermissions.upload,
        },
    ];
    const adminRoute = {
        key: ROUTE_ADMIN,
        route: (
            <NavLink to={'/admin'}>
                <FormattedMessage id={'intl-msg:mainmenu.admin'}/>
            </NavLink>
        ),
        permissions: viewComponentPermissions.admin,
    };
    if (ftAdmin) {
        allNavItems.push(adminRoute);
    }
    return allNavItems;
};
