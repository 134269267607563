import { createSlice } from '@reduxjs/toolkit';
import { config, ConfigState } from '../../config';

import type { RootState } from './store';

const initialState: ConfigState = config;

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        fetchUserPermissions: () => {},
        fetchUserPermissionsSuccess: (state, action) => {
            console.info('fetchUserPermissionsSuccess');

            state.login.permissions = { ...action.payload };
        },
    },
});

export const { fetchUserPermissions, fetchUserPermissionsSuccess } = configSlice.actions;

export const getPermissions = (state: RootState) => state.config.login.permissions;
export default configSlice.reducer;
