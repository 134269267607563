export const getFromLocalStorage = (name) => {
    try {
        return window.localStorage.getItem(name);
    } catch (_) {
        // Intentionally left blank
    }
    return '';
};

export const saveToLocalStorage = (name, value) => {
    try {
        window.localStorage.setItem(name, value);
    } catch (_) {
        // Intentionally left blank
    }
    return value;
};
