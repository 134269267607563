import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const ConfigurationLink = ({ link, label, icon, background, show, startProcess, children }) => {
    const iconClasses = `rioglyph ${icon}`;
    const classes = `btn ${background} width-200 text-bold`;
    const linkClasses = `text-decoration-none text-color-lightest ${!show ? 'opacity-40' : ''}`;
    return (
        <div className={`btn-toolbar max-width-200 margin-right-210 ${!show ? 'cursor-not-allowed' : ''}`}>
            <NavLink
                to={link}
                onClick={startProcess}
                style={{ pointerEvents: !show ? 'none' : '' }}
                className={linkClasses}
                relative='path'
            >
                <div className={classes}>
                    <span className={iconClasses || null} aria-hidden='true'/>
                    {children}
                    {/* <FormattedMessage id='intl-msg:home.registeredvehicles' defaultMessage='MISSING'/>*/}
                    {/* {label}*/}
                </div>
            </NavLink>
        </div>
    );
};

export default ConfigurationLink;

ConfigurationLink.defaultProps = {
    link: '',
    label: '',
    icon: '',
    background: '',
    show: true,
    startProcess: noop,
    children: noop,
};

ConfigurationLink.propTypes = {
    link: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    background: PropTypes.string,
    show: PropTypes.bool,
    startProcess: PropTypes.func,
    children: PropTypes.node,
};
