import { createSlice } from '@reduxjs/toolkit';
import last from 'lodash/fp/last';

const initialState = {
    signals: {},
    ksComponents: [],
};

export const engineSlice = createSlice({
    name: 'engine',
    initialState,
    // reducers: {
    //     // ❌ ERROR: mutates state, but also returns new array size!
    //     brokenReducer: (state, action) => state.push(action.payload),
    //     // ✅ SAFE: the `void` keyword prevents a return value
    //     fixedReducer1: (state, action) => void state.push(action.payload),
    //     // ✅ SAFE: curly braces make this a function body and no return
    //     fixedReducer2: (state, action) => {
    //         state.push(action.payload)
    //     },
    reducers: {
        getEngineSignalsFetch: () => {},
        getEngineSignalsSuccess: (state = initialState, action = {}) => {
            state.signals = action.payload;
        },
        getEngineSignalParamsFetch: () => {},
        addKSComponents: (state, action) => {
            const newComponent = action.payload;
            /*
            {
                "vin": "WMA10KZZXNM902353",
                "ks_code": "Z1ABRA",
                "name": "ZDR 1 - Abschaltbedingung Bremse",
                "current_value": 234,
                "last_values": [
                    234
                ],
                "last_changed": "19.6.2023, 16:05:03",
                "can_change": false,
                "default_value": 200,
                "allowed_values": [],
                "min_value": -1000,
                "max_value": 10000
            }
            */
            const ids = state.ksComponents.map((ks) => ks.id);
            newComponent.id = ids.length > 0 ? Math.max(...ids) + 1 : 1;
            state.ksComponents.push(newComponent);
        },
        removeKSComponent: (state, action) => {
            const id = action.payload.id;
            const ksComponents = [...state.ksComponents];
            state.ksComponents = ksComponents.filter((ks) => ks.id !== id);
        },
        changeKSComponentValue: (state, action) => {
            const { currentId, currentValue } = action.payload;
            const ksComponents = state.ksComponents;
            /*
                vin(pin):"WMA10KZZXNM902353"
                ks_code(pin):"Z1ABRA"
                name(pin):"ZDR 1 - Abschaltbedingung Bremse"
                current_value(pin):"234"
                last_changed(pin):"6.2.2023, 14:56:47"
                can_change(pin):true
                default_value(pin):"200"
                allowed_values(pin):
                min_value(pin):"100"
                max_value(pin):"500"
                id(pin):0
             */
            const oldComponent = ksComponents.find((ks) => ks.id === currentId);
            const newComponent = { ...oldComponent };
            if (last(newComponent.last_values) !== newComponent.current_value) {
                newComponent.last_values.push(newComponent.current_value);
            }
            {
                newComponent['current_value'] = +currentValue;
            }
            const filteredKSComponents = ksComponents.filter((ks) => ks.id !== currentId);
            filteredKSComponents.push(newComponent);
            state.ksComponents = filteredKSComponents;
        },
    },
});

export const getEngineSignals = (state) => state.entities.engine.signals;
export const getKSComponents = (state) => state.entities.engine.ksComponents;
export const {
    getEngineSignalsFetch,
    getEngineSignalsSuccess,
    getEngineSignalParamsFetch,
    addKSComponents,
    removeKSComponent,
    changeKSComponentValue,
} = engineSlice.actions;
export default engineSlice.reducer;
