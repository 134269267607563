import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {DefaultUserMenu} from '@rio-cloud/rio-user-menu-component';
import {RioNotifications} from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import {config} from '../../config';
import ServiceInfo from './ServiceInfo';
import {useAppSelector} from '../../configuration/setup/hooks';
import {getPermissions} from '../../configuration/setup/configSlice';
import {getAllNavItems} from '../../routes/Router';
import {intersection} from 'lodash';
import isEmpty from 'lodash/fp/isEmpty';
import React from 'react';
import AccountMenu from './AccountMenu';

interface rolesPermissionsType {
  tctRoles: [];
  otherRoles: [];
  permissions: [];
}

const AppHeader = () => {
    const permissions = useAppSelector(getPermissions) as rolesPermissionsType;
    const createNavItems = () => {
        return getAllNavItems()
            .map((navItem, i) => {
                const permitted = intersection(permissions.tctRoles, navItem.permissions);
                if (!isEmpty(permitted)) {
                    return navItem;
                }
            })
            .filter((item) => item !== undefined);
    };
    const permittedNavItems = !isEmpty(permissions) && createNavItems();
    const environment = import.meta.env.PROD ? 'production' : 'local';
    const serviceInfoItem = <ServiceInfo/>;
    const notifications = <RioNotifications/>;
    const userMenuItem = <DefaultUserMenu environment={environment}/>;
    const accountMenu = <AccountMenu/>;

    return (
        <ApplicationHeader
            label={<FormattedMessage id='intl-msg:appName'/>}
            // appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
            homeRoute={<Link to={config.homeRoute || ''}/>}
            navItems={permittedNavItems}
            // actionBarItems={[serviceInfoItem, notifications, userMenuItem]}
            actionBarItems={[]}
        />
    );
};

export default AppHeader;
