import { SIGNAL_DIRECTION_IN, SIGNAL_DIRECTION_OUT } from '@/common/constants/commonConstants';
import ExpanderPanelContainer from '@/components/ExpanderPanelContainer';
import { useAppDispatch } from '@/configuration/setup/hooks';
import { setDraggingType } from '@/features/conflogic2/confLogicSlice';
import { confLogicInputDescriptor } from '@/features/conflogic2/InputSignalPanelConfLogicDescriptor';
import noop from 'lodash/fp/noop';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SignalListContainer = ({ vectors, direction }) => {
    const dispatch = useAppDispatch();
    const [expandAllClicked, setExpandAllClicked] = useState(null);
    const [collapseAllClicked, setCollapseAllClicked] = useState(null);
    const onDragStart = (event) => {
        event.target.classList.add('dragging');
        const draggingType = event.target.classList.contains(SIGNAL_DIRECTION_OUT)
            ? SIGNAL_DIRECTION_OUT
            : SIGNAL_DIRECTION_IN;
        dispatch(setDraggingType({ draggingType }));
        event.dataTransfer.effectAllowed = 'copy';
        event.dataTransfer.dropEffect = 'copy';
        const node = event.target.querySelector('.rioglyph.rioglyph-flash').cloneNode(true);
        node.classList.add('dragIcon');
        node.style.cssText = 'position:absolute;top:100px;left:-1000px;font-size:30px;';
        document.body.appendChild(node);
        event.dataTransfer.setDragImage(node, 20, 20);
        event.dataTransfer.setData('text/plain', JSON.stringify({ input: event.target.dataset.input }));
        const dropZones = document.querySelectorAll('.drop-zone:not(.done)');
        dropZones.forEach((dz) => {
            dz.classList.add('possible-target');
        });
    };
    const onDragEnd = (event) => {
        event.target.classList.remove('dragging');
        const dropZones = document.querySelectorAll('.drop-zone');
        dropZones.forEach((dz) => {
            dz.classList.remove('possible-target');
        });
        const node = document.querySelector('.dragIcon');
        if (node) {
            node.remove();
        }
    };
    return (
        <div>
            <div className='col-md-3'>
                <div className='panel panel-primary'>
                    <div className='vectors overflow-scroll'>
                        {Object.keys(vectors).map((key) => {
                            return (
                                <ExpanderPanelContainer
                                    key={key}
                                    title={key}
                                    vectors={vectors[key]}
                                    expandAllClicked={expandAllClicked}
                                    collapseAllClicked={collapseAllClicked}
                                    onDragStart={onDragStart}
                                    onDragEnd={onDragEnd}
                                    descriptor={confLogicInputDescriptor}
                                    output={
                                        direction === SIGNAL_DIRECTION_OUT ? SIGNAL_DIRECTION_OUT : SIGNAL_DIRECTION_IN
                                    }
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
SignalListContainer.defaultProps = {
    vectors: noop,
    direction: '',
};
SignalListContainer.propTypes = {
    vectors: PropTypes.object,
    direction: PropTypes.string,
};

export default SignalListContainer;
