import { ftRole } from '@/configuration/featureToggles';
import isEmpty from 'lodash/fp/isEmpty';

// const roles = getFromLocalStorage('ft_role');
const roles = isEmpty(ftRole) ? ['rio.tct.admin', 'rio.tct.developer'] : ftRole.split(',').map((p) => p.trim());

export const endpointUserAPI = {
    permissions: {
        tctRoles: roles,
        otherRoles: [],
        permissions: ['app.upload'],
    },
};

export const endpointAPI = {
    conflogicSignals: {
        inputVectors: [
            {
                'id': 0,
                'fSignal': 'f_boBuConfIn01',
                'description': 'configurable input Pin 1',
                'additional_parameter': '',
                'additional_information': 'resistance coded',
                'category': 'Input/Output Pin'
            },
            {
                'id': 1,
                'fSignal': 'f_boBuConfIn02',
                'description': 'configurable input Pin 2',
                'additional_parameter': '',
                'additional_information': 'resistance coded',
                'category': 'Input/Output Pin'
            },
            {
                'id': 2,
                'fSignal': 'f_boBuConfIn03',
                'description': 'configurable input Pin 3',
                'additional_parameter': '',
                'additional_information': 'resistance coded',
                'category': 'Input/Output Pin'
            },
            {
                'id': 3,
                'fSignal': 'f_boBuConfIn04',
                'description': 'configurable input Pin 4',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 4,
                'fSignal': 'f_boBuConfIn05',
                'description': 'configurable input Pin 5',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 5,
                'fSignal': 'f_boBuConfIn06',
                'description': 'configurable input Pin 6',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 6,
                'fSignal': 'f_boBuConfIn07',
                'description': 'configurable input Pin 7',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 7,
                'fSignal': 'f_boBuConfIn08',
                'description': 'configurable input Pin 8',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 8,
                'fSignal': 'f_boBuConfIn09',
                'description': 'configurable input Pin 9',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 9,
                'fSignal': 'f_boBuConfIn10',
                'description': 'configurable input Pin 10',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 10,
                'fSignal': 'f_boBuConfIn11',
                'description': 'configurable input Pin 11',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 11,
                'fSignal': 'f_boBuConfIn12',
                'description': 'configurable input Pin 12',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 12,
                'fSignal': 'f_boBuConfIn13',
                'description': 'configurable input Pin 13',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 13,
                'fSignal': 'f_boBuConfIn14',
                'description': 'configurable input Pin 14',
                'additional_parameter': '',
                'additional_information': 'resistance coded',
                'category': 'Input/Output Pin'
            },
            {
                'id': 14,
                'fSignal': 'f_boBuConfIn15',
                'description': 'configurable input Pin 15',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 15,
                'fSignal': 'f_boBuConfIn16',
                'description': 'configurable input Pin 16',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 16,
                'fSignal': 'f_boBuConfIn17',
                'description': 'configurable input Pin 17',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 17,
                'fSignal': 'f_boBuConfIn18',
                'description': 'configurable input Pin 18',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 18,
                'fSignal': 'f_boBuConfIn19',
                'description': 'configurable input Pin 19',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 19,
                'fSignal': 'f_boBuConfIn20',
                'description': 'configurable input Pin 20',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 20,
                'fSignal': 'f_boBuConfIn01st',
                'description': 'state of configurable input 1',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 21,
                'fSignal': 'f_boBuConfIn02st',
                'description': 'state of configurable input 2',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 22,
                'fSignal': 'f_boBuConfIn03st',
                'description': 'state of configurable input 3',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 23,
                'fSignal': 'f_boBuConfIn14st',
                'description': 'state of configurable input 14',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Input/Output Pin'
            },
            {
                'id': 24,
                'fSignal': 'f_CLboBuConfOut1_outpSt',
                'description': 'diagnosis response of configurable output pin 1',
                'additional_parameter': 'eiocdp_p_selectDiagPwmOut01',
                'additional_information': 'no test pulses, parameter to select diagnosis on pin',
                'category': 'Input/Output Pin'
            },
            {
                'id': 25,
                'fSignal': 'f_CLboBuConfOut2_outpSt',
                'description': 'diagnosis response of configurable output pin 2',
                'additional_parameter': '',
                'additional_information': 'test pulses',
                'category': 'Input/Output Pin'
            },
            {
                'id': 26,
                'fSignal': 'f_CLboBuConfOut3_outpSt',
                'description': 'diagnosis response of configurable output pin 3',
                'additional_parameter': '',
                'additional_information': 'test pulses',
                'category': 'Input/Output Pin'
            },
            {
                'id': 27,
                'fSignal': 'f_CLboBuConfOut4_outpSt',
                'description': 'diagnosis response of configurable output pin 4',
                'additional_parameter': 'eiocdp_p_selectDiagPwmOut05',
                'additional_information': 'no test pulses, parameter to select diagnosis on pin',
                'category': 'Input/Output Pin'
            },
            {
                'id': 28,
                'fSignal': 'f_CLboBuConfOut5_outpSt',
                'description': 'diagnosis response of configurable output pin 5',
                'additional_parameter': 'eiocdp_p_selectDiagPwmOut06',
                'additional_information': 'no test pulses, parameter to select diagnosis on pin',
                'category': 'Input/Output Pin'
            },
            {
                'id': 29,
                'fSignal': 'f_CLboBuConfOut6_outpSt',
                'description': 'diagnosis response of configurable output pin 6',
                'additional_parameter': 'eiocdp_p_selectDiagPwmOut03',
                'additional_information': 'no test pulses, parameter to select diagnosis on pin',
                'category': 'Input/Output Pin'
            },
            {
                'id': 30,
                'fSignal': 'f_CLboBuConfOut7_outpSt',
                'description': 'diagnosis response of configurable output pin 7',
                'additional_parameter': 'eiocdp_p_selectDiagPwmOut04',
                'additional_information': 'no test pulses, parameter to select diagnosis on pin',
                'category': 'Input/Output Pin'
            },
            {
                'id': 31,
                'fSignal': 'f_CLboBuConfOut8_outpSt',
                'description': 'diagnosis response of configurable output pin 8',
                'additional_parameter': 'eiocdp_p_selectDiagPwmOut02',
                'additional_information': 'no test pulses, parameter to select diagnosis on pin',
                'category': 'Input/Output Pin'
            },
            {
                'id': 32,
                'fSignal': 'f_CLboBuConfOut9_outpSt',
                'description': 'diagnosis response of configurable output pin 9',
                'additional_parameter': '',
                'additional_information': 'test pulses',
                'category': 'Input/Output Pin'
            },
            {
                'id': 33,
                'fSignal': 'f_CLboBuConfOut10_outpSt',
                'description': 'diagnosis response of configurable output pin 10',
                'additional_parameter': '',
                'additional_information': 'test pulses',
                'category': 'Input/Output Pin'
            },
            {
                'id': 34,
                'fSignal': 'f_CLboBuConfOut11_outpSt',
                'description': 'diagnosis response of configurable output pin 11',
                'additional_parameter': '',
                'additional_information': 'test pulses',
                'category': 'Input/Output Pin'
            },
            {
                'id': 35,
                'fSignal': 'f_CLboBuConfOut12_outpSt',
                'description': 'diagnosis response of configurable output pin 12',
                'additional_parameter': '',
                'additional_information': 'test pulses',
                'category': 'Input/Output Pin'
            },
            {
                'id': 50,
                'fSignal': 'f_genericBobuIn101',
                'description': 'generic CAN input 101',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 51,
                'fSignal': 'f_genericBobuIn102',
                'description': 'generic CAN input 102',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 52,
                'fSignal': 'f_genericBobuIn103',
                'description': 'generic CAN input 103',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 53,
                'fSignal': 'f_genericBobuIn104',
                'description': 'generic CAN input 104',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 54,
                'fSignal': 'f_genericBobuIn105',
                'description': 'generic CAN input 105',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 55,
                'fSignal': 'f_genericBobuIn106',
                'description': 'generic CAN input 106',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 56,
                'fSignal': 'f_genericBobuIn201',
                'description': 'generic CAN input 201 - eval/above threshold',
                'additional_parameter': 'BBCL_p_upperThresCAN1',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 57,
                'fSignal': 'f_genericBobuIn201',
                'description': 'generic CAN input 201 - below threshold',
                'additional_parameter': 'BBCL_p_lowerThresCAN1',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 58,
                'fSignal': 'f_genericBobuIn202',
                'description': 'generic CAN input 202 - eval/above threshold',
                'additional_parameter': 'BBCL_p_upperThresCAN2',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 59,
                'fSignal': 'f_genericBobuIn202',
                'description': 'generic CAN input 202 - below threshold',
                'additional_parameter': 'BBCL_p_lowerThresCAN2',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 60,
                'fSignal': 'f_genericBobuIn203',
                'description': 'generic CAN input 203 - above threshold',
                'additional_parameter': 'BBCL_p_upperThresCAN3',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 61,
                'fSignal': 'f_genericBobuIn203',
                'description': 'generic CAN input 203 - eval/below threshold',
                'additional_parameter': 'BBCL_p_lowerThresCAN3',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 62,
                'fSignal': 'f_genericBobuIn301',
                'description': 'generic CAN input 301 - above threshold',
                'additional_parameter': 'BBCL_p_upperThresCAN4',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 63,
                'fSignal': 'f_genericBobuIn301',
                'description': 'generic CAN input 301 - eval/below threshold',
                'additional_parameter': 'BBCL_p_lowerThresCAN4',
                'additional_information': '',
                'category': 'CAN Input'
            },
            {
                'id': 80,
                'fSignal': 'f_pbDoorBobuReq1',
                'description': 'pushbuttons door 1',
                'additional_parameter': 'BBCL_p_pbSwitchDoor1',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 81,
                'fSignal': 'f_pbDoorBobuReq2',
                'description': 'pushbuttons door 2',
                'additional_parameter': 'BBCL_p_pbSwitchDoor2',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 82,
                'fSignal': 'f_pbDoorBobuReq3',
                'description': 'pushbuttons door 3',
                'additional_parameter': 'BBCL_p_pbSwitchDoor3',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 83,
                'fSignal': 'f_pbDoorBobuReq4',
                'description': 'pushbuttons door 4',
                'additional_parameter': 'BBCL_p_pbSwitchDoor4',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 84,
                'fSignal': 'f_pbBobuReq1',
                'description': 'pushbuttons 6er pushbutton field, 1',
                'additional_parameter': 'BBCL_p_pbSwitch1',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 85,
                'fSignal': 'f_pbBobuReq2',
                'description': 'pushbuttons 6er pushbutton field, 2',
                'additional_parameter': 'BBCL_p_pbSwitch2',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 86,
                'fSignal': 'f_pbBobuReq3',
                'description': 'pushbuttons 6er pushbutton field, 3',
                'additional_parameter': 'BBCL_p_pbSwitch3',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 87,
                'fSignal': 'f_pbBobuReq4',
                'description': 'pushbuttons 6er pushbutton field, 4',
                'additional_parameter': 'BBCL_p_pbSwitch4',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 88,
                'fSignal': 'f_pbBobuReq5',
                'description': 'pushbuttons 6er pushbutton field, 5',
                'additional_parameter': 'BBCL_p_pbSwitch5',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 89,
                'fSignal': 'f_pbBobuReq6',
                'description': 'pushbuttons 6er pushbutton field, 6',
                'additional_parameter': 'BBCL_p_pbSwitch6',
                'additional_information': 'Parameter for change between pushbutton and switch logic',
                'category': 'Push buttons'
            },
            {
                'id': 90,
                'fSignal': 'f_CCoffReq',
                'description': 'steering wheel off request',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Push buttons'
            },
            {
                'id': 91,
                'fSignal': 'f_CCsetReq',
                'description': 'steering wheel set request',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Push buttons'
            },
            {
                'id': 92,
                'fSignal': 'f_CCsetPlus',
                'description': 'steering wheel set plus request',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Push buttons'
            },
            {
                'id': 93,
                'fSignal': 'f_ECOsetPlus',
                'description': 'steering wheel ECO set plus request',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Push buttons'
            },
            {
                'id': 94,
                'fSignal': 'f_ECOsetMinus',
                'description': 'steering wheel ECO set minus request',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Push buttons'
            },
            {
                'id': 100,
                'fSignal': 'off',
                'description': '',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 101,
                'fSignal': 'on',
                'description': '',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 102,
                'fSignal': 'f_ICErunning',
                'description': '1 = engine is running',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 103,
                'fSignal': 'f_neutrInd',
                'description': '1 = transmission is in neutral',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 104,
                'fSignal': 'f_parkingBrkSw',
                'description': '1 = parking brake is engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 105,
                'fSignal': 'f_revGearActv',
                'description': '1 = reverse gear is engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 106,
                'fSignal': 'f_ABSactv',
                'description': '1 = ABS is active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 107,
                'fSignal': 'f_actualActivatedSplit',
                'description': '0 = high split, 1 = low split',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 108,
                'fSignal': 'f_AP1lowIdleSw',
                'description': '1 = accelerator pedal not pressed',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 109,
                'fSignal': 'f_APkickdownSw',
                'description': '1 = accelerator pedal kickdown pressed',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 110,
                'fSignal': 'f_brakeLightTruckRight',
                'description': '1 = brake light activated',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 111,
                'fSignal': 'f_clamp15active',
                'description': '1 = clamp15 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 112,
                'fSignal': 'f_drvlEngaged',
                'description': '0 = drive train open, 1 = drive train closed',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 113,
                'fSignal': 'f_highBeamHLRsp',
                'description': '1 = high beam headlight active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 114,
                'fSignal': 'f_lowBeamHLRsp',
                'description': '1 = low beam headlight active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 115,
                'fSignal': 'f_parkingLightCtrl',
                'description': '1 = parking light active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 116,
                'fSignal': 'f_warningBeacon1_2',
                'description': '1 = warning beacon group 1+2 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 117,
                'fSignal': 'f_warningBeacon3',
                'description': '1 = warning beacon group 3 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 118,
                'fSignal': 'f_workingLight1',
                'description': '1 = working light group 1 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 119,
                'fSignal': 'f_workingLight2',
                'description': '1 = working light group 2 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 120,
                'fSignal': 'f_ZDRact',
                'description': '1 = ZDR active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 121,
                'fSignal': 'f_actReqPrkHeat',
                'description': '1 = park heater active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 122,
                'fSignal': 'f_arbCentralLocked',
                'description': '1 = central locking locked',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 123,
                'fSignal': 'f_arbCentralUnlocked',
                'description': '1 = central locking unlocked',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 124,
                'fSignal': 'f_diffLockStateCentralFA',
                'description': '1 = differential lock Central FA engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 125,
                'fSignal': 'f_diffLockStateCentralRA',
                'description': '1 = differential lock Central RA engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 126,
                'fSignal': 'f_diffLockStateCentralTC',
                'description': '1 = differential lock Central TC engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 127,
                'fSignal': 'f_diffLockStateFA1',
                'description': '1 = differential lock FA1 engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 128,
                'fSignal': 'f_diffLockStateFA2',
                'description': '1 = differential lock FA2 engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 129,
                'fSignal': 'f_diffLockStateRA1',
                'description': '1 = differential lock RA1 engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 130,
                'fSignal': 'f_diffLockStateRA2',
                'description': '1 = differential lock RA2 engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 131,
                'fSignal': 'f_doorContactCoDrvrState',
                'description': '0 = door closed, 1 = door open',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 132,
                'fSignal': 'f_doorContactDriverState',
                'description': '0 = door closed, 1 = door open',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 133,
                'fSignal': 'f_fuelLvlWarning',
                'description': '1 = fuel level warning active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 134,
                'fSignal': 'f_wiperMotorFrontStep1',
                'description': '1 = front wiper step 1 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 135,
                'fSignal': 'f_wiperMotorFrontStep2',
                'description': '1 = front wiper step 2 active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 136,
                'fSignal': 'f_cabLockRsp',
                'description': '1 = cabin unlocked',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 137,
                'fSignal': 'f_disengageDrvlBobu',
                'description': '1 = disengage driveline requested via bodybuilder interface',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 138,
                'fSignal': 'f_tipperUnlockedState',
                'description': '1 = tipper unlocked',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 139,
                'fSignal': 'f_trl1Rgn',
                'description': '1 = trailer recognized',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 140,
                'fSignal': 'f_reqDrivingDir1',
                'description': '1 = requested driving direction is D',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 141,
                'fSignal': 'f_reqDrivingDir1',
                'description': '1 = requested driving direction is N',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 142,
                'fSignal': 'f_reqDrivingDir1',
                'description': '1 = requested driving direction is R',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 143,
                'fSignal': 'f_boBuActSA',
                'description': '1 = active bodybuilder source adress is 1',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 144,
                'fSignal': 'f_boBuActSA',
                'description': '1 = active bodybuilder source adress is 2',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 145,
                'fSignal': 'f_boBuActSA',
                'description': '1 = active bodybuilder source adress is 3',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 146,
                'fSignal': 'f_indicatorLightMode',
                'description': '1 = indicator left activated',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 147,
                'fSignal': 'f_indicatorLightMode',
                'description': '1 = indicator right activated',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 148,
                'fSignal': 'f_indicatorLightMode',
                'description': '1= emergency light active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 149,
                'fSignal': 'f_lvlCtrlMd1veh',
                'description': '1 = exhaust bellows is active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 150,
                'fSignal': 'f_nomLvlFA1veh',
                'description': '1 = FA1 level is equal parameter',
                'additional_parameter': 'BBCL_p_actvLvlFA1',
                'additional_information': 'parameter for height FA1',
                'category': 'Vehicle Status'
            },
            {
                'id': 151,
                'fSignal': 'f_nomLvlFA2veh',
                'description': '1 = FA2 level is equal parameter',
                'additional_parameter': 'BBCL_p_actvLvlFA2',
                'additional_information': 'parameter for height FA2',
                'category': 'Vehicle Status'
            },
            {
                'id': 152,
                'fSignal': 'f_nomLvlRA1veh',
                'description': '1 = RA1 level is equal parameter',
                'additional_parameter': 'BBCL_p_actvLvlRA1',
                'additional_information': 'parameter for height RA1',
                'category': 'Vehicle Status'
            },
            {
                'id': 153,
                'fSignal': 'f_nomLvlRA2veh',
                'description': '1 = RA2 level is equal parameter',
                'additional_parameter': 'BBCL_p_actvLvlRA2',
                'additional_information': 'parameter for height RA2',
                'category': 'Vehicle Status'
            },
            {
                'id': 154,
                'fSignal': 'f_statusPTO1',
                'description': '1 = PTO1 is active  ',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 155,
                'fSignal': 'f_statusPTO1',
                'description': '1 = PTO1 is active and engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 156,
                'fSignal': 'f_statusPTO2',
                'description': '1 = PTO2 is active  ',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 157,
                'fSignal': 'f_statusPTO2',
                'description': '1 = PTO2 is active and engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 158,
                'fSignal': 'f_statusPTO3',
                'description': '1 = PTO3 is active  ',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 159,
                'fSignal': 'f_statusPTO3',
                'description': '1 = PTO3 is active and engaged',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 160,
                'fSignal': 'f_ZDRactiveMode',
                'description': '1 = ZDR ist equal parameter',
                'additional_parameter': 'BBCL_p_actvZDRmode',
                'additional_information': 'parameter for ZDR mode',
                'category': 'Vehicle Status'
            },
            {
                'id': 161,
                'fSignal': 'f_DPFactvRegenerationManual',
                'description': '1 = manual DPF Regeneration active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 162,
                'fSignal': 'f_DPFactvRegenerationAuto',
                'description': '1 = automatic DPF Regeneration active',
                'additional_parameter': '',
                'additional_information': '',
                'category': 'Vehicle Status'
            },
            {
                'id': 200,
                'fSignal': 'configurable threshold 1',
                'description': '1 = is eval/greater parameter ',
                'additional_parameter': 'BBCL_p_upperThresCL1',
                'additional_information': 'upper threshold for configurable threshold 1',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 1',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL1',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 201,
                'fSignal': 'configurable threshold 1',
                'description': '1 = is smaller parameter ',
                'additional_parameter': 'BBCL_p_lowerThresCL1',
                'additional_information': 'lower threshold for configurable threshold 1',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 1',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL1',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 202,
                'fSignal': 'configurable threshold 2',
                'description': '1 = is eval/greater parameter ',
                'additional_parameter': 'BBCL_p_upperThresCL2',
                'additional_information': 'upper threshold for configurable threshold 2',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 2',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL2',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 203,
                'fSignal': 'configurable threshold 2',
                'description': '1 = is smaller parameter ',
                'additional_parameter': 'BBCL_p_lowerThresCL2',
                'additional_information': 'lower threshold for configurable threshold 2',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 2',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL2',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 204,
                'fSignal': 'configurable threshold 3',
                'description': '1 = is greater parameter ',
                'additional_parameter': 'BBCL_p_upperThresCL3',
                'additional_information': 'upper threshold for configurable threshold 3',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 3',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL3',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 205,
                'fSignal': 'configurable threshold 3',
                'description': '1 = is eval/smaller parameter ',
                'additional_parameter': 'BBCL_p_lowerThresCL3',
                'additional_information': 'lower threshold for configurable threshold 3',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 3',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL3',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 206,
                'fSignal': 'configurable threshold 4',
                'description': '1 = greater parameter ',
                'additional_parameter': 'BBCL_p_upperThresCL4',
                'additional_information': 'upper threshold for configurable threshold 4',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 4',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL4',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 207,
                'fSignal': 'configurable threshold 4',
                'description': '1 = is eval/smaller parameter ',
                'additional_parameter': 'BBCL_p_lowerThresCL4',
                'additional_information': 'lower threshold for configurable threshold 4',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 4',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL4',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 208,
                'fSignal': 'configurable threshold 5',
                'description': '1 = is eval parameter ',
                'additional_parameter': 'BBCL_p_upperThresCL5',
                'additional_information': 'upper threshold for configurable threshold 5',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 5',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL5',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            },
            {
                'id': 209,
                'fSignal': 'configurable threshold 5',
                'description': '1 = is eval parameter ',
                'additional_parameter': 'BBCL_p_lowerThresCL5',
                'additional_information': 'lower threshold for configurable threshold 5',
                'category': 'Threshold',
                'source': {
                    'id': '',
                    'fSignal': 'configurable threshold 5',
                    'description': 'define source signal via parameter',
                    'additional_parameter': 'BBCL_p_thresSrcCL5',
                    'additional_information': 'parameter to define source signal for threshold evaluation',
                    'category': 'Input/Output Pin'
                }
            }        ],
        // inputVectors: [
        //     {
        //         id: 0,
        //         fSignal: 'f_boBuConfIn01',
        //         description: 'X4481 - Pin3 - input',
        //         additional_parameter: '',
        //         additional_information: 'resistance coded',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 1,
        //         fSignal: 'f_boBuConfIn02',
        //         description: 'X4481 - Pin2 - input',
        //         additional_parameter: '',
        //         additional_information: 'resistance coded',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 2,
        //         fSignal: 'f_boBuConfIn03',
        //         description: 'X4481 - Pin9 - input',
        //         additional_parameter: '',
        //         additional_information: 'resistance coded',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 3,
        //         fSignal: 'f_boBuConfIn04',
        //         description: 'X4481 - Pin11 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 4,
        //         fSignal: 'f_boBuConfIn05',
        //         description: 'X4482 - Pin21 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 5,
        //         fSignal: 'f_boBuConfIn06',
        //         description: 'X6493 - Pin17 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 6,
        //         fSignal: 'f_boBuConfIn07',
        //         description: 'X4481 - Pin4 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 7,
        //         fSignal: 'f_boBuConfIn08',
        //         description: 'X4481 - Pin1 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 8,
        //         fSignal: 'f_boBuConfIn09',
        //         description: 'X4481 - Pin8 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 9,
        //         fSignal: 'f_boBuConfIn10',
        //         description: 'X4481 - Pin7 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 10,
        //         fSignal: 'f_boBuConfIn11',
        //         description: 'X4481 - Pin6 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 11,
        //         fSignal: 'f_boBuConfIn12',
        //         description: 'X4481 - Pin12 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 12,
        //         fSignal: 'f_boBuConfIn13',
        //         description: 'X4481 - Pin5 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 13,
        //         fSignal: 'f_boBuConfIn14',
        //         description: 'X4481 - Pin10 - input',
        //         additional_parameter: '',
        //         additional_information: 'resistance coded',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 14,
        //         fSignal: 'f_boBuConfIn15',
        //         description: 'X4481 - Pin15 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 15,
        //         fSignal: 'f_boBuConfIn16',
        //         description: 'X4481 - Pin17 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 16,
        //         fSignal: 'f_boBuConfIn17',
        //         description: 'X4481 - Pin16 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 17,
        //         fSignal: 'f_boBuConfIn18',
        //         description: 'X4481 - Pin18 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 18,
        //         fSignal: 'f_boBuConfIn19',
        //         description: 'X4481 - Pin19 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 19,
        //         fSignal: 'f_boBuConfIn20',
        //         description: 'X4481 - Pin20 - input',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 20,
        //         fSignal: 'f_boBuConfIn01st',
        //         description: 'diagnosis of X4481 - Pin3 - input (broken connection, shortcut ubat …)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 21,
        //         fSignal: 'f_boBuConfIn02st',
        //         description: 'diagnosis of X4481 - Pin2 - input (broken connection, shortcut ubat …)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 22,
        //         fSignal: 'f_boBuConfIn03st',
        //         description: 'diagnosis of X4481 - Pin9 - input (broken connection, shortcut ubat …)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 23,
        //         fSignal: 'f_boBuConfIn14st',
        //         description: 'diagnosis of X4481 - Pin10 - input (broken connection, shortcut ubat …)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 24,
        //         fSignal: 'f_CLboBuConfOut1_outpSt',
        //         description: 'diagnosis of X4482 - Pin13 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: 'eiocdp_p_selectDiagPwmOut01',
        //         additional_information: 'no test pulses, parameter to select diagnosis on pin',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 25,
        //         fSignal: 'f_CLboBuConfOut2_outpSt',
        //         description: 'diagnosis of X4482 - Pin20 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: '',
        //         additional_information: 'test pulses',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 26,
        //         fSignal: 'f_CLboBuConfOut3_outpSt',
        //         description: 'diagnosis of X4482 - Pin4 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: '',
        //         additional_information: 'test pulses',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 27,
        //         fSignal: 'f_CLboBuConfOut4_outpSt',
        //         description: 'diagnosis of X4482 - Pin17 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: 'eiocdp_p_selectDiagPwmOut05',
        //         additional_information: 'no test pulses, parameter to select diagnosis on pin',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 28,
        //         fSignal: 'f_CLboBuConfOut5_outpSt',
        //         description: 'diagnosis of X4482 - Pin18 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: 'eiocdp_p_selectDiagPwmOut06',
        //         additional_information: 'no test pulses, parameter to select diagnosis on pin',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 29,
        //         fSignal: 'f_CLboBuConfOut6_outpSt',
        //         description: 'diagnosis of X4482 - Pin15 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: 'eiocdp_p_selectDiagPwmOut03',
        //         additional_information: 'no test pulses, parameter to select diagnosis on pin',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 30,
        //         fSignal: 'f_CLboBuConfOut7_outpSt',
        //         description: 'diagnosis of X4482 - Pin16 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: 'eiocdp_p_selectDiagPwmOut04',
        //         additional_information: 'no test pulses, parameter to select diagnosis on pin',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 31,
        //         fSignal: 'f_CLboBuConfOut8_outpSt',
        //         description: 'diagnosis of X4482 - Pin14 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: 'eiocdp_p_selectDiagPwmOut02',
        //         additional_information: 'no test pulses, parameter to select diagnosis on pin',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 32,
        //         fSignal: 'f_CLboBuConfOut9_outpSt',
        //         description: 'diagnosis of X4482 - Pin5 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: '',
        //         additional_information: 'test pulses',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 33,
        //         fSignal: 'f_CLboBuConfOut10_outpSt',
        //         description: 'diagnosis of X4482 - Pin2 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: '',
        //         additional_information: 'test pulses',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 34,
        //         fSignal: 'f_CLboBuConfOut11_outpSt',
        //         description: 'diagnosis of X4482 - Pin3 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: '',
        //         additional_information: 'test pulses',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 35,
        //         fSignal: 'f_CLboBuConfOut12_outpSt',
        //         description: 'diagnosis of X4482 - Pin19 - output (broken connection, shortcut ubat…)',
        //         additional_parameter: '',
        //         additional_information: 'test pulses',
        //         category: 'Input/Output Pin',
        //     },
        //     {
        //         id: 50,
        //         fSignal: 'f_genericBobuIn101',
        //         description: 'CAN Input 1 - Bool (0 or 1)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 51,
        //         fSignal: 'f_genericBobuIn102',
        //         description: 'CAN Input 2 - Bool (0 or 1)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 52,
        //         fSignal: 'f_genericBobuIn103',
        //         description: 'CAN Input 3 - Bool (0 or 1)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 53,
        //         fSignal: 'f_genericBobuIn104',
        //         description: 'CAN Input 4 - Bool (0 or 1)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 54,
        //         fSignal: 'f_genericBobuIn105',
        //         description: 'CAN Input 5 - Bool (0 or 1)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 55,
        //         fSignal: 'f_genericBobuIn106',
        //         description: 'CAN Input 6 - Bool (0 or 1)',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 56,
        //         fSignal: 'f_genericBobuIn201',
        //         description: 'CAN Input 7 - UINT8  - eval/above threshold',
        //         additional_parameter: 'BBCL_p_upperThresCAN1',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 57,
        //         fSignal: 'f_genericBobuIn201',
        //         description: 'CAN Input 8 - UINT8 - below threshold',
        //         additional_parameter: 'BBCL_p_lowerThresCAN1',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 58,
        //         fSignal: 'f_genericBobuIn202',
        //         description: 'CAN Input 9 - UINT8 - eval/above threshold',
        //         additional_parameter: 'BBCL_p_upperThresCAN2',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 59,
        //         fSignal: 'f_genericBobuIn202',
        //         description: 'CAN Input 10 - UINT8 - below threshold',
        //         additional_parameter: 'BBCL_p_lowerThresCAN2',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 60,
        //         fSignal: 'f_genericBobuIn203',
        //         description: 'CAN Input 11 - UINT8 - above threshold',
        //         additional_parameter: 'BBCL_p_upperThresCAN3',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 61,
        //         fSignal: 'f_genericBobuIn203',
        //         description: 'CAN Input 12 - UINT8 - eval/below threshold',
        //         additional_parameter: 'BBCL_p_lowerThresCAN3',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 62,
        //         fSignal: 'f_genericBobuIn301',
        //         description: 'CAN Input 13 - UINT16 - above threshold',
        //         additional_parameter: 'BBCL_p_upperThresCAN4',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 63,
        //         fSignal: 'f_genericBobuIn301',
        //         description: 'CAN Input 14 - UINT16 - eval/below threshold',
        //         additional_parameter: 'BBCL_p_lowerThresCAN4',
        //         additional_information: '',
        //         category: 'CAN Input',
        //     },
        //     {
        //         id: 80,
        //         fSignal: 'f_pbDoorBobuReq1',
        //         description: 'pushbutton 1 (pushbutton field door)',
        //         additional_parameter: 'BBCL_p_pbSwitchDoor1',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 81,
        //         fSignal: 'f_pbDoorBobuReq2',
        //         description: 'pushbutton 2 (pushbutton field door)',
        //         additional_parameter: 'BBCL_p_pbSwitchDoor2',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 82,
        //         fSignal: 'f_pbDoorBobuReq3',
        //         description: 'pushbutton 3 (pushbutton field door)',
        //         additional_parameter: 'BBCL_p_pbSwitchDoor3',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 83,
        //         fSignal: 'f_pbDoorBobuReq4',
        //         description: 'pushbutton 4 (pushbutton field door)',
        //         additional_parameter: 'BBCL_p_pbSwitchDoor4',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 84,
        //         fSignal: 'f_pbBobuReq1',
        //         description: 'pushbutton 1 (6- pushbutton field)',
        //         additional_parameter: 'BBCL_p_pbSwitch1',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 85,
        //         fSignal: 'f_pbBobuReq2',
        //         description: 'pushbutton 2 (6- pushbutton field)',
        //         additional_parameter: 'BBCL_p_pbSwitch2',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 86,
        //         fSignal: 'f_pbBobuReq3',
        //         description: 'pushbutton 3 (6- pushbutton field)',
        //         additional_parameter: 'BBCL_p_pbSwitch3',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 87,
        //         fSignal: 'f_pbBobuReq4',
        //         description: 'pushbutton 4 (6- pushbutton field)',
        //         additional_parameter: 'BBCL_p_pbSwitch4',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 88,
        //         fSignal: 'f_pbBobuReq5',
        //         description: 'pushbutton 5 (6- pushbutton field)',
        //         additional_parameter: 'BBCL_p_pbSwitch5',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 89,
        //         fSignal: 'f_pbBobuReq6',
        //         description: 'pushbutton 6 (6- pushbutton field)',
        //         additional_parameter: 'BBCL_p_pbSwitch6',
        //         additional_information: 'Parameter for change between pushbutton and switch logic',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 90,
        //         fSignal: 'f_CCoffReq',
        //         description: 'steering wheel pushbutton - off request',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 91,
        //         fSignal: 'f_CCsetReq',
        //         description: 'steering wheel pushbutton - set request',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 92,
        //         fSignal: 'f_CCsetPlus',
        //         description: 'steering wheel pushbutton - set plus request',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 93,
        //         fSignal: 'f_ECOsetPlus',
        //         description: 'steering wheel pushbutton - ECO set plus request',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 94,
        //         fSignal: 'f_ECOsetMinus',
        //         description: 'steering wheel pushbutton - ECO set minus request',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Push buttons',
        //     },
        //     {
        //         id: 100,
        //         fSignal: 'off',
        //         description: '',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 101,
        //         fSignal: 'on',
        //         description: '',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 102,
        //         fSignal: 'f_ICErunning',
        //         description: '1 = engine is running',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 103,
        //         fSignal: 'f_neutrInd',
        //         description: '1 = transmission is in neutral',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 104,
        //         fSignal: 'f_parkingBrkSw',
        //         description: '1 = parking brake is engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 105,
        //         fSignal: 'f_revGearActv',
        //         description: '1 = reverse gear is engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 106,
        //         fSignal: 'f_ABSactv',
        //         description: '1 = ABS is active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 107,
        //         fSignal: 'f_actualActivatedSplit',
        //         description: '0 = high split, 1 = low split',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 108,
        //         fSignal: 'f_AP1lowIdleSw',
        //         description: '1 = accelerator pedal not pressed',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 109,
        //         fSignal: 'f_APkickdownSw',
        //         description: '1 = accelerator pedal kickdown pressed',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 110,
        //         fSignal: 'f_brakeLightTruckRight',
        //         description: '1 = brake light activated',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 111,
        //         fSignal: 'f_clamp15active',
        //         description: '1 = clamp15 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 112,
        //         fSignal: 'f_drvlEngaged',
        //         description: '0 = drive train open, 1 = drive train closed',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 113,
        //         fSignal: 'f_highBeamHLRsp',
        //         description: '1 = high beam headlight active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 114,
        //         fSignal: 'f_lowBeamHLRsp',
        //         description: '1 = low beam headlight active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 115,
        //         fSignal: 'f_parkingLightCtrl',
        //         description: '1 = parking light active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 116,
        //         fSignal: 'f_warningBeacon1_2',
        //         description: '1 = warning beacon group 1+2 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 117,
        //         fSignal: 'f_warningBeacon3',
        //         description: '1 = warning beacon group 3 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 118,
        //         fSignal: 'f_workingLight1',
        //         description: '1 = working light group 1 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 119,
        //         fSignal: 'f_workingLight2',
        //         description: '1 = working light group 2 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 120,
        //         fSignal: 'f_ZDRact',
        //         description: '1 = ZDR active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 121,
        //         fSignal: 'f_actReqPrkHeat',
        //         description: '1 = park heater active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 122,
        //         fSignal: 'f_arbCentralLocked',
        //         description: '1 = central locking locked',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 123,
        //         fSignal: 'f_arbCentralUnlocked',
        //         description: '1 = central locking unlocked',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 124,
        //         fSignal: 'f_diffLockStateCentralFA',
        //         description: '1 = differential lock Central FA engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 125,
        //         fSignal: 'f_diffLockStateCentralRA',
        //         description: '1 = differential lock Central RA engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 126,
        //         fSignal: 'f_diffLockStateCentralTC',
        //         description: '1 = differential lock Central TC engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 127,
        //         fSignal: 'f_diffLockStateFA1',
        //         description: '1 = differential lock FA1 engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 128,
        //         fSignal: 'f_diffLockStateFA2',
        //         description: '1 = differential lock FA2 engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 129,
        //         fSignal: 'f_diffLockStateRA1',
        //         description: '1 = differential lock RA1 engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 130,
        //         fSignal: 'f_diffLockStateRA2',
        //         description: '1 = differential lock RA2 engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 131,
        //         fSignal: 'f_doorContactCoDrvrState',
        //         description: '0 = door closed, 1 = door open',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 132,
        //         fSignal: 'f_doorContactDriverState',
        //         description: '0 = door closed, 1 = door open',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 133,
        //         fSignal: 'f_fuelLvlWarning',
        //         description: '1 = fuel level warning active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 134,
        //         fSignal: 'f_wiperMotorFrontStep1',
        //         description: '1 = front wiper step 1 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 135,
        //         fSignal: 'f_wiperMotorFrontStep2',
        //         description: '1 = front wiper step 2 active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 136,
        //         fSignal: 'f_cabLockRsp',
        //         description: '1 = cabin unlocked',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 137,
        //         fSignal: 'f_disengageDrvlBobu',
        //         description: '1 = disengage driveline requested via bodybuilder interface',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 138,
        //         fSignal: 'f_tipperUnlockedState',
        //         description: '1 = tipper unlocked',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 139,
        //         fSignal: 'f_trl1Rgn',
        //         description: '1 = trailer recognized',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 140,
        //         fSignal: 'f_reqDrivingDir1',
        //         description: '1 = requested driving direction is D',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 141,
        //         fSignal: 'f_reqDrivingDir1',
        //         description: '1 = requested driving direction is N',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 142,
        //         fSignal: 'f_reqDrivingDir1',
        //         description: '1 = requested driving direction is R',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 143,
        //         fSignal: 'f_boBuActSA',
        //         description: '1 = active bodybuilder source adress is 1',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 144,
        //         fSignal: 'f_boBuActSA',
        //         description: '1 = active bodybuilder source adress is 2',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 145,
        //         fSignal: 'f_boBuActSA',
        //         description: '1 = active bodybuilder source adress is 3',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 146,
        //         fSignal: 'f_indicatorLightMode',
        //         description: '1 = indicator left activated',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 147,
        //         fSignal: 'f_indicatorLightMode',
        //         description: '1 = indicator right activated',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 148,
        //         fSignal: 'f_indicatorLightMode',
        //         description: '1= emergency light active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 149,
        //         fSignal: 'f_lvlCtrlMd1veh',
        //         description: '1 = exhaust bellows is active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 150,
        //         fSignal: 'f_nomLvlFA1veh',
        //         description: '1 = FA1 level is equal parameter',
        //         additional_parameter: 'BBCL_p_actvLvlFA1',
        //         additional_information: 'parameter for height FA1',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 151,
        //         fSignal: 'f_nomLvlFA2veh',
        //         description: '1 = FA2 level is equal parameter',
        //         additional_parameter: 'BBCL_p_actvLvlFA2',
        //         additional_information: 'parameter for height FA2',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 152,
        //         fSignal: 'f_nomLvlRA1veh',
        //         description: '1 = RA1 level is equal parameter',
        //         additional_parameter: 'BBCL_p_actvLvlRA1',
        //         additional_information: 'parameter for height RA1',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 153,
        //         fSignal: 'f_nomLvlRA2veh',
        //         description: '1 = RA2 level is equal parameter',
        //         additional_parameter: 'BBCL_p_actvLvlRA2',
        //         additional_information: 'parameter for height RA2',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 154,
        //         fSignal: 'f_statusPTO1',
        //         description: '1 = PTO1 is active  ',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 155,
        //         fSignal: 'f_statusPTO1',
        //         description: '1 = PTO1 is active and engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 156,
        //         fSignal: 'f_statusPTO2',
        //         description: '1 = PTO2 is active  ',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 157,
        //         fSignal: 'f_statusPTO2',
        //         description: '1 = PTO2 is active and engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 158,
        //         fSignal: 'f_statusPTO3',
        //         description: '1 = PTO3 is active  ',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 159,
        //         fSignal: 'f_statusPTO3',
        //         description: '1 = PTO3 is active and engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 160,
        //         fSignal: 'f_ZDRactiveMode',
        //         description: '1 = ZDR ist equal parameter',
        //         additional_parameter: 'BBCL_p_actvZDRmode',
        //         additional_information: 'parameter for ZDR mode',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 161,
        //         fSignal: 'f_DPFactvRegenerationManual',
        //         description: '1 = manual DPF Regeneration active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 162,
        //         fSignal: 'f_DPFactvRegenerationAuto',
        //         description: '1 = automatic DPF Regeneration active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 163,
        //         fSignal: 'f_drvSysActv',
        //         description: '1 = drive system active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 164,
        //         fSignal: 'f_EngStartReqKl50',
        //         description: '1 = start requested',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 165,
        //         fSignal: 'f_drvProgChar',
        //         description: '1= special drive program active',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 166,
        //         fSignal: 'f_extCAMshown1',
        //         description: '1 = camera 1 is shown',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 167,
        //         fSignal: 'f_extCAMshown2',
        //         description: '1 = camera 2 is shown',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 168,
        //         fSignal: 'f_extCAMshown3',
        //         description: '1 = camera 3 is shown',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 169,
        //         fSignal: 'f_extCAMshown4',
        //         description: '1 = camera 4 is shown',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 170,
        //         fSignal: 'f_extCAMshown5',
        //         description: '1 = camera 5 is shown',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 171,
        //         fSignal: 'f_statusEPTO',
        //         description: '1 = PTO3 is active and engaged',
        //         additional_parameter: '',
        //         additional_information: '',
        //         category: 'Vehicle Status',
        //     },
        //     {
        //         id: 200,
        //         fSignal: 'configurable threshold 1',
        //         description: '1 = is eval/greater parameter ',
        //         additional_parameter: 'BBCL_p_upperThresCL1',
        //         additional_information: 'upper threshold for configurable threshold 1',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 1',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL1',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 201,
        //         fSignal: 'configurable threshold 1',
        //         description: '1 = is smaller parameter ',
        //         additional_parameter: 'BBCL_p_lowerThresCL1',
        //         additional_information: 'lower threshold for configurable threshold 1',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 1',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL1',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 202,
        //         fSignal: 'configurable threshold 2',
        //         description: '1 = is eval/greater parameter ',
        //         additional_parameter: 'BBCL_p_upperThresCL2',
        //         additional_information: 'upper threshold for configurable threshold 2',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 2',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL2',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 203,
        //         fSignal: 'configurable threshold 2',
        //         description: '1 = is smaller parameter ',
        //         additional_parameter: 'BBCL_p_lowerThresCL2',
        //         additional_information: 'lower threshold for configurable threshold 2',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 2',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL2',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 204,
        //         fSignal: 'configurable threshold 3',
        //         description: '1 = is greater parameter ',
        //         additional_parameter: 'BBCL_p_upperThresCL3',
        //         additional_information: 'upper threshold for configurable threshold 3',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 3',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL3',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 205,
        //         fSignal: 'configurable threshold 3',
        //         description: '1 = is eval/smaller parameter ',
        //         additional_parameter: 'BBCL_p_lowerThresCL3',
        //         additional_information: 'lower threshold for configurable threshold 3',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 3',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL3',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 206,
        //         fSignal: 'configurable threshold 4',
        //         description: '1 = greater parameter ',
        //         additional_parameter: 'BBCL_p_upperThresCL4',
        //         additional_information: 'upper threshold for configurable threshold 4',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 4',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL4',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 207,
        //         fSignal: 'configurable threshold 4',
        //         description: '1 = is eval/smaller parameter ',
        //         additional_parameter: 'BBCL_p_lowerThresCL4',
        //         additional_information: 'lower threshold for configurable threshold 4',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 4',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL4',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 208,
        //         fSignal: 'configurable threshold 5',
        //         description: '1 = is eval parameter ',
        //         additional_parameter: 'BBCL_p_upperThresCL5',
        //         additional_information: 'upper threshold for configurable threshold 5',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 5',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL5',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        //     {
        //         id: 209,
        //         fSignal: 'configurable threshold 5',
        //         description: '1 = is eval parameter ',
        //         additional_parameter: 'BBCL_p_lowerThresCL5',
        //         additional_information: 'lower threshold for configurable threshold 5',
        //         category: 'Threshold',
        //         source: {
        //             id: '',
        //             fSignal: 'configurable threshold 5',
        //             description: 'define source signal via parameter',
        //             additional_parameter: 'BBCL_p_thresSrcCL5',
        //             additional_information: 'parameter to define source signal for threshold evaluation',
        //             category: 'Input/Output Pin',
        //         },
        //     },
        // ],
        outputVectorsLevelA: [
            {
                id: 250,
                fSignal: 'BBCL_levA_And1',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 251,
                fSignal: 'BBCL_levA_And2',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 252,
                fSignal: 'BBCL_levA_And3',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 253,
                fSignal: 'BBCL_levA_And4',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 254,
                fSignal: 'BBCL_levA_Or1',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 255,
                fSignal: 'BBCL_levA_Or2',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 256,
                fSignal: 'BBCL_levA_Or3',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 257,
                fSignal: 'BBCL_levA_Or4',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 258,
                fSignal: 'outputTimer10ms_levA_Timer1',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 259,
                fSignal: 'outputTimer10ms_levA_Timer2',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 260,
                fSignal: 'outputTimer10ms_levA_Timer3',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 261,
                fSignal: 'outputTimer10ms_levA_Timer4',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 262,
                fSignal: 'BBCL_levA_Not1',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 263,
                fSignal: 'BBCL_levA_Not2',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 264,
                fSignal: 'BBCL_levA_Not3',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 265,
                fSignal: 'BBCL_levA_Not4',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 266,
                fSignal: 'BBCL_levA_Delay1',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 267,
                fSignal: 'BBCL_levA_Delay2',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 268,
                fSignal: 'BBCL_levA_Delay3',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 269,
                fSignal: 'BBCL_levA_Delay4',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 270,
                fSignal: 'BBCL_levA_Flipflop1',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 271,
                fSignal: 'BBCL_levA_Flipflop2',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 272,
                fSignal: 'BBCL_levA_Flipflop3',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 273,
                fSignal: 'BBCL_levA_Flipflop4',
                description: 'result from Level A',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
        ],
        outputVectorsLevelB: [
            {
                id: 274,
                fSignal: 'BBCL_levB_And1',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 275,
                fSignal: 'BBCL_levB_And2',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 276,
                fSignal: 'BBCL_levB_And3',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 277,
                fSignal: 'BBCL_levB_And4',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 278,
                fSignal: 'BBCL_levB_Or1',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 279,
                fSignal: 'BBCL_levB_Or2',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 280,
                fSignal: 'BBCL_levB_Or3',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 281,
                fSignal: 'BBCL_levB_Or4',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 282,
                fSignal: 'outputTimer10ms_levB_Timer1',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 283,
                fSignal: 'outputTimer10ms_levB_Timer2',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 284,
                fSignal: 'outputTimer10ms_levB_Timer3',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 285,
                fSignal: 'outputTimer10ms_levB_Timer4',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 286,
                fSignal: 'BBCL_levB_Not1',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 287,
                fSignal: 'BBCL_levB_Not2',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 288,
                fSignal: 'BBCL_levB_Not3',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 289,
                fSignal: 'BBCL_levB_Not4',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 290,
                fSignal: 'BBCL_levB_Delay1',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 291,
                fSignal: 'BBCL_levB_Delay2',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 292,
                fSignal: 'BBCL_levB_Delay3',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 293,
                fSignal: 'BBCL_levB_Delay4',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 294,
                fSignal: 'BBCL_levB_Flipflop1',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 295,
                fSignal: 'BBCL_levB_Flipflop2',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 296,
                fSignal: 'BBCL_levB_Flipflop3',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
            {
                id: 297,
                fSignal: 'BBCL_levB_Flipflop4',
                description: 'result from Level B',
                additional_parameter: '',
                additional_information: '',
                category: '',
            },
        ],
        outputVectors: [
            {
                id: 0,
                parameter: 'BBCL_p_CLout1',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn1',
                additional_information:
                    'parameter to define function (outputFCN) from BBCL_levB_OutputVector, default ',
            },
            {
                id: 1,
                parameter: 'BBCL_p_CLout2',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn2',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 2,
                parameter: 'BBCL_p_CLout3',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn3',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 3,
                parameter: 'BBCL_p_CLout4',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn4',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 4,
                parameter: 'BBCL_p_CLout5',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn5',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 5,
                parameter: 'BBCL_p_CLout6',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn6',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 6,
                parameter: 'BBCL_p_CLout7',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn7',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 7,
                parameter: 'BBCL_p_CLout8',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn8',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 8,
                parameter: 'BBCL_p_CLout9',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn9',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 9,
                parameter: 'BBCL_p_CLout10',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn10',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 10,
                parameter: 'BBCL_p_CLout11',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn11',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 11,
                parameter: 'BBCL_p_CLout12',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn12',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 12,
                parameter: 'BBCL_p_CLout13',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn13',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 13,
                parameter: 'BBCL_p_CLout14',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn14',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 14,
                parameter: 'BBCL_p_CLout15',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn15',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 15,
                parameter: 'BBCL_p_CLout16',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn16',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 16,
                parameter: 'BBCL_p_CLout17',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn17',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 17,
                parameter: 'BBCL_p_CLout18',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn18',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 18,
                parameter: 'BBCL_p_CLout19',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn19',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
            {
                id: 19,
                parameter: 'BBCL_p_CLout20',
                description: 'parameter to define value from BBCL_LevB_OutputVector',
                additional_parameter: 'BBCL_p_CLoutFcn20',
                additional_information: 'parameter to define function (outputFCN) from BBCL_levB_OutputVector',
            },
        ],
        outputFunctions: [
            {
                id: 1,
                parameter: 'f_CLboBuConfOut1',
                description: 'X4482 - Pin13 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut1 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 2,
                parameter: 'f_CLboBuConfOut2',
                description: 'X4482 - Pin20 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut2 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 3,
                parameter: 'f_CLboBuConfOut3',
                description: 'X4482 - Pin4 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut3 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 4,
                parameter: 'f_CLboBuConfOut4',
                description: 'X4482 - Pin17 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut4 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 5,
                parameter: 'f_CLboBuConfOut5',
                description: 'X4482 - Pin18 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut5 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 6,
                parameter: 'f_CLboBuConfOut6',
                description: 'X4482 - Pin15 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut6 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 7,
                parameter: 'f_CLboBuConfOut7',
                description: 'X4482 - Pin16 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut7 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 8,
                parameter: 'f_CLboBuConfOut8',
                description: 'X4482 - Pin14 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut8 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 9,
                parameter: 'f_CLboBuConfOut9',
                description: 'X4482 - Pin5 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut9 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 10,
                parameter: 'f_CLboBuConfOut10',
                description: 'X4482 - Pin2 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut10 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 11,
                parameter: 'f_CLboBuConfOut11',
                description: 'X4482 - Pin3 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut11 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 12,
                parameter: 'f_CLboBuConfOut12',
                description: 'X4482 - Pin19 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut12 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 13,
                parameter: 'f_CLboBuConfOut13',
                description: 'X4482 - Pin1 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut13 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 14,
                parameter: 'f_CLboBuConfOut14',
                description: 'X4482 - Pin9 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut14 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 15,
                parameter: 'f_CLboBuConfOut15',
                description: 'X4482 - Pin6 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut15 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 16,
                parameter: 'f_CLboBuConfOut16',
                description: 'X4482 - Pin11 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut16 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 17,
                parameter: 'f_CLboBuConfOut17',
                description: 'X4482 - Pin7 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut17 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 18,
                parameter: 'f_CLboBuConfOut18',
                description: 'X4482 - Pin12 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut18 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 19,
                parameter: 'f_CLboBuConfOut19',
                description: 'X4482 - Pin8 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut19 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 20,
                parameter: 'f_CLboBuConfOut20',
                description: 'X4482 - Pin10 - output',
                additional_parameter: 'PARAM: BBCO_p_configSrcOut20 / BASE; 0',
                additional_information: 'parameter to define configurabe logic as source (==1)',
                category: 'Output Pin',
            },
            {
                id: 50,
                parameter: 'f_genericBobuOut101',
                description: 'CAN Output 1 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 51,
                parameter: 'f_genericBobuOut102',
                description: 'CAN Output 2 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 52,
                parameter: 'f_genericBobuOut103',
                description: 'CAN Output 3 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 53,
                parameter: 'f_genericBobuOut104',
                description: 'CAN Output 4 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 54,
                parameter: 'f_genericBobuOut105',
                description: 'CAN Output 5 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 55,
                parameter: 'f_genericBobuOut106',
                description: 'CAN Output 6 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 56,
                parameter: 'f_genericBobuOut107',
                description: 'CAN Output 7 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 57,
                parameter: 'f_genericBobuOut108',
                description: 'CAN Output 8 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 58,
                parameter: 'f_genericBobuOut109',
                description: 'CAN Output 9 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 59,
                parameter: 'f_genericBobuOut110',
                description: 'CAN Output 10 - Bool (0 or 1)',
                additional_parameter: '',
                additional_information: '',
                category: 'CAN Output',
            },
            {
                id: 80,
                parameter: 'f_pbDoorBobuRsp1',
                description: 'pushbutton 1 telltale (pushbutton field door)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 81,
                parameter: 'f_pbDoorBobuRsp2',
                description: 'pushbutton 2 telltale (pushbutton field door)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 82,
                parameter: 'f_pbDoorBobuRsp3',
                description: 'pushbutton 3 telltale (pushbutton field door)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 83,
                parameter: 'f_pbDoorBobuRsp4',
                description: 'pushbutton 4 telltale (pushbutton field door)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 84,
                parameter: 'f_pbBobuLED1',
                description: 'pushbutton 1 telltale (6- pushbutton field)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 85,
                parameter: 'f_pbBobuLED2',
                description: 'pushbutton 2 telltale (6- pushbutton field)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 86,
                parameter: 'f_pbBobuLED3',
                description: 'pushbutton 3 telltale (6- pushbutton field)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 87,
                parameter: 'f_pbBobuLED4',
                description: 'pushbutton 4 telltale (6- pushbutton field)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 88,
                parameter: 'f_pbBobuLED5',
                description: 'pushbutton 5 telltale (6- pushbutton field)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 89,
                parameter: 'f_pbBobuLED6',
                description: 'pushbutton 6 telltale (6- pushbutton field)',
                additional_parameter: '',
                additional_information: '',
                category: 'Push buttons',
            },
            {
                id: 100,
                parameter: 'f_CLboBuReqFcn1',
                description: 'request specific vehicle function 1',
                additional_parameter: 'BBVC_p_configCLfcn01',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 101,
                parameter: 'f_CLboBuReqFcn2',
                description: 'request specific vehicle function 2',
                additional_parameter: 'BBVC_p_configCLfcn02',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 102,
                parameter: 'f_CLboBuReqFcn3',
                description: 'request specific vehicle function 3',
                additional_parameter: 'BBVC_p_configCLfcn03',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 103,
                parameter: 'f_CLboBuReqFcn4',
                description: 'request specific vehicle function 4',
                additional_parameter: 'BBVC_p_configCLfcn04',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 104,
                parameter: 'f_CLboBuReqFcn5',
                description: 'request specific vehicle function 5',
                additional_parameter: 'BBVC_p_configCLfcn05',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 105,
                parameter: 'f_CLboBuReqFcn6',
                description: 'request specific vehicle function 6',
                additional_parameter: 'BBVC_p_configCLfcn06',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 106,
                parameter: 'f_CLboBuReqFcn7',
                description: 'request specific vehicle function 7',
                additional_parameter: 'BBVC_p_configCLfcn07',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 107,
                parameter: 'f_CLboBuReqFcn8',
                description: 'request specific vehicle function 8',
                additional_parameter: 'BBVC_p_configCLfcn08',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 108,
                parameter: 'f_CLboBuReqFcn9',
                description: 'request specific vehicle function 9',
                additional_parameter: 'BBVC_p_configCLfcn09',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
            {
                id: 109,
                parameter: 'f_CLboBuReqFcn10',
                description: 'request specific vehicle function 10',
                additional_parameter: 'BBVC_p_configCLfcn10',
                additional_information:
                    'parameter to define functionality out of a function list (see FCN: arbBobuRequests)',
                category: 'Vehicle functions',
            },
        ],
        sourceSignals: [
            {
                id: 0,
                fSignal: 'f_engSpd',
                description: 'engine speed',
                unit: 'rpm',
                lsb: '2^-3',
                minmax: '0 - 8031.875',
            },
            {
                id: 1,
                fSignal: 'f_vehSpd',
                description: 'vehicle speed',
                unit: 'km/h',
                lsb: '2^-8',
                minmax: '0 - 250.996',
            },
            {
                id: 2,
                fSignal: 'f_actEngTorq',
                description: 'actual engine torque',
                unit: '%',
                lsb: '2^-8',
                minmax: '-125 - 125',
            },
            {
                id: 3,
                fSignal: 'f_actRetPerTorqMC',
                description: 'endurance brake torque',
                unit: '%',
                lsb: '2^0',
                minmax: '-125 - 125',
            },
            {
                id: 4,
                fSignal: 'f_Appos',
                description: 'acelerator pedal position',
                unit: '%',
                lsb: '0.4',
                minmax: '0 - 100',
            },
            {
                id: 5,
                fSignal: 'f_axleWghtAxle0',
                description: 'axle weight on axle',
                unit: 'kg',
                lsb: '2^-1',
                minmax: '0 - 32127.5',
            },
            {
                id: 6,
                fSignal: 'f_axleWghtAxle1',
                description: 'axle weight on axle',
                unit: 'kg',
                lsb: '2^-1',
                minmax: '0 - 32127.5',
            },
            {
                id: 7,
                fSignal: 'f_axleWghtAxle2',
                description: 'axle weight on axle',
                unit: 'kg',
                lsb: '2^-1',
                minmax: '0 - 32127.5',
            },
            {
                id: 8,
                fSignal: 'f_axleWghtAxle3',
                description: 'axle weight on axle',
                unit: 'kg',
                lsb: '2^-1',
                minmax: '0 - 32127.5',
            },
            {
                id: 9,
                fSignal: 'f_axleWghtAxle4',
                description: 'axle weight on axle',
                unit: 'kg',
                lsb: '2^-1',
                minmax: '0 - 32127.5',
            },
            {
                id: 10,
                fSignal: 'f_axleWghtAxle5',
                description: 'axle weight on axle',
                unit: 'kg',
                lsb: '2^-1',
                minmax: '0 - 32127.5',
            },
            {
                id: 11,
                fSignal: 'f_Bppos',
                description: 'brake pedal position',
                unit: '%',
                lsb: '0.4',
                minmax: '0 - 100',
            },
            {
                id: 12,
                fSignal: 'f_engCoolantTempHR',
                description: 'engine coolant temperature',
                unit: '°C',
                lsb: '2^-5',
                minmax: '-273.0 - 1734.96875',
            },
            {
                id: 13,
                fSignal: 'f_engOilPress',
                description: 'engine oil pressure',
                unit: 'kPa',
                lsb: '2^2',
                minmax: '0 - 1000',
            },
            {
                id: 14,
                fSignal: 'f_ambAirTemp',
                description: 'ambient air temperature',
                unit: '°C',
                lsb: '2^-5',
                minmax: '-273.0 - 1734.96875',
            },
            {
                id: 15,
                fSignal: 'f_catalystTankLvl',
                description: 'catalyst tank level',
                unit: '%',
                lsb: '0.4',
                minmax: '0 - 100',
            },
            {
                id: 16,
                fSignal: 'f_dispBatt24V',
                description: 'Batterie Voltage',
                unit: 'V',
                lsb: '0.001',
                minmax: '0 - 64.255',
            },
            {
                id: 17,
                fSignal: 'f_fuelLvl',
                description: 'fuel level diesel tank',
                unit: '%',
                lsb: '0.4',
                minmax: '0 - 100',
            },
            {
                id: 18,
                fSignal: 'f_maxVehSpdLim',
                description: 'active vehicle speed limit',
                unit: 'km/h',
                lsb: 1,
                minmax: '0 - 250',
            },
            {
                id: 19,
                fSignal: 'f_reqSpdLimEng',
                description: 'engine speed limit',
                unit: 'rpm',
                lsb: '2^-3',
                minmax: '0 - 8031.875',
            },
            {
                id: 20,
                fSignal: 'f_reqTorqLimEng',
                description: 'engine torque limit',
                unit: '%',
                lsb: '2^-8',
                minmax: '-125 - 125',
            },
            {
                id: 21,
                fSignal: 'f_transCurGear',
                description: 'transmission current gear',
                unit: 'gear value',
                lsb: 1,
                minmax: '-125 - 125',
            },
            {
                id: 22,
                fSignal: 'f_transSelGear',
                description: 'transmission selected gear',
                unit: 'gear value',
                lsb: 1,
                minmax: '-125 - 125',
            },
            {
                id: 23,
                fSignal: 'f_reqSpdEng',
                description: 'requested engine speed',
                unit: 'rpm',
                lsb: '2^-3',
                minmax: '0 - 8031.875',
            },
            {
                id: 24,
                fSignal: 'f_reqTorqEng',
                description: 'requested engine torque',
                unit: '%',
                lsb: '2^-8',
                minmax: '-125 - 125',
            },
            {
                id: 25,
                fSignal: 'f_transOilTemp',
                description: 'transmission oil temperature',
                unit: '°C',
                lsb: '2^-5',
                minmax: '-273.0 - 1734.96875',
            },
            {
                id: 26,
                fSignal: 'f_stateOfChargeTES',
                description: 'state of charge (SoC) of the traction energy storage (TES)',
                unit: '%',
                lsb: '0.4',
                minmax: '0 - 100',
            },
        ],
        vehicleFunctions: [
            {
                id: 0,
                function: 'no function',
                remark: '',
            },
            {
                id: 1,
                function: 'Power window driver close request',
                remark: 'current solution: even one side request leads to an closing of both windows',
            },
            {
                id: 2,
                function: 'Power window codriver close request',
                remark: '',
            },
            {
                id: 3,
                function: 'Central locking request',
                remark: '',
            },
            {
                id: 4,
                function: 'Central unlocking request',
                remark: '',
            },
            {
                id: 5,
                function: 'signal horn request',
                remark: '',
            },
            {
                id: 6,
                function: 'signal horn inhibit request',
                remark: '',
            },
            {
                id: 7,
                function: 'ZDR request mode 1',
                remark: '',
            },
            {
                id: 8,
                function: 'ZDR request mode 2',
                remark: '',
            },
            {
                id: 9,
                function: 'ZDR request mode 3',
                remark: '',
            },
            {
                id: 10,
                function: 'ZDR request mode 4',
                remark: '',
            },
            {
                id: 11,
                function: 'ZDR request mode 5',
                remark: '',
            },
            {
                id: 12,
                function: 'ZDR request mode 6',
                remark: '',
            },
            {
                id: 13,
                function: 'ZDR request mode 7',
                remark: '',
            },
            {
                id: 14,
                function: 'PTO1 request',
                remark: '',
            },
            {
                id: 15,
                function: 'PTO2 request',
                remark: '',
            },
            {
                id: 16,
                function: 'PTO3 request',
                remark: '',
            },
            {
                id: 17,
                function: 'Motor start request',
                remark: '',
            },
            {
                id: 18,
                function: 'Motor stop request',
                remark: '',
            },
            {
                id: 19,
                function: 'lock cruise range R',
                remark: '',
            },
            {
                id: 20,
                function: 'lock cruise range D',
                remark: '',
            },
            {
                id: 21,
                function: 'lock cruise Range R and D',
                remark: '',
            },
            {
                id: 22,
                function: 'disengage driveline request',
                remark: '',
            },
            {
                id: 23,
                function: 'gearshift inhibit request',
                remark: '',
            },
            {
                id: 24,
                function: 'torque converter lockup request',
                remark: '',
            },
            {
                id: 25,
                function: 'drive program request',
                remark: '',
            },
            {
                id: 26,
                function: 'recirculation air conditioning request',
                remark: '',
            },
            {
                id: 27,
                function: 'reversing alert mode request',
                remark: 'only when reversing light is also requested',
            },
            {
                id: 28,
                function: 'inhibit dpf regeneration request',
                remark: '',
            },
            {
                id: 29,
                function: 'external brake request',
                remark: '',
            },
            {
                id: 30,
                function: 'external brake demand request',
                remark: 'e.g. -3.5 m/s',
            },
            {
                id: 31,
                function: 'parking light request',
                remark: '',
            },
            {
                id: 32,
                function: 'low beam headlight request',
                remark: '',
            },
            {
                id: 33,
                function: 'reversing light request',
                remark: '',
            },
            {
                id: 34,
                function: 'brake light request',
                remark: '',
            },
            {
                id: 35,
                function: 'hazard warn light request',
                remark: '',
            },
            {
                id: 36,
                function: 'rear fog light request',
                remark: '',
            },
            {
                id: 37,
                function: 'working light 1 request',
                remark: '',
            },
            {
                id: 38,
                function: 'working light 2 request',
                remark: '',
            },
            {
                id: 39,
                function: 'warning beacon 1_2 request',
                remark: '',
            },
            {
                id: 40,
                function: 'warning beacon 3 request',
                remark: '',
            },
            {
                id: 41,
                function: 'electrical parkbrake apply request',
                remark: '',
            },
            {
                id: 42,
                function: 'electrical parkbrake release inhibit request',
                remark: '',
            },
            {
                id: 43,
                function: 'ZDR off request',
                remark: '',
            },
            {
                id: 44,
                function: 'ZDR set+ request',
                remark: '',
            },
            {
                id: 45,
                function: 'ZDR set- request',
                remark: '',
            },
            {
                id: 46,
                function: 'ZDR resume request',
                remark: '',
            },
            {
                id: 47,
                function: 'mdb mode 1 request',
                remark: '',
            },
            {
                id: 48,
                function: 'mdb mode 2 request',
                remark: '',
            },
            {
                id: 49,
                function: 'hgb mode 1 request',
                remark: '',
            },
            {
                id: 50,
                function: 'hgb mode 2 request',
                remark: '',
            },
            {
                id: 51,
                function: 'bodybuiler display 1 request ',
                remark: '',
            },
            {
                id: 52,
                function: 'bodybuiler display 2 request ',
                remark: '',
            },
            {
                id: 53,
                function: 'bodybuiler display 3 request ',
                remark: '',
            },
            {
                id: 54,
                function: 'bodybuiler display 4 request ',
                remark: '',
            },
            {
                id: 55,
                function: 'bodybuiler display 5 request ',
                remark: '',
            },
            {
                id: 56,
                function: 'bodybuiler display 6 request ',
                remark: '',
            },
            {
                id: 57,
                function: 'bodybuiler display 7 request ',
                remark: '',
            },
            {
                id: 58,
                function: 'bodybuiler display 8 request ',
                remark: '',
            },
            {
                id: 59,
                function: 'bodybuiler display 9 request ',
                remark: '',
            },
            {
                id: 60,
                function: 'bodybuiler display 10 request ',
                remark: '',
            },
            {
                id: 61,
                function: 'exhaust bellows request',
                remark: '',
            },
            {
                id: 62,
                function: 'prohibit air suspension control request',
                remark: '',
            },
            {
                id: 63,
                function: 'haltbrake request',
                remark: '',
            },
            {
                id: 64,
                function: 'torque speed limit request 1',
                remark: '',
            },
            {
                id: 65,
                function: 'torque speed limit request 2',
                remark: '',
            },
            {
                id: 66,
                function: 'camera 1 request',
                remark: '',
            },
            {
                id: 67,
                function: 'camera 2 request',
                remark: '',
            },
            {
                id: 68,
                function: 'camera 3 request',
                remark: '',
            },
            {
                id: 69,
                function: 'camera 4 request',
                remark: '',
            },
            {
                id: 70,
                function: 'camera 5 request',
                remark: '',
            },
            {
                id: 71,
                function: 'Audio mute request',
                remark: '',
            },
        ],
    },
    adminData: {
        getAdminDataFetch: {
            purchased: '8',
            usage: {
                customers: '6',
                timerange: '3 month',
            },
            bodybuilders: [
                {
                    name: 'Palfinger',
                    country: 'Austria',
                },
                {
                    name: 'Meiller',
                    country: 'Austria',
                },
                {
                    name: 'Kögel',
                    country: 'Austria',
                },
                {
                    name: 'Schmitz',
                    country: 'Austria',
                },
                {
                    name: 'Schwarzmüller',
                    country: 'Austria',
                },
            ],
            mostused: [
                {
                    name: 'CLAA22',
                    changes: '345',
                },
                {
                    name: 'KFKA11',
                    changes: '345',
                },
                {
                    name: 'KFKA15',
                    changes: '345',
                },
                {
                    name: 'ASSPT2',
                    changes: '345',
                },
                {
                    name: 'N1EGNA',
                    changes: '345',
                },
                {
                    name: 'N1MDB1',
                    changes: '345',
                },
                {
                    name: 'HGBMAX',
                    changes: '345',
                },
                {
                    name: 'M1OM1A',
                    changes: '345',
                },
                {
                    name: 'Z1AVSA',
                    changes: '345',
                },
            ],
        },
    },
    parametrization: {
        favorites: {
            'Most Used': [
                {
                    name: 'Z1ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Abschaltbedingung Bremse',
                    changed: 37051,
                },
                {
                    name: 'Z1BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Bedienteil',
                    changed: 58184,
                },
                {
                    name: 'Z1ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Obere Drehzahlgrenze',
                    changed: 130820,
                },
                {
                    name: 'Z1OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 45198,
                },
                {
                    name: 'Z1PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Fahrpedal',
                    changed: 42161,
                },
                {
                    name: 'Z1SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Sollwert für Wiederaufnahme',
                    changed: 72000,
                },
                {
                    name: 'Z1UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Untere Drehzahlgrenze',
                    changed: 75458,
                },
                {
                    name: 'Z2BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Bedienteil',
                    changed: 18916,
                },
                {
                    name: 'Z2DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 5428,
                },
                {
                    name: 'Z2ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Obere Drehzahlgrenze',
                    changed: 36554,
                },
                {
                    name: 'Z2OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 6818,
                },
                {
                    name: 'Z2PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Fahrpedal',
                    changed: 11268,
                },
                {
                    name: 'Z2SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Sollwert für Wiederaufnahme',
                    changed: 19560,
                },
                {
                    name: 'Z2UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Untere Drehzahlgrenze',
                    changed: 21770,
                },
                {
                    name: 'Z3BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Bedienteil',
                    changed: 6640,
                },
                {
                    name: 'Z3DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 2886,
                },
                {
                    name: 'Z3ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Obere Drehzahlgrenze',
                    changed: 20602,
                },
                {
                    name: 'Z3OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 -Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 6852,
                },
                {
                    name: 'Z3PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Fahrpedal',
                    changed: 9690,
                },
                {
                    name: 'Z3SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Sollwert für Wiederaufnahme',
                    changed: 11205,
                },
                {
                    name: 'Z3UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Untere Drehzahlgrenze',
                    changed: 10798,
                },
                {
                    name: 'Z4BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Bedienteil',
                    changed: 2143,
                },
                {
                    name: 'Z4DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 1571,
                },
                {
                    name: 'Z4ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Obere Drehzahlgrenze',
                    changed: 11589,
                },
                {
                    name: 'Z4OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 3014,
                },
                {
                    name: 'Z4PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Fahrpedal',
                    changed: 3271,
                },
                {
                    name: 'Z4SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Sollwert für Wiederaufnahme',
                    changed: 5808,
                },
                {
                    name: 'Z4UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Untere Drehzahlgrenze',
                    changed: 6102,
                },
                {
                    name: 'Z5BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Bedienteil',
                    changed: 5116,
                },
                {
                    name: 'Z5DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Drehzahländerung beim Tippen SET+/SET- im ZDR',
                    changed: 806,
                },
                {
                    name: 'Z5ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Obere Drehzahlgrenze',
                    changed: 17316,
                },
                {
                    name: 'Z5OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 5718,
                },
                {
                    name: 'Z5PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Fahrpedal',
                    changed: 2609,
                },
                {
                    name: 'Z5SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Sollwert für Wiederaufnahme',
                    changed: 10416,
                },
                {
                    name: 'Z5UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Untere Drehzahlgrenze',
                    changed: 7252,
                },
                {
                    name: 'Z6BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Bedienteil',
                    changed: 1730,
                },
                {
                    name: 'Z6DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Drehzahländerung beim Tippen SET+/SET- im ZDR',
                    changed: 724,
                },
                {
                    name: 'Z6ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Obere Drehzahlgrenze',
                    changed: 13143,
                },
                {
                    name: 'Z6OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 3325,
                },
                {
                    name: 'Z6PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Fahrpedal',
                    changed: 2237,
                },
                {
                    name: 'Z6SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Sollwert für Wiederaufnahme',
                    changed: 5875,
                },
                {
                    name: 'Z6UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Untere Drehzahlgrenze',
                    changed: 8018,
                },
                {
                    name: 'ZSBEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Bedienteil',
                    changed: 3163,
                },
                {
                    name: 'ZSDSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Drehzahländerung beim Tippen SET+/SET- im ZDR',
                    changed: 2942,
                },
                {
                    name: 'ZSODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Obere Drehzahlgrenze',
                    changed: 33128,
                },
                {
                    name: 'ZSOMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 6991,
                },
                {
                    name: 'ZSPWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Fahrpedal',
                    changed: 169,
                },
                {
                    name: 'ZSSSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Sollwert für Wiederaufnahme',
                    changed: 12843,
                },
                {
                    name: 'ZSUDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Untere Drehzahlgrenze',
                    changed: 1226,
                },
                {
                    name: 'M1ODGA',
                    approval_status: 'Produktion',
                    description: 'Drehzahlbegrenzung Stufe 1',
                    changed: 36035,
                },
                {
                    name: 'M2ODGA',
                    approval_status: 'Produktion',
                    description: 'Drehzahlbegrenzung Stufe 2',
                    changed: 16584,
                },
                {
                    name: 'KDDB1A',
                    approval_status: 'Produktion',
                    description: 'Drehzahl-Begrenzung 1 über Pin',
                    changed: 15809,
                },
                {
                    name: 'KDDB2A',
                    approval_status: 'Produktion',
                    description: 'Drehzahl-Begrenzung 2 über Pin',
                    changed: 4125,
                },
                {
                    name: 'KDDB3A',
                    approval_status: 'Produktion',
                    description: 'Drehzahl-Begrenzung 3 über Pin',
                    changed: 4712,
                },
                {
                    name: 'H1VMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit HGB1 (km/h)',
                    changed: 331322,
                },
                {
                    name: 'H2VMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit HGB2 (km/h)',
                    changed: 38741,
                },
                {
                    name: 'H3VMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit HGB3 (km/h)',
                    changed: 11247,
                },
                {
                    name: 'ASSPT1',
                    approval_status: 'Produktion',
                    description:
                        'Gibt an, welche Split-Stellung beim Betrieb des Nebenabtriebs 1 vom Getriebe gefordert wird.',
                    changed: 1440,
                },
                {
                    name: 'ASSPT2',
                    approval_status: 'Produktion',
                    description:
                        'Gibt an, welche Split-Stellung beim Betrieb des Nebenabtriebs 2 vom Getriebe gefordert wird.',
                    changed: 1403,
                },
                {
                    name: 'N1ENSA',
                    approval_status: 'Produktion',
                    description: 'NA1 Einschaltschwelle Drehzahl',
                    changed: 2981,
                },
                {
                    name: 'N1MDBE',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung der Motordrehzahl',
                    changed: 9969,
                },
                {
                    name: 'N2ENSA',
                    approval_status: 'Produktion',
                    description: 'NA2 Einschaltschwelle Drehzahl',
                    changed: 1217,
                },
                {
                    name: 'N2MDBE',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung der Motordrehzahl',
                    changed: 1552,
                },
                {
                    name: 'KNADSA',
                    approval_status: 'Produktion',
                    description: 'NMV: Drehzahlschwelle bewerten zum Auslegen',
                    changed: 47,
                },
                {
                    name: 'KNAN1M',
                    approval_status: 'Produktion',
                    description: 'ZDR-Mode für ZDR-NA1 Verknüpfung',
                    changed: 1314,
                },
                {
                    name: 'KNAN2M',
                    approval_status: 'Produktion',
                    description: 'ZDR-Mode für ZDR-NA2 Verknüpfung',
                    changed: 419,
                },
                {
                    name: 'KNANNM',
                    approval_status: 'Produktion',
                    description: 'ZDR-Mode für ZDR-NA3 Verknüpfung (NMV)',
                    changed: 479,
                },
                {
                    name: 'KDIDID',
                    approval_status: 'Produktion',
                    description: 'Umstellung Triebstrang öffnen - Taster <-> Schalter',
                    changed: 306,
                },
                {
                    name: 'KHS1KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 1 mit einer bestimmten Funktionalität',
                    changed: 10814,
                },
                {
                    name: 'KHS2KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 2 mit einer bestimmten Funktionalität',
                    changed: 15742,
                },
                {
                    name: 'KHS3KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 3 mit einer bestimmten Funktionalität',
                    changed: 2810,
                },
                {
                    name: 'KHS4KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 4 mit einer bestimmten Funktionalität',
                    changed: 9639,
                },
                {
                    name: 'KHS5KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 5 mit einer bestimmten Funktionalität',
                    changed: 8891,
                },
                {
                    name: 'KLS1KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Lowside-Ausgangs 1 mit einer bestimmten Funktionalität',
                    changed: 7285,
                },
                {
                    name: 'KLS2KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Lowside-Ausgangs 2 mit einer bestimmten Funktionalität',
                    changed: 4078,
                },
                {
                    name: 'KLS3KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Lowside-Ausgangs 3 mit einer bestimmten Funktionalität',
                    changed: 3908,
                },
            ],
            // '2nd Most Used': [
            //     {
            //         name: 'Z1DSTA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 1 - Drehzahländerung beim Tippen SET+/SET-',
            //         changed: 18017
            //     },
            //     {
            //         name: 'Z1REZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 1 - Rampensteilheit bei Wiederaufnahme',
            //         changed: 14429
            //     },
            //     {
            //         name: 'Z1RSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 1 - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 11106
            //     },
            //     {
            //         name: 'Z1SHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 1 - Auswertung Handbremse',
            //         changed: 44816
            //     },
            //     {
            //         name: 'Z1SKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 1 - Auswertung Kupplung',
            //         changed: 4709
            //     },
            //     {
            //         name: 'Z2ABRA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Abschaltbedingung Bremse',
            //         changed: 9511
            //     },
            //     {
            //         name: 'Z2AVSA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Abschaltbedingung Geschw.-Schwelle',
            //         changed: 2087
            //     },
            //     {
            //         name: 'Z2REZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Rampensteilheit bei Wiederaufnahme',
            //         changed: 3204
            //     },
            //     {
            //         name: 'Z2RSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 2348
            //     },
            //     {
            //         name: 'Z2SGNA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Auswertung Getriebe-Neutral',
            //         changed: 10238
            //     },
            //     {
            //         name: 'Z2SHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Auswertung Handbremse',
            //         changed: 10699
            //     },
            //     {
            //         name: 'Z2SKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 2 - Auswertung Kupplung',
            //         changed: 1370
            //     },
            //     {
            //         name: 'Z3ABRA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 3 - Abschaltbedingung Bremse',
            //         changed: 6521
            //     },
            //     {
            //         name: 'Z3AVSA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 3 - Abschaltbedingung Geschw.-Schwelle',
            //         changed: 959
            //     },
            //     {
            //         name: 'Z3REZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 3 - Rampensteilheit bei Wiederaufnahme',
            //         changed: 2478
            //     },
            //     {
            //         name: 'Z3RSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 3 - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 1631
            //     },
            //     {
            //         name: 'Z3SHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 3 - Auswertung Handbremse',
            //         changed: 7542
            //     },
            //     {
            //         name: 'Z3SKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 3 - Auswertung Kupplung',
            //         changed: 963
            //     },
            //     {
            //         name: 'Z4ABRA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 4 - Abschaltbedingung Bremse',
            //         changed: 2891
            //     },
            //     {
            //         name: 'Z4AVSA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 4 - Abschaltbedingung Geschw.-Schwelle',
            //         changed: 529
            //     },
            //     {
            //         name: 'Z4REZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 4 - Rampensteilheit bei Wiederaufnahme',
            //         changed: 802
            //     },
            //     {
            //         name: 'Z4RSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 4 - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 513
            //     },
            //     {
            //         name: 'Z4SHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 4 - Auswertung Handbremse',
            //         changed: 3203
            //     },
            //     {
            //         name: 'Z4SKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 4 - Auswertung Kupplung',
            //         changed: 273
            //     },
            //     {
            //         name: 'Z5ABRA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 5 - Abschaltbedingung Bremse',
            //         changed: 4145
            //     },
            //     {
            //         name: 'Z5AVSA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 5 - Abschaltbedingung Geschw.-Schwelle',
            //         changed: 676
            //     },
            //     {
            //         name: 'Z5REZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 5 - Rampensteilheit bei Wiederaufnahme',
            //         changed: 1805
            //     },
            //     {
            //         name: 'Z5RSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 5 - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 889
            //     },
            //     {
            //         name: 'Z5SHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 5 - Auswertung Handbremse',
            //         changed: 3196
            //     },
            //     {
            //         name: 'Z5SKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 5 - Auswertung Kupplung',
            //         changed: 379
            //     },
            //     {
            //         name: 'Z6ABRA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 6 - Abschaltbedingung Bremse',
            //         changed: 3895
            //     },
            //     {
            //         name: 'Z6AVSA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 6 - Abschaltbedingung Geschw.-Schwelle',
            //         changed: 964
            //     },
            //     {
            //         name: 'Z6REZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 6 - Rampensteilheit bei Wiederaufnahme',
            //         changed: 990
            //     },
            //     {
            //         name: 'Z6RSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 6 - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 835
            //     },
            //     {
            //         name: 'Z6SHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 6 - Auswertung Handbremse',
            //         changed: 1840
            //     },
            //     {
            //         name: 'Z6SKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR 6 - Auswertung Kupplung',
            //         changed: 364
            //     },
            //     {
            //         name: 'ZSABRA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR S - Abschaltbedingung Bremse',
            //         changed: 4401
            //     },
            //     {
            //         name: 'ZSAVSA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR S - Abschaltbedingung Geschw.-Schwelle',
            //         changed: 1772
            //     },
            //     {
            //         name: 'ZSREZA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR S - Rampensteilheit bei Wiederaufnahme',
            //         changed: 1433
            //     },
            //     {
            //         name: 'ZSRSPA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR S - Drehzahländerung SET + , langer Tastendruck',
            //         changed: 1715
            //     },
            //     {
            //         name: 'ZSSHBA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR S - Auswertung Handbremse',
            //         changed: 5832
            //     },
            //     {
            //         name: 'ZSSKUA',
            //         approval_status: 'Produktion',
            //         description: 'ZDR S - Auswertung Kupplung',
            //         changed: 1066
            //     },
            //     {
            //         name: 'M1OM1A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 1',
            //         changed: 4460
            //     },
            //     {
            //         name: 'M1OM2A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 2',
            //         changed: 1835
            //     },
            //     {
            //         name: 'M1OM3A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 3',
            //         changed: 1500
            //     },
            //     {
            //         name: 'M1OM4A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 4',
            //         changed: 1384
            //     },
            //     {
            //         name: 'M1OM5A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 5',
            //         changed: 1384
            //     },
            //     {
            //         name: 'M2OM1A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 1',
            //         changed: 5569
            //     },
            //     {
            //         name: 'M2OM2A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 2',
            //         changed: 757
            //     },
            //     {
            //         name: 'M2OM3A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 3',
            //         changed: 532
            //     },
            //     {
            //         name: 'M2OM4A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 4',
            //         changed: 479
            //     },
            //     {
            //         name: 'M2OM5A',
            //         approval_status: 'Produktion',
            //         description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 5',
            //         changed: 458
            //     },
            //     {
            //         name: 'KDMB1A',
            //         approval_status: 'Produktion',
            //         description: 'Momenten-Begrenzung 1 über Pin',
            //         changed: 1500
            //     },
            //     {
            //         name: 'KDMB2A',
            //         approval_status: 'Produktion',
            //         description: 'Momenten-Begrenzung 2 über Pin',
            //         changed: 564
            //     },
            //     {
            //         name: 'KDMB3A',
            //         approval_status: 'Produktion',
            //         description: 'Momenten-Begrenzung 3 über Pin',
            //         changed: 829
            //     },
            //     {
            //         name: 'KFKA11',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 1. Achse für Achslastschwelle 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA12',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 2. Achse für Achslastschwelle 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA13',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 3. Achse für Achslastschwelle 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA14',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 4. Achse für Achslastschwelle 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA15',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 5. Achse für Achslastschwelle 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA1A',
            //         approval_status: 'Produktion',
            //         description: 'untere Achslastschwelle 1',
            //         changed: 130
            //     },
            //     {
            //         name: 'KFKA1E',
            //         approval_status: 'Produktion',
            //         description: 'obere Achslastschwelle 1',
            //         changed: 159
            //     },
            //     {
            //         name: 'KFKA21',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 1. Achse für Achslastschwelle 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA22',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 2. Achse für Achslastschwelle 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA23',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 3. Achse für Achslastschwelle 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA24',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 4. Achse für Achslastschwelle 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA25',
            //         approval_status: 'Produktion',
            //         description: 'Auswertung 5. Achse für Achslastschwelle 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KFKA2A',
            //         approval_status: 'Produktion',
            //         description: 'untere Achslastschwelle 2',
            //         changed: 54
            //     },
            //     {
            //         name: 'KFKA2E',
            //         approval_status: 'Produktion',
            //         description: 'obere Achslastschwelle 2',
            //         changed: 67
            //     },
            //     {
            //         name: 'KFKBSA',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die untere Bremspedalschwelle der konfigurierbaren Ausgänge - Ausschaltschwelle',
            //         changed: 609
            //     },
            //     {
            //         name: 'KFKBSE',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die obere Bremspedalschwelle der konfigurierbaren Ausgänge - Einschaltschwelle',
            //         changed: 800
            //     },
            //     {
            //         name: 'KFKD1E',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die Motordrehzahlschwelle 1 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
            //         changed: 1642
            //     },
            //     {
            //         name: 'KFKD1O',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die obere Motordrehzahlschwelle 1 der konfigurierbaren Ausgänge',
            //         changed: 6689
            //     },
            //     {
            //         name: 'KFKD1U',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die untere Motordrehzahlschwelle 1 der konfigurierbaren Ausgänge',
            //         changed: 6235
            //     },
            //     {
            //         name: 'KFKD2E',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die Motordrehzahlschwelle 2 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
            //         changed: 499
            //     },
            //     {
            //         name: 'KFKD2O',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die obere Motordrehzahlschwelle 2 der konfigurierbaren Ausgänge',
            //         changed: 1223
            //     },
            //     {
            //         name: 'KFKD2U',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die untere Motordrehzahlschwelle 2 der konfigurierbaren Ausgänge',
            //         changed: 1159
            //     },
            //     {
            //         name: 'KFKFSA',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die untere Fahrpedalschwelle der konfigurierbaren Ausgänge - Ausschaltschwelle',
            //         changed: 599
            //     },
            //     {
            //         name: 'KFKFSE',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die obere Fahrpedalschwelle der konfigurierbaren Ausgänge - Einschaltschwelle',
            //         changed: 979
            //     },
            //     {
            //         name: 'KFKG1E',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die Geschwindigkeitsschwelle 1 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
            //         changed: 2024
            //     },
            //     {
            //         name: 'KFKG1O',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die obere Geschwindigkeitsschwelle 1 der konfigurierbaren Ausgänge',
            //         changed: 14223
            //     },
            //     {
            //         name: 'KFKG1U',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die untere Geschwindigkeitsschwelle 1 der konfigurierbaren Ausgänge',
            //         changed: 14048
            //     },
            //     {
            //         name: 'KFKG2E',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die Geschwindigkeitsschwelle 2 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
            //         changed: 597
            //     },
            //     {
            //         name: 'KFKG2O',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die obere Geschwindigkeitsschwelle 1 der konfigurierbaren Ausgänge',
            //         changed: 1646
            //     },
            //     {
            //         name: 'KFKG2U',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die untere Geschwindigkeitsschwelle 2 der konfigurierbaren Ausgänge',
            //         changed: 1569
            //     },
            //     {
            //         name: 'KFKSPL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter für die Splitstellung zur Aktivierung der konfigurierbaren Ausgänge',
            //         changed: 445
            //     },
            //     {
            //         name: 'KHS1HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren HS1 (high side 1) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS1KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS2HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren HS2 (high side 2) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS2KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS3HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren HS3 (high side 3) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS3KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 3',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS4HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren HS4 (high side 4) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS4KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 4',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS5HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren HS5 (high side 5) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KHS5KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 5',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KLS1HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren LS1 (low side 1) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KLS1KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Lowside-Ausgangs 1',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KLS2HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren LS2 (low side 2) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KLS2KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Lowside-Ausgangs 2',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KLS3HZ',
            //         approval_status: 'Produktion',
            //         description: 'einstellbare Haltezeit des konfigurierbaren LS3 (low side 3) nach Deaktivierung',
            //         changed: '-'
            //     },
            //     {
            //         name: 'KLS3KL',
            //         approval_status: 'Produktion',
            //         description: 'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Lowside-Ausgangs 3',
            //         changed: '-'
            //     }
            // ]
        },
        categories: {
            Zwischendrehzahl: [
                {
                    name: 'Z1ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Abschaltbedingung Bremse',
                    changed: 37051,
                },
                {
                    name: 'Z1AKFL',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Definition der Aktivierungsflanke',
                    changed: '-',
                },
                {
                    name: 'Z1AVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Abschaltbedingung Geschw.-Schwelle',
                    changed: 11513,
                },
                {
                    name: 'Z1AVSE',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - obere Geschwindigkeitsgrenze bis zu der der ZDR eingeschaltet werden kann',
                    changed: '-',
                },
                {
                    name: 'Z1BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Bedienteil',
                    changed: 58184,
                },
                {
                    name: 'Z1BPWN',
                    approval_status: 'Produktion',
                    description:
                        'Mit diesem Parametervektor werden die Bremspedalschwellen zur Abschaltung der Zwischendrehzahl für die einzelnen ZDR- Modi festgelegt.',
                    changed: '-',
                },
                {
                    name: 'Z1DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 18017,
                },
                {
                    name: 'Z1FREA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Freigabe des Modes',
                    changed: 0,
                },
                {
                    name: 'Z1HGBD',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Höchstgeschwindigkeit Umsetzung der Funktion',
                    changed: 192,
                },
                {
                    name: 'Z1HGBS',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Höchstgeschwindigkeit für den Schubbetrieb',
                    changed: 195,
                },
                {
                    name: 'Z1HGBZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Höchstgeschwindigkeit für den Zugbetrieb',
                    changed: 196,
                },
                {
                    name: 'Z1ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Obere Drehzahlgrenze',
                    changed: 130820,
                },
                {
                    name: 'Z1OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 45198,
                },
                {
                    name: 'Z1PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Fahrpedal',
                    changed: 42161,
                },
                {
                    name: 'Z1REZA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Rampensteilheit bei Wiederaufnahme',
                    changed: 14429,
                },
                {
                    name: 'Z1RSDG',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Drehzahllimit im Kehrmaschinen - Limitierungsfall',
                    changed: '-',
                },
                {
                    name: 'Z1RSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Drehzahländerung SET + , langer Tastendruck',
                    changed: 11106,
                },
                {
                    name: 'Z1SGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Auswertung Getriebe-Neutral',
                    changed: 39696,
                },
                {
                    name: 'Z1SHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Auswertung Handbremse',
                    changed: 44816,
                },
                {
                    name: 'Z1SKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Auswertung Kupplung',
                    changed: 4709,
                },
                {
                    name: 'Z1SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Sollwert für Wiederaufnahme',
                    changed: 72000,
                },
                {
                    name: 'Z1UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 1 - Untere Drehzahlgrenze',
                    changed: 75458,
                },
                {
                    name: 'Z2ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Abschaltbedingung Bremse',
                    changed: 9511,
                },
                {
                    name: 'Z2AKFL',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Definition der Aktivierungsflanke',
                    changed: '-',
                },
                {
                    name: 'Z2AVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Abschaltbedingung Geschw.-Schwelle',
                    changed: 2087,
                },
                {
                    name: 'Z2AVSE',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - obere Geschwindigkeitsgrenze bis zu der der ZDR eingeschaltet werden kann',
                    changed: '-',
                },
                {
                    name: 'Z2BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Bedienteil',
                    changed: 18916,
                },
                {
                    name: 'Z2BPWN',
                    approval_status: 'Produktion',
                    description:
                        'Mit diesem Parametervektor werden die Bremspedalschwellen zur Abschaltung der Zwischendrehzahl für die einzelnen ZDR- Modi festgelegt.',
                    changed: '-',
                },
                {
                    name: 'Z2DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 5428,
                },
                {
                    name: 'Z2FREA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Freigabe des Modes',
                    changed: 7,
                },
                {
                    name: 'Z2HGBD',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Höchstgeschwindigkeit Umsetzung der Funktion',
                    changed: '-',
                },
                {
                    name: 'Z2HGBS',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Höchstgeschwindigkeit für den Schubbetrieb',
                    changed: 0,
                },
                {
                    name: 'Z2HGBZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Höchstgeschwindigkeit für den Zugbetrieb',
                    changed: 0,
                },
                {
                    name: 'Z2ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Obere Drehzahlgrenze',
                    changed: 36554,
                },
                {
                    name: 'Z2OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 6818,
                },
                {
                    name: 'Z2PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Fahrpedal',
                    changed: 11268,
                },
                {
                    name: 'Z2REZA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Rampensteilheit bei Wiederaufnahme',
                    changed: 3204,
                },
                {
                    name: 'Z2RSDG',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Drehzahllimit im Kehrmaschinen - Limitierungsfall',
                    changed: '-',
                },
                {
                    name: 'Z2RSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Drehzahländerung SET + , langer Tastendruck',
                    changed: 2348,
                },
                {
                    name: 'Z2SGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Auswertung Getriebe-Neutral',
                    changed: 10238,
                },
                {
                    name: 'Z2SHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Auswertung Handbremse',
                    changed: 10699,
                },
                {
                    name: 'Z2SKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Auswertung Kupplung',
                    changed: 1370,
                },
                {
                    name: 'Z2SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Sollwert für Wiederaufnahme',
                    changed: 19560,
                },
                {
                    name: 'Z2UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 2 - Untere Drehzahlgrenze',
                    changed: 21770,
                },
                {
                    name: 'Z3ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Abschaltbedingung Bremse',
                    changed: 6521,
                },
                {
                    name: 'Z3AKFL',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Definition der Aktivierungsflanke',
                    changed: '-',
                },
                {
                    name: 'Z3AVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Abschaltbedingung Geschw.-Schwelle',
                    changed: 959,
                },
                {
                    name: 'Z3AVSE',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - obere Geschwindigkeitsgrenze bis zu der der ZDR eingeschaltet werden kann',
                    changed: '-',
                },
                {
                    name: 'Z3BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Bedienteil',
                    changed: 6640,
                },
                {
                    name: 'Z3DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 2886,
                },
                {
                    name: 'Z3FREA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Freigabe des Modes',
                    changed: 0,
                },
                {
                    name: 'Z3HGBD',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Höchstgeschwindigkeit Umsetzung der Funktion',
                    changed: '-',
                },
                {
                    name: 'Z3HGBS',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Höchstgeschwindigkeit für den Schubbetrieb',
                    changed: 0,
                },
                {
                    name: 'Z3HGBZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Höchstgeschwindigkeit für den Zugbetrieb',
                    changed: 0,
                },
                {
                    name: 'Z3ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Obere Drehzahlgrenze',
                    changed: 20602,
                },
                {
                    name: 'Z3OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 -Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 6852,
                },
                {
                    name: 'Z3PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Fahrpedal',
                    changed: 9690,
                },
                {
                    name: 'Z3REZA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Rampensteilheit bei Wiederaufnahme',
                    changed: 2478,
                },
                {
                    name: 'Z3RSDG',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Drehzahllimit im Kehrmaschinen - Limitierungsfall',
                    changed: '-',
                },
                {
                    name: 'Z3RSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Drehzahländerung SET + , langer Tastendruck',
                    changed: 1631,
                },
                {
                    name: 'Z3SGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Auswertung Getriebe-Neutral',
                    changed: 6362,
                },
                {
                    name: 'Z3SHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Auswertung Handbremse',
                    changed: 7542,
                },
                {
                    name: 'Z3SKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Auswertung Kupplung',
                    changed: 963,
                },
                {
                    name: 'Z3SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Sollwert für Wiederaufnahme',
                    changed: 11205,
                },
                {
                    name: 'Z3UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 3 - Untere Drehzahlgrenze',
                    changed: 10798,
                },
                {
                    name: 'Z4ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Abschaltbedingung Bremse',
                    changed: 2891,
                },
                {
                    name: 'Z4AVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Abschaltbedingung Geschw.-Schwelle',
                    changed: 529,
                },
                {
                    name: 'Z4BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Bedienteil',
                    changed: 2143,
                },
                {
                    name: 'Z4DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Drehzahländerung beim Tippen SET+/SET-',
                    changed: 1571,
                },
                {
                    name: 'Z4FREA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Freigabe des Modes',
                    changed: 23,
                },
                {
                    name: 'Z4ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Obere Drehzahlgrenze',
                    changed: 11589,
                },
                {
                    name: 'Z4OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 3014,
                },
                {
                    name: 'Z4PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Fahrpedal',
                    changed: 3271,
                },
                {
                    name: 'Z4REZA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Rampensteilheit bei Wiederaufnahme',
                    changed: 802,
                },
                {
                    name: 'Z4RSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Drehzahländerung SET + , langer Tastendruck',
                    changed: 513,
                },
                {
                    name: 'Z4SGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Auswertung Getriebe-Neutral',
                    changed: 3291,
                },
                {
                    name: 'Z4SHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Auswertung Handbremse',
                    changed: 3203,
                },
                {
                    name: 'Z4SKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Auswertung Kupplung',
                    changed: 273,
                },
                {
                    name: 'Z4SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Sollwert für Wiederaufnahme',
                    changed: 5808,
                },
                {
                    name: 'Z4UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Untere Drehzahlgrenze',
                    changed: 6102,
                },
                {
                    name: 'Z5ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Abschaltbedingung Bremse',
                    changed: 4145,
                },
                {
                    name: 'Z5AVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Abschaltbedingung Geschw.-Schwelle',
                    changed: 676,
                },
                {
                    name: 'Z5BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Bedienteil',
                    changed: 5116,
                },
                {
                    name: 'Z5DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Drehzahländerung beim Tippen SET+/SET- im ZDR',
                    changed: 806,
                },
                {
                    name: 'Z5FREA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Freigabe des Modes',
                    changed: 0,
                },
                {
                    name: 'Z5ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Obere Drehzahlgrenze',
                    changed: 17316,
                },
                {
                    name: 'Z5OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 5718,
                },
                {
                    name: 'Z5PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Fahrpedal',
                    changed: 2609,
                },
                {
                    name: 'Z5REZA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Rampensteilheit bei Wiederaufnahme',
                    changed: 1805,
                },
                {
                    name: 'Z5RSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Drehzahländerung SET + , langer Tastendruck',
                    changed: 889,
                },
                {
                    name: 'Z5SGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Auswertung Getriebe-Neutral',
                    changed: 2932,
                },
                {
                    name: 'Z5SHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Auswertung Handbremse',
                    changed: 3196,
                },
                {
                    name: 'Z5SKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Auswertung Kupplung',
                    changed: 379,
                },
                {
                    name: 'Z5SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Sollwert für Wiederaufnahme',
                    changed: 10416,
                },
                {
                    name: 'Z5UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 5 - Untere Drehzahlgrenze',
                    changed: 7252,
                },
                {
                    name: 'Z6ABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Abschaltbedingung Bremse',
                    changed: 3895,
                },
                {
                    name: 'Z6AVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Abschaltbedingung Geschw.-Schwelle',
                    changed: 964,
                },
                {
                    name: 'Z6BEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Bedienteil',
                    changed: 1730,
                },
                {
                    name: 'Z6DSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR 4 - Drehzahländerung beim Tippen SET+/SET- im ZDR',
                    changed: 724,
                },
                {
                    name: 'Z6FREA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Freigabe des Modes',
                    changed: 23,
                },
                {
                    name: 'Z6ODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Obere Drehzahlgrenze',
                    changed: 13143,
                },
                {
                    name: 'Z6OMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 3325,
                },
                {
                    name: 'Z6PWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Fahrpedal',
                    changed: 2237,
                },
                {
                    name: 'Z6REZA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Rampensteilheit bei Wiederaufnahme',
                    changed: 990,
                },
                {
                    name: 'Z6RSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Drehzahländerung SET + , langer Tastendruck',
                    changed: 835,
                },
                {
                    name: 'Z6SGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Auswertung Getriebe-Neutral',
                    changed: 2685,
                },
                {
                    name: 'Z6SHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Auswertung Handbremse',
                    changed: 1840,
                },
                {
                    name: 'Z6SKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Auswertung Kupplung',
                    changed: 364,
                },
                {
                    name: 'Z6SSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Sollwert für Wiederaufnahme',
                    changed: 5875,
                },
                {
                    name: 'Z6UDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR 6 - Untere Drehzahlgrenze',
                    changed: 8018,
                },
                {
                    name: 'ZSABRA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Abschaltbedingung Bremse',
                    changed: 4401,
                },
                {
                    name: 'ZSAVSA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Abschaltbedingung Geschw.-Schwelle',
                    changed: 1772,
                },
                {
                    name: 'ZSBEDA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Bedienteil',
                    changed: 3163,
                },
                {
                    name: 'ZSDSTA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Drehzahländerung beim Tippen SET+/SET- im ZDR',
                    changed: 2942,
                },
                {
                    name: 'ZSFREA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Freigabe des Modes',
                    changed: 0,
                },
                {
                    name: 'ZSODGA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Obere Drehzahlgrenze',
                    changed: 33128,
                },
                {
                    name: 'ZSOMSZ',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Gespeicherte ZDR-Drehzahl über MEM-Taste',
                    changed: 6991,
                },
                {
                    name: 'ZSPWGA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Fahrpedal',
                    changed: 169,
                },
                {
                    name: 'ZSREZA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Rampensteilheit bei Wiederaufnahme',
                    changed: 1433,
                },
                {
                    name: 'ZSRSPA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Drehzahländerung SET + , langer Tastendruck',
                    changed: 1715,
                },
                {
                    name: 'ZSSGNA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Auswertung Getriebe-Neutral',
                    changed: 5986,
                },
                {
                    name: 'ZSSHBA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Auswertung Handbremse',
                    changed: 5832,
                },
                {
                    name: 'ZSSKUA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Auswertung Kupplung',
                    changed: 1066,
                },
                {
                    name: 'ZSSSA0',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Sollwert für Wiederaufnahme',
                    changed: 12843,
                },
                {
                    name: 'ZSUDGA',
                    approval_status: 'Produktion',
                    description: 'ZDR S - Untere Drehzahlgrenze',
                    changed: 1226,
                },
            ],
            'Momenten-Drehzahl-Begrenzung': [
                {
                    name: 'M1ODGA',
                    approval_status: 'Produktion',
                    description: 'Drehzahlbegrenzung Stufe 1',
                    changed: 36035,
                },
                {
                    name: 'M1OM1A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 1',
                    changed: 4460,
                },
                {
                    name: 'M1OM2A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 2',
                    changed: 1835,
                },
                {
                    name: 'M1OM3A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 3',
                    changed: 1500,
                },
                {
                    name: 'M1OM4A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 4',
                    changed: 1384,
                },
                {
                    name: 'M1OM5A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 5',
                    changed: 1384,
                },
                {
                    name: 'M1ON1A',
                    approval_status: {
                        formula: 'SUM(1,2)',
                        result: 3,
                    },
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 1 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M1ON2A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 2 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M1ON3A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 3 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M1ON4A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 4 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M1ON5A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 1, Stützstelle 5 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M2ODGA',
                    approval_status: 'Produktion',
                    description: 'Drehzahlbegrenzung Stufe 2',
                    changed: 16584,
                },
                {
                    name: 'M2OM1A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 1',
                    changed: 5569,
                },
                {
                    name: 'M2OM2A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 2',
                    changed: 757,
                },
                {
                    name: 'M2OM3A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 3',
                    changed: 532,
                },
                {
                    name: 'M2OM4A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 4',
                    changed: 479,
                },
                {
                    name: 'M2OM5A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 5',
                    changed: 458,
                },
                {
                    name: 'M2ON1A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 1 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M2ON2A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 2 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M2ON3A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 3 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M2ON4A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 4 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'M2ON5A',
                    approval_status: 'Produktion',
                    description: 'Momentenbegrenzung Kennlinie für MDB Stufe 2, Stützstelle 5 - Drehzahl (x-Achse)',
                    changed: 0,
                },
                {
                    name: 'KDDB1A',
                    approval_status: 'Produktion',
                    description: 'Drehzahl-Begrenzung 1 über Pin',
                    changed: 15809,
                },
                {
                    name: 'KDDB2A',
                    approval_status: 'Produktion',
                    description: 'Drehzahl-Begrenzung 2 über Pin',
                    changed: 4125,
                },
                {
                    name: 'KDDB3A',
                    approval_status: 'Produktion',
                    description: 'Drehzahl-Begrenzung 3 über Pin',
                    changed: 4712,
                },
                {
                    name: 'KDMB1A',
                    approval_status: 'Produktion',
                    description: 'Momenten-Begrenzung 1 über Pin',
                    changed: 1500,
                },
                {
                    name: 'KDMB2A',
                    approval_status: 'Produktion',
                    description: 'Momenten-Begrenzung 2 über Pin',
                    changed: 564,
                },
                {
                    name: 'KDMB3A',
                    approval_status: 'Produktion',
                    description: 'Momenten-Begrenzung 3 über Pin',
                    changed: 829,
                },
            ],
            'HGB-Höchstgeschwindigkeitsbegre': [
                {
                    name: 'H1VMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit HGB1 (km/h)',
                    changed: 331322,
                },
                {
                    name: 'H2VMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit HGB2 (km/h)',
                    changed: 38741,
                },
                {
                    name: 'H3VMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit HGB3 (km/h)',
                    changed: 11247,
                },
                {
                    name: 'HGBMAX',
                    approval_status: 'Produktion',
                    description: 'Höchster einstellbarer Wert für die HGB (Höchstgeschwindigkeitsbegrenzung)',
                    changed: 205,
                },
                {
                    name: 'HRVMAA',
                    approval_status: 'Produktion',
                    description: 'Höchstgeschwindigkeit Rückwärtsgang (km/h)',
                    changed: 103478,
                },
            ],
            Nebenabtrieb: [
                {
                    name: 'ASSPT1',
                    approval_status: 'Produktion',
                    description:
                        'Gibt an, welche Split-Stellung beim Betrieb des Nebenabtriebs 1 vom Getriebe gefordert wird.',
                    changed: 1440,
                },
                {
                    name: 'ASSPT2',
                    approval_status: 'Produktion',
                    description:
                        'Gibt an, welche Split-Stellung beim Betrieb des Nebenabtriebs 2 vom Getriebe gefordert wird.',
                    changed: 1403,
                },
                {
                    name: 'N1EFSA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Fzg.stillstand bei Einschalten NA1',
                    changed: 312,
                },
                {
                    name: 'N1EGNA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Getriebe Neutral bei Einschalten NA1',
                    changed: 375,
                },
                {
                    name: 'N1EHBA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Handbremse bei Einschalten NA1',
                    changed: 26,
                },
                {
                    name: 'N1EKUA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Kupplung bei Einschalten NA1',
                    changed: 518,
                },
                {
                    name: 'N1ENSA',
                    approval_status: 'Produktion',
                    description: 'NA1 Einschaltschwelle Drehzahl',
                    changed: 2981,
                },
                {
                    name: 'N1MDB1',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 600 U/min',
                    changed: 89,
                },
                {
                    name: 'N1MDB2',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 750 U/min',
                    changed: 103,
                },
                {
                    name: 'N1MDB3',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 1000 U/min',
                    changed: 108,
                },
                {
                    name: 'N1MDB4',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 1250 U/min',
                    changed: 101,
                },
                {
                    name: 'N1MDB5',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 1500 U/min',
                    changed: 93,
                },
                {
                    name: 'N1MDB6',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 1750 U/min',
                    changed: 97,
                },
                {
                    name: 'N1MDB7',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung des Motormomentes bei 2000 U/min',
                    changed: 97,
                },
                {
                    name: 'N1MDBE',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 1: Begrenzung der Motordrehzahl',
                    changed: 9969,
                },
                {
                    name: 'N1SWTY',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Umschaltung von Taster auf Schalter Logik bei der Anforderung des Nebenabtrieb 1',
                    changed: '-',
                },
                {
                    name: 'N2EFSA ',
                    approval_status: 'Produktion',
                    description: 'Auswerten Fzg.stillstand bei Einschalten NA2',
                    changed: 216,
                },
                {
                    name: 'N2EGNA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Getriebe Neutral bei Einschalten NA2',
                    changed: 218,
                },
                {
                    name: 'N2EHBA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Handbremse bei Einschalten NA2',
                    changed: 7,
                },
                {
                    name: 'N2EKUA',
                    approval_status: 'Produktion',
                    description: 'Auswerten Kupplung bei Einschalten NA2',
                    changed: 254,
                },
                {
                    name: 'N2ENSA',
                    approval_status: 'Produktion',
                    description: 'NA2 Einschaltschwelle Drehzahl',
                    changed: 1217,
                },
                {
                    name: 'N2MDB1',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 600 U/min',
                    changed: 20,
                },
                {
                    name: 'N2MDB2',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 750 U/min',
                    changed: 18,
                },
                {
                    name: 'N2MDB3',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 1000 U/min',
                    changed: 29,
                },
                {
                    name: 'N2MDB4',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 1250 U/min',
                    changed: 24,
                },
                {
                    name: 'N2MDB5',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 1500 U/min',
                    changed: 26,
                },
                {
                    name: 'N2MDB6',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 1750 U/min',
                    changed: 26,
                },
                {
                    name: 'N2MDB7',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung des Motormomentes bei 2000 U/min',
                    changed: 24,
                },
                {
                    name: 'N2MDBE',
                    approval_status: 'Produktion',
                    description: 'Nebenabtrieb 2: Begrenzung der Motordrehzahl',
                    changed: 1552,
                },
                {
                    name: 'N2SWTY',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Umschaltung von Taster auf Schalter Logik bei der Anforderung des Nebenabtrieb 2',
                    changed: '-',
                },
                {
                    name: 'KNADSA',
                    approval_status: 'Produktion',
                    description: 'NMV: Drehzahlschwelle bewerten zum Auslegen',
                    changed: 47,
                },
                {
                    name: 'KNADSE',
                    approval_status: 'Produktion',
                    description: 'NMV: Drehzahlschwelle bewerten zum Einlegen',
                    changed: '-',
                },
                {
                    name: 'KNADSO',
                    approval_status: 'Produktion',
                    description: 'NMV: Drehzahlschwelle ab der eingeschaltet wird',
                    changed: 1788,
                },
                {
                    name: 'KNADSU',
                    approval_status: 'Produktion',
                    description: 'NMV: Drehzahlschwelle ab der ausgeschaltet wird',
                    changed: 4,
                },
                {
                    name: 'KNAN1B',
                    approval_status: 'Produktion',
                    description: 'NA1 Status für ZDR-NA1 Verknüpfung',
                    changed: 5440,
                },
                {
                    name: 'KNAN1M',
                    approval_status: 'Produktion',
                    description: 'ZDR-Mode für ZDR-NA1 Verknüpfung',
                    changed: 1314,
                },
                {
                    name: 'KNAN2B',
                    approval_status: 'Produktion',
                    description: 'NA2 Status für ZDR-NA2 Verknüpfung',
                    changed: 512,
                },
                {
                    name: 'KNAN2M',
                    approval_status: 'Produktion',
                    description: 'ZDR-Mode für ZDR-NA2 Verknüpfung',
                    changed: 419,
                },
                {
                    name: 'KNANNB',
                    approval_status: 'Produktion',
                    description: 'NA3 Status für ZDR-NA3 Verknüpfung (NMV)',
                    changed: 467,
                },
                {
                    name: 'KNANNM',
                    approval_status: 'Produktion',
                    description: 'ZDR-Mode für ZDR-NA3 Verknüpfung (NMV)',
                    changed: 479,
                },
            ],
            Aufbauschnittstelle: [
                {
                    name: 'KDIDID',
                    approval_status: 'Produktion',
                    description: 'Umstellung Triebstrang öffnen - Taster <-> Schalter',
                    changed: 306,
                },
                {
                    name: 'KFKA11',
                    approval_status: 'Produktion',
                    description: 'Auswertung 1. Achse für Achslastschwelle 1',
                    changed: '-',
                },
                {
                    name: 'KFKA12',
                    approval_status: 'Produktion',
                    description: 'Auswertung 2. Achse für Achslastschwelle 1',
                    changed: '-',
                },
                {
                    name: 'KFKA13',
                    approval_status: 'Produktion',
                    description: 'Auswertung 3. Achse für Achslastschwelle 1',
                    changed: '-',
                },
                {
                    name: 'KFKA14',
                    approval_status: 'Produktion',
                    description: 'Auswertung 4. Achse für Achslastschwelle 1',
                    changed: '-',
                },
                {
                    name: 'KFKA15',
                    approval_status: 'Produktion',
                    description: 'Auswertung 5. Achse für Achslastschwelle 1',
                    changed: '-',
                },
                {
                    name: 'KFKA1A',
                    approval_status: 'Produktion',
                    description: 'untere Achslastschwelle 1',
                    changed: 130,
                },
                {
                    name: 'KFKA1E',
                    approval_status: 'Produktion',
                    description: 'obere Achslastschwelle 1',
                    changed: 159,
                },
                {
                    name: 'KFKA21',
                    approval_status: 'Produktion',
                    description: 'Auswertung 1. Achse für Achslastschwelle 2',
                    changed: '-',
                },
                {
                    name: 'KFKA22',
                    approval_status: 'Produktion',
                    description: 'Auswertung 2. Achse für Achslastschwelle 2',
                    changed: '-',
                },
                {
                    name: 'KFKA23',
                    approval_status: 'Produktion',
                    description: 'Auswertung 3. Achse für Achslastschwelle 2',
                    changed: '-',
                },
                {
                    name: 'KFKA24',
                    approval_status: 'Produktion',
                    description: 'Auswertung 4. Achse für Achslastschwelle 2',
                    changed: '-',
                },
                {
                    name: 'KFKA25',
                    approval_status: 'Produktion',
                    description: 'Auswertung 5. Achse für Achslastschwelle 2',
                    changed: '-',
                },
                {
                    name: 'KFKA2A',
                    approval_status: 'Produktion',
                    description: 'untere Achslastschwelle 2',
                    changed: 54,
                },
                {
                    name: 'KFKA2E',
                    approval_status: 'Produktion',
                    description: 'obere Achslastschwelle 2',
                    changed: 67,
                },
                {
                    name: 'KFKBSA',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die untere Bremspedalschwelle der konfigurierbaren Ausgänge - Ausschaltschwelle',
                    changed: 609,
                },
                {
                    name: 'KFKBSE',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die obere Bremspedalschwelle der konfigurierbaren Ausgänge - Einschaltschwelle',
                    changed: 800,
                },
                {
                    name: 'KFKD1E',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die Motordrehzahlschwelle 1 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
                    changed: 1642,
                },
                {
                    name: 'KFKD1O',
                    approval_status: 'Produktion',
                    description: 'Parameter für die obere Motordrehzahlschwelle 1 der konfigurierbaren Ausgänge',
                    changed: 6689,
                },
                {
                    name: 'KFKD1U',
                    approval_status: 'Produktion',
                    description: 'Parameter für die untere Motordrehzahlschwelle 1 der konfigurierbaren Ausgänge',
                    changed: 6235,
                },
                {
                    name: 'KFKD2E',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die Motordrehzahlschwelle 2 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
                    changed: 499,
                },
                {
                    name: 'KFKD2O',
                    approval_status: 'Produktion',
                    description: 'Parameter für die obere Motordrehzahlschwelle 2 der konfigurierbaren Ausgänge',
                    changed: 1223,
                },
                {
                    name: 'KFKD2U',
                    approval_status: 'Produktion',
                    description: 'Parameter für die untere Motordrehzahlschwelle 2 der konfigurierbaren Ausgänge',
                    changed: 1159,
                },
                {
                    name: 'KFKFSA',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die untere Fahrpedalschwelle der konfigurierbaren Ausgänge - Ausschaltschwelle',
                    changed: 599,
                },
                {
                    name: 'KFKFSE',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die obere Fahrpedalschwelle der konfigurierbaren Ausgänge - Einschaltschwelle',
                    changed: 979,
                },
                {
                    name: 'KFKG1E',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die Geschwindigkeitsschwelle 1 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
                    changed: 2024,
                },
                {
                    name: 'KFKG1O',
                    approval_status: 'Produktion',
                    description: 'Parameter für die obere Geschwindigkeitsschwelle 1 der konfigurierbaren Ausgänge',
                    changed: 14223,
                },
                {
                    name: 'KFKG1U',
                    approval_status: 'Produktion',
                    description: 'Parameter für die untere Geschwindigkeitsschwelle 1 der konfigurierbaren Ausgänge',
                    changed: 14048,
                },
                {
                    name: 'KFKG2E',
                    approval_status: 'Produktion',
                    description:
                        'Parameter für die Geschwindigkeitsschwelle 2 zur Aktivierung der konfigurierbaren Ausgänge - Einschaltschwelle',
                    changed: 597,
                },
                {
                    name: 'KFKG2O',
                    approval_status: 'Produktion',
                    description: 'Parameter für die obere Geschwindigkeitsschwelle 1 der konfigurierbaren Ausgänge',
                    changed: 1646,
                },
                {
                    name: 'KFKG2U',
                    approval_status: 'Produktion',
                    description: 'Parameter für die untere Geschwindigkeitsschwelle 2 der konfigurierbaren Ausgänge',
                    changed: 1569,
                },
                {
                    name: 'KFKSPL',
                    approval_status: 'Produktion',
                    description: 'Parameter für die Splitstellung zur Aktivierung der konfigurierbaren Ausgänge',
                    changed: 445,
                },
                {
                    name: 'KHS1HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren HS1 (high side 1) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KHS1KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 1',
                    changed: '-',
                },
                {
                    name: 'KHS1KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 1 mit einer bestimmten Funktionalität',
                    changed: 10814,
                },
                {
                    name: 'KHS2HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren HS2 (high side 2) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KHS2KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 2',
                    changed: '-',
                },
                {
                    name: 'KHS2KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 2 mit einer bestimmten Funktionalität',
                    changed: 15742,
                },
                {
                    name: 'KHS3HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren HS3 (high side 3) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KHS3KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 3',
                    changed: '-',
                },
                {
                    name: 'KHS3KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 3 mit einer bestimmten Funktionalität',
                    changed: 2810,
                },
                {
                    name: 'KHS4HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren HS4 (high side 4) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KHS4KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 4',
                    changed: '-',
                },
                {
                    name: 'KHS4KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 4 mit einer bestimmten Funktionalität',
                    changed: 9639,
                },
                {
                    name: 'KHS5HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren HS5 (high side 5) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KHS5KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Highside-Ausgangs 5',
                    changed: '-',
                },
                {
                    name: 'KHS5KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Highside-Ausgangs 5 mit einer bestimmten Funktionalität',
                    changed: 8891,
                },
                {
                    name: 'KLS1HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren LS1 (low side 1) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KLS1KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Lowside-Ausgangs 1',
                    changed: '-',
                },
                {
                    name: 'KLS1KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Lowside-Ausgangs 1 mit einer bestimmten Funktionalität',
                    changed: 7285,
                },
                {
                    name: 'KLS2HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren LS2 (low side 2) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KLS2KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Lowside-Ausgangs 2',
                    changed: '-',
                },
                {
                    name: 'KLS2KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Lowside-Ausgangs 2 mit einer bestimmten Funktionalität',
                    changed: 4078,
                },
                {
                    name: 'KLS3HZ',
                    approval_status: 'Produktion',
                    description: 'einstellbare Haltezeit des konfigurierbaren LS3 (low side 3) nach Deaktivierung',
                    changed: '-',
                },
                {
                    name: 'KLS3KL',
                    approval_status: 'Produktion',
                    description:
                        'Parameter zur Aktivierung/Deaktivierung des Kontrollleuchtentests des Lowside-Ausgangs 3',
                    changed: '-',
                },
                {
                    name: 'KLS3KO',
                    approval_status: 'Produktion',
                    description: 'Parameter zur Belegung des Lowside-Ausgangs 3 mit einer bestimmten Funktionalität',
                    changed: 3908,
                },
            ],
            'konfigurierbare Logik': [
                {
                    name: 'CLAA11',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des AND im Level A',
                },
                {
                    name: 'CLAA12',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des AND im Level A',
                },
                {
                    name: 'CLAA21',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 2.AND im Level A',
                },
                {
                    name: 'CLAA22',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 2.AND im Level A',
                },
                {
                    name: 'CLAA31',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 3.AND im Level A',
                },
                {
                    name: 'CLAA32',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 3.AND im Level A',
                },
                {
                    name: 'CLAA41',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 4.AND im Level A',
                },
                {
                    name: 'CLAA42',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 4.AND im Level A',
                },
                {
                    name: 'CLAD10',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang der Verzögerung im Level A',
                },
                {
                    name: 'CLAD20',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 2.Verzögerung im Level A',
                },
                {
                    name: 'CLAD30',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 3.Verzögerung im Level A',
                },
                {
                    name: 'CLAD40',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 4.Verzögerung im Level A',
                },
                {
                    name: 'CLAF1R',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang RESET des FLIPFLOP im Level A',
                },
                {
                    name: 'CLAF1S',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang SET des FLIFLOP im Level A',
                },
                {
                    name: 'CLAF2R',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang RESET des 2.FLIPFLOP im Level A',
                },
                {
                    name: 'CLAF2S',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang SET des 2.FLIPFLOP im Level A',
                },
                {
                    name: 'CLAF3R',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang RESET des 3.FLIPFLOP im Level A',
                },
                {
                    name: 'CLAF3S',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang SET des 3.FLIPFLOP im Level A',
                },
                {
                    name: 'CLAF4R',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang RESET des 4. FLIPFLOP im Level A',
                },
                {
                    name: 'CLAF4S',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang SET des 4. FLIPFLOP im Level A',
                },
                {
                    name: 'CLAN10',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des NOT im Level A',
                },
                {
                    name: 'CLAO11',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des OR im Level A',
                },
                {
                    name: 'CLAO12',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des OR im Level A',
                },
                {
                    name: 'CLAO21',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 2.OR im Level A',
                },
                {
                    name: 'CLAO22',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 2.OR im Level A',
                },
                {
                    name: 'CLAO31',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 3.OR im Level A',
                },
                {
                    name: 'CLAO32',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 3.OR im Level A',
                },
                {
                    name: 'CLAO41',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 4.OR im Level A',
                },
                {
                    name: 'CLAO42',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 4.OR im Level A',
                },
                {
                    name: 'CLAT10',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des Timers im Level A',
                },
                {
                    name: 'CLAT1T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der Timer-Zeit im Level A',
                },
                {
                    name: 'CLAT20',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 2.Timers im Level A',
                },
                {
                    name: 'CLAT2T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der 2.Timer-Zeit im Level A',
                },
                {
                    name: 'CLAT30',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 3.Timers im Level A',
                },
                {
                    name: 'CLAT3T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der 3.Timer-Zeit im Level A',
                },
                {
                    name: 'CLAT40',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 4.Timers im Level A',
                },
                {
                    name: 'CLAT4T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der 4.Timer-Zeit im Level A',
                },
                {
                    name: 'CLBF3R',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang RESET des 3.FLIPFLOP im Level B',
                },
                {
                    name: 'CLBF3S',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang SET des 3.FLIPFLOP im Level B',
                },
                {
                    name: 'CLBF4R',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang RESET des 4. FLIPFLOP im Level B',
                },
                {
                    name: 'CLBF4S',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang SET des 4. FLIPFLOP im Level B',
                },
                {
                    name: 'CLBN10',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des NOT im Level B',
                },
                {
                    name: 'CLBN20',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 2.NOT im Level B',
                },
                {
                    name: 'CLBN30',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 3.NOT im Level B',
                },
                {
                    name: 'CLBN40',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 4.NOT im Level B',
                },
                {
                    name: 'CLBO11',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des OR im Level B',
                },
                {
                    name: 'CLBO12',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des OR im Level B',
                },
                {
                    name: 'CLBO21',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 2.OR im Level B',
                },
                {
                    name: 'CLBO22',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 2.OR im Level B',
                },
                {
                    name: 'CLBO31',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 3.OR im Level B',
                },
                {
                    name: 'CLBO32',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 3.OR im Level B',
                },
                {
                    name: 'CLBO41',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 1 des 4.OR im Level B',
                },
                {
                    name: 'CLBO42',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang 2 des 4.OR im Level B',
                },
                {
                    name: 'CLBT10',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des Timers im Level B',
                },
                {
                    name: 'CLBT1T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der Timer-Zeit im Level B',
                },
                {
                    name: 'CLBT20',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 2.Timers im Level B',
                },
                {
                    name: 'CLBT2T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der 2.Timer-Zeit im Level B',
                },
                {
                    name: 'CLBT30',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 3.Timers im Level B',
                },
                {
                    name: 'CLBT3T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der 3.Timer-Zeit im Level B',
                },
                {
                    name: 'CLBT40',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration Eingang des 4.Timers im Level B',
                },
                {
                    name: 'CLBT4T',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Konfiguration der 4.Timer-Zeit im Level B',
                },
                {
                    name: 'CLFN01',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: angeforderte Fahrzeugfunktionalität 1',
                },
                {
                    name: 'CLOF01',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor Position 1-  Konfiguration Funktionalität ',
                },
                {
                    name: 'CLOV01',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 1',
                },
                {
                    name: 'CLOV02',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 2',
                },
                {
                    name: 'CLOV03',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 3',
                },
                {
                    name: 'CLOV04',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 4',
                },
                {
                    name: 'CLOV05',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 5',
                },
                {
                    name: 'CLOV06',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 6',
                },
                {
                    name: 'CLOV07',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 7',
                },
                {
                    name: 'CLOV08',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 8',
                },
                {
                    name: 'CLOV09',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 9',
                },
                {
                    name: 'CLOV10',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 10',
                },
                {
                    name: 'CLOV11',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 11',
                },
                {
                    name: 'CLOV12',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 12',
                },
                {
                    name: 'CLOV13',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 13',
                },
                {
                    name: 'CLOV14',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 14',
                },
                {
                    name: 'CLOV15',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 15',
                },
                {
                    name: 'CLOV16',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 16',
                },
                {
                    name: 'CLOV17',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 17',
                },
                {
                    name: 'CLOV18',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 18',
                },
                {
                    name: 'CLOV19',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 19',
                },
                {
                    name: 'CLOV20',
                    approval_status: 'Versuch',
                    description: 'konfigurierbare Logik: Ausgangsvektor-  Konfiguration Position 20',
                },
            ],
        },
    },
    customer: {
        id: 0,
        name: 'EEAppFit Admin',
        registeredVehicles: 5,
        vehicles: [
            {
                id: 0,
                vehicleNo: '22f0100',
                vin: 'WMA10KZZXNM902353',
            },
            {
                id: 1,
                vin: 'WMA06XZZ8MP151967',
                vehicleNo: '200f2100',
            },
            {
                id: 2,
                vehicleNo: '22f0300',
                vin: 'WMA10KZZ5MP167309',
            },
            {
                id: 3,
                vehicleNo: '22f0400',
                vin: 'WMA82SZZ9LM859906',
            },
            {
                id: 4,
                vehicleNo: '22f0500',
                vin: 'WMAN13ZZ4LY401940',
            },
        ],
    },
    selectedVehicle: 'WMA10KZZXNM902353',
    logicComponents: [],
    ksComponents: [],
    operatorOptions: {
        childOperator: [
            {
                id: '1',
                label: 'And',
                disabled: false,
                selected: true,
            },
            {
                id: '2',
                label: 'Or',
                disabled: false,
                selected: false,
            },
            {
                id: '3',
                label: 'Not',
                disabled: false,
                selected: false,
            },
            {
                id: '4',
                label: 'Timer',
                disabled: false,
                selected: false,
            },
            {
                id: '5',
                label: 'Delay',
                disabled: false,
                selected: false,
            },
            {
                id: '6',
                label: 'Flipflop',
                disabled: false,
                selected: false,
            },
        ],
        parentOperator: [
            {
                id: '1',
                label: 'And',
                disabled: false,
                selected: true,
            },
            {
                id: '2',
                label: 'Or',
                disabled: false,
                selected: false,
            },
            {
                id: '3',
                label: 'Not',
                disabled: false,
                selected: false,
            },
            {
                id: '4',
                label: 'Timer',
                disabled: false,
                selected: false,
            },
            {
                id: '5',
                label: 'Delay',
                disabled: false,
                selected: false,
            },
            {
                id: '6',
                label: 'Flipflop',
                disabled: false,
                selected: false,
            },
        ],
    },
};
