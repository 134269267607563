import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { config } from './config';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { ErrorBoundary } from './components/ErrorBoundary';
import { PermissionsContainer } from './components/PermissionsContainer';

const renderApplication = () => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <Provider store={store}>
                <PermissionsContainer/>
            </Provider>
        </ErrorBoundary>,
    );
};

const isDev = import.meta.env.DEV;
const isProd = import.meta.env.PROD;
const isProdPreview = import.meta.env.VITE_PRODUCTION_PREVIEW;

if ((isDev && config.enableMockServer) || isProdPreview) {
    import('../mocks/serviceMock').then(({ worker }) => {
        worker
            .start({
                onUnhandledRequest: 'bypass',
            })
            .then(() => {
                console.info('Worker started in Dev');
                main(renderApplication).then(() => {});
            });
    });
}

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else if (isProd && !isProdPreview) {
    main(renderApplication).then(() => {});
}
