import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const SignalListHeader = ({ messageId }) => {
    return (
        <div className='panel-heading display-flex'>
            <div className='col-md-8 panel-title'>
                <div className='text-size-18 padding-top-5 text-bold text-color-white'>
                    <FormattedMessage id={messageId}/>
                </div>
            </div>
        </div>
    );
};

SignalListHeader.defaultProps = {
    messageId: '',
};
SignalListHeader.propTypes = {
    messageId: PropTypes.string,
};

export default SignalListHeader;
