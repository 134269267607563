// import { addKSComponents } from '@/common/actions/ksComponentsActions';
import { getHTTP } from '@/common/utils/sagaUtils';
import { config } from '@/config';
import { endpointAPI } from '@/data/endpointAPI';
import { addKSComponents, getEngineSignalsSuccess } from '@/features/engine/engineSlice';
import { getCustomerFailure } from '@/features/home/customerSlice';
import { all, call, put, takeLatest } from 'redux-saga/effects';

const appDataServiceUrl = config.backend.APP_DATA_SERVICE;

export function* doFetchEngineSignalsSaga() {
    // console.info('doFetchEngineSignalsSaga Start');
    // const url = `${appDataServiceUrl}/api/v1/enginesignals`;
    // const engineSignals = yield call(getHTTP, url);
    // // TODO: testdata
    // // const result = endpointAPI.engineSignals;
    // // yield put(addEngineSignals(result, {ENTITY_ADD_ENGINE_SIGNALS}));
    // yield put(getEngineSignalsSuccess(result));
    // console.info('doFetchEngineSignalsSaga End');

    console.groupCollapsed('doFetchEngineSignalsSaga Start');
    const url = `${appDataServiceUrl}/parametrization`;
    try {
        // const engineSignals = yield call(getHTTP, url);
        const engineSignals = import.meta.env.PROD ? endpointAPI.parametrization : yield call(getHTTP, url);
        if (!engineSignals) {
            throw new Error('EngineSignals are empty');
        }
        console.info(engineSignals);
        yield all([yield put(getEngineSignalsSuccess(engineSignals))]);
    } catch (error) {
        yield put(getCustomerFailure());
    }
    console.info('doFetchEngineSignalsSaga End');
    console.groupEnd();
}

function* doFetchEngineSignalParams(action) {
    console.groupCollapsed('doFetchEngineSignalParams Start');
    const { vin, ks_code } = action.payload;
    let flatObj = [];
    const flatMaker = (coll) => {
        if (typeof coll === 'object') {
            Object.keys(coll).forEach((c) => {
                if (Array.isArray(coll[c])) {
                    flatObj = [...flatObj, ...coll[c]];
                } else {
                    flatMaker(coll[c]);
                }
            });
        }
    };
    flatMaker(endpointAPI.parametrization);
    const signal = flatObj.filter((s) => s.name === ks_code)[0];
    const result = {
        vin,
        ks_code,
        name: signal.description,
        current_value: 234,
        last_values: [234],
        last_changed: new Date().toLocaleString('de'),
        can_change: Math.random() > 0.5,
        default_value: 200,
        allowed_values: [],
        min_value: -1000,
        max_value: 10000,
    };
    // result.name = target['Beschreibung'];
    result['ks_code'] = ks_code;
    yield put(addKSComponents(result));
    console.info('doFetchEngineSignalParams End');
    console.groupEnd();
}

export function* fetchEngineSignalsSaga() {
    yield takeLatest('engine/getEngineSignalsFetch', doFetchEngineSignalsSaga);
}

export function* fetchEngineSignalParamsSaga() {
    yield takeLatest('engine/getEngineSignalParamsFetch', doFetchEngineSignalParams);
}
